import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions';
import text from '../../text';
import { Button } from '../button';

export const CONFIRM_MODAL = 'CONFIRM_MODAL';

const ConfirmModal = ({
  show,
  onConfirm,
  title,
  body,
  cancelLabel = text('cancel'),
  okLabel = text('ok'),
}) => {
  const dispatch = useDispatch();

  const onHide = () => dispatch(hideModal(CONFIRM_MODAL));

  const onOk = () => {
    onConfirm();
    onHide();
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header className='centered'>
        <h3>{title}</h3>
      </Modal.Header>
      <Modal.Body className='text-center'>{body}</Modal.Body>
      <Modal.Footer>
        <Button
          className='full-width'
          variant='outline-primary'
          onClick={onHide}
        >
          {cancelLabel}
        </Button>
        <Button className='full-width' onClick={onOk}>
          {okLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
