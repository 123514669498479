import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useAsyncFn, useMount, useSearchParam } from 'react-use';
import RightMenu from '../components/RightMenu/RightMenu';
import Sidenav from '../pages/dashboard/components/navigation/Sidenav';
import { getUserProfile } from '../redux/actions';
import { alertConstants } from '../redux/constants';
import routes from '../routes/constants';
import { larkiApiNoAuthentication } from './api';
import { getUser, setUser } from './storage';
import { isUserAnonymous } from './user';

const PrivateRoute = ({ component: Component, allowAnonymous, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSearchParam('token');
  const impersonateToken = useSearchParam('impersonate');
  const searchParams = new URLSearchParams(window.location.search);

  const [{ loading, error }, autoLogin] = useAsyncFn(
    async () => {
      const { data } = await larkiApiNoAuthentication.post(
        '/user/verify-token',
        {
          token,
        }
      );

      setUser(data, data.is_anonymous);
      dispatch(getUserProfile());
      if (token) {
        searchParams.delete('token');
      }
      history.replace({
        search: searchParams.toString(),
      });
    },
    [token],
    {
      loading: token !== null && !getUser(),
    }
  );

  useEffect(() => {
    if (error) {
      console.error(error);
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
    }
  }, [error]);

  const impersonate = () => {
    setUser(
      {
        token: impersonateToken,
      },
      true
    );
    dispatch(getUserProfile());
    history.push(routes.home);
  };

  useMount(() => {
    if (token && !getUser()) {
      autoLogin();
    } else if (impersonateToken) {
      impersonate();
    }
  });

  const getQueryString = (search) => {
    const queryParams = new URLSearchParams(search);

    if (queryParams.has('token')) {
      queryParams.delete('token');
    }

    return queryParams.toString();
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <PulseLoader />
        ) : (getUser() && !isUserAnonymous(getUser())) ||
          (allowAnonymous && isUserAnonymous(getUser())) ? (
          <>
            <Sidenav />
            <Component {...props} />
            <RightMenu />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: `?redirectUrl=${encodeURIComponent(
                `${props.location.pathname}?${getQueryString(
                  props.location.search
                )}`
              )}`,
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
