import { Form, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';
import { useWizard } from 'react-use-wizard';

import { Button } from '../../components/button';
import FormContainer from '../../components/containers/FormContainer';
import Control from '../../components/form/Control';
import Img from '../../components/img/Img';
import { alertConstants } from '../../redux/constants';
import routes from '../../routes/constants';
import text from '../../text';
import { larkiApiNoAuthentication } from '../../utilities/api';
import { getUser } from '../../utilities/storage';
import { isUserAnonymous } from '../../utilities/user';
import Header from './Header';

const SignupForm = ({ onGoogleRedirect, onGoToLogin }) => {
  const dispatch = useDispatch();
  const {
    values,
    errors,
    touched,
    isSubmitting,
    dirty,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
  } = useFormikContext();
  const { nextStep, goToStep } = useWizard();
  const [isLoading, setLoading] = useState(false);
  const redirectUrl = useSearchParam('redirectUrl');
  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      let headers = {};
      if (isUserAnonymous(currentUser)) {
        headers['Authorization'] = `Bearer ${getUser().token}`;
      }
      const data = await submitForm();
      if (data) {
        const { is_google_account, is_verified, exists } = data;

        if (!exists) {
          nextStep(); //user does not exist, go to create new account
        } else {
          if (is_google_account) {
            await onGoogleRedirect();
          } else if (is_verified) {
            onGoToLogin(values.email);
          }

          if (!is_verified) {
            await larkiApiNoAuthentication.post(
              '/user/send-verification-email',
              {
                ...values,
                ...(redirectUrl && { redirectUrl }),
              },
              { headers }
            );
            sessionStorage.clear();
            goToStep(2);
          }
        }
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
    } finally {
      setLoading(false);
    }
  };

  const onRedirect = async () => {
    setLoading(true);
    await onGoogleRedirect();
  };

  return (
    <FormContainer className='SignupForm'>
      <FormContainer.SecondaryHeader isSecondary>
        <Header />
      </FormContainer.SecondaryHeader>
      <FormContainer.Body>
        <FormContainer.PrimaryHeader>
          <div>
            <h2>{text('welcomeToLarki')}</h2>
          </div>
          <p>{text('chooseOptionsToGetStarted')}</p>
        </FormContainer.PrimaryHeader>
        <Form noValidate onSubmit={onSubmit}>
          <div className='form-group'>
            <a onClick={onRedirect} className='signin-btn'>
              <span className='signin-iconbtn'>
                <img
                  src='/public/img/icon-google.svg'
                  alt='Image not present'
                />
              </span>{' '}
              {text('signUpLoginWithGoogle')}
            </a>
          </div>
          <div className='or'>
            <span>{text('or')}</span>
          </div>
          <Control
            validationError={errors.email}
            as='input'
            type='text'
            name='email'
            label={text('email')}
            value={values?.email || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={text('enterEmail')}
            isFailed={touched.email && Boolean(errors.email)}
          />
          <div className='text-center'>
            <div className='btns-group'>
              <Button
                className='full-width'
                loading={isSubmitting || isLoading}
                disabled={!dirty || !isValid}
                onClick={onSubmit}
              >
                {text('signUpLogin')}
              </Button>
            </div>
            <p className='small-text'>
              {text('createAccountByline')}
              <br />
              <a href={process.env.LARKI_URL_TERMS} target='_blank'>
                {text('termsOfUse')}
              </a>{' '}
              {text('and')}{' '}
              <a href={routes.larkiWebsiteUrl.privacy} target='_blank'>
                {text('privacyPolicy')}
              </a>
            </p>
          </div>
        </Form>
        <Img id='laptop-larki-form' img='laptop-larki-app' />
      </FormContainer.Body>
    </FormContainer>
  );
};

export default SignupForm;
