/* eslint-disable react/prop-types */

import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import JSZip from 'jszip';

import { GEOSPATIAL_FILES } from '../constants';
import { MapViewContext } from '../mapViewContext';
import { client } from '../../../utilities/api';
import { downloadFile } from '../../../utilities/files';
import { map } from 'lodash';

const toFileNameSafe = (s) => {
  return s.replace(/[^a-z0-9]/gi, '_').toLowerCase();
};

const downloadGeospatialFile = async (jobId, selections, fileType) => {
  const urls = [];
  const names = [];
  // Promise.all would be more elegant here
  for (const selection of selections) {
    const { data } = await client.getSelectionFeaturesFile(
      jobId,
      [selection.id],
      fileType
    );
    const fileName = selection.name
      ? toFileNameSafe(selection.name)
      : selection.id;
    urls.push(data);
    names.push(`${fileName}.${fileType}`);
  }
  if (urls.length === 1) {
    downloadFile(urls[0]);
  } else if (urls.length > 1) {
    const zip = new JSZip();
    for (let i = 0; i < urls.length; i++) {
      const res = await fetch(urls[i]);
      const blob = await res.blob();
      zip.file(names[i], blob, { blob: true });
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      downloadFile(URL.createObjectURL(content), 'shapes.zip');
    });
  }
};

const GeospatialDownloadsMenu = ({ selections }) => {
  const { state, actions } = useContext(MapViewContext);

  return (
    <div className='geospatial-downloads-menu'>
      {map(GEOSPATIAL_FILES, (shapeFile, key) => (
        <Dropdown.Item
          key={key}
          onClick={async () => {
            if (state.productShapeSelected) {
              actions.setProductShapeSelected(null);
            }
            console.assert(state.job?.id);
            if (!selections || selections.length === 0) {
              return;
            }
            await downloadGeospatialFile(
              state.job?.id,
              selections,
              shapeFile.fileExtension
            );
          }}
        >
          {shapeFile.displayName}
        </Dropdown.Item>
      ))}
    </div>
  );
};

export default GeospatialDownloadsMenu;
