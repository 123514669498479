/* eslint-disable no-undef */
import '@pointerra/pointerra-bootstrap';
import pointerraJWT from '@pointerra/pointerra-jwt';
import axios from 'axios';
import { identity, isEmpty, map, orderBy } from 'lodash/fp';
import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useInterval, useSearchParam } from 'react-use';

import { useTour } from '@reactour/tour';
import { AMPLITUDE_EVENTS } from '../../constants/analytics';
import { STATUS } from '../../constants/job';
import {
  GET_STARTED_TOURS,
  TOURS,
} from '../../pages/dashboard/components/onboarding/tour';
import {
  getAdminJobsList,
  getJobsList,
  getPointCloud,
  showModal,
} from '../../redux/actions';
import { getTourStatus } from '../../redux/actions/tour';
import { alertConstants } from '../../redux/constants';
import routes from '../../routes/constants';
import text from '../../text';
import { amplitudeTrack } from '../../utilities';
import { larkiApi, larkiApiNoAuthentication } from '../../utilities/api';
import { isDemoJob, isJobViewable } from '../../utilities/job';
import { getUser } from '../../utilities/storage';
import { isUserStaff, userHasValidMembership } from '../../utilities/user';
import Img from '../img/Img';
import { INITIATE_TOUR_MODAL } from '../modal/InitiateTourModal';
import Page from '../page/Page';

const POINTERRA_VIEWER_ID = 'cloud-viewer';
const { COMPLETED, DEMO } = STATUS;

const PointerraViewer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams();
  const location = useLocation();
  const params = queryString.parse(location.search.slice(1));

  const pointCloud = useSelector((state) => state.jobsReducer.pointCloud);
  const jobsList = useSelector((state) => state.jobsReducer.jobsList);
  const currentUser = useSelector((state) => state.profileReducer.userProfile);
  const tourSelected = useSelector((state) => state.tourReducer.tourSelected);

  const { steps, setSteps, setCurrentStep } = useTour();
  const [isPointCloudLoaded, setIsPointCloudLoaded] = useState(false);
  const [isAmplitudeDemoEventSaved, setIsAmplitudeDemoEventSaved] =
    useState(false);

  const admin = useSearchParam('admin');
  const jobsArray = orderBy('updated_at', ['desc'])(
    map(identity, jobsList).filter(
      (job) =>
        [
          ...(!userHasValidMembership(currentUser) && !isUserStaff(currentUser)
            ? []
            : [COMPLETED]),
          DEMO,
        ].includes(job.status) && isJobViewable(job)
    )
  );

  useEffect(() => {
    if (!projectId && jobsArray.length !== 0) {
      const latestJob = jobsArray[0];
      history.replace(routes.view3D.project(latestJob.projectId));
    }
  }, [jobsArray]);

  useEffect(() => {
    if (isEmpty(jobsList)) {
      dispatch(admin ? getAdminJobsList() : getJobsList());
    }

    if (!isEmpty(jobsList) && projectId && !isAmplitudeDemoEventSaved) {
      const job = jobsList[projectId];
      if (job && isDemoJob(job)) {
        amplitudeTrack(AMPLITUDE_EVENTS.VIEWED_DEMO_PROJECT_IN_3D_VIEWER.EVENT);
        setIsAmplitudeDemoEventSaved(true);
      }
    }
  }, [jobsList]);

  useEffect(() => {
    if (projectId) {
      dispatch(getPointCloud(projectId));
    }
  }, [projectId]);

  useInterval(
    () => {
      larkiApi.post('/event/point-cloud-view', {
        id: pointCloud.id,
      });
    },
    pointCloud ? 60000 : null
  );

  const handleExpiry = (loginFunction) => {
    const userData = getUser();
    let userToken = null;
    if (userData) {
      userToken = userData.token;
    }

    return new Promise((resolve, reject) => {
      loginFunction('', userToken).then((result) => {
        if (result) {
          resolve(true);
        } else {
          reject();
        }
      });
    });
  };

  pointerraJWT.configure({
    expiryCallback: handleExpiry,
    disableLoginDialog: true,
    endpoint: process.env.LARKI_API_URL, // Pointerra will call /api/jwt/token
    storage: sessionStorage,
  });

  const loadPointerraClient = async (pointerraId, defaults) => {
    try {
      const api = await pointerra.loadClient({
        targetElement: `#${POINTERRA_VIEWER_ID}`,
        jwt: pointerraJWT,
        plugins: [
          pointerra.Plugins.LeafletMap(
            'https://tile.googleapis.com/v1/3dtiles/root.json?key='
          ),
          pointerra.Plugins.Datasets(),
          pointerra.Plugins.PanoViewer(),
          pointerra.Plugins.PhotosViewer(),
          pointerra.Plugins.SphericalImages(),
        ],
        viewer: {
          disallowedTools: ['VolumeMeasure', 'PlaneMeasure'],
          showScansPanel: false,
          enableTools: true,
          showFullscreenButton: false,
          showOptionsButton: false,
        },
        sceneProperties: defaults.scene_properties,
        viewerProperties: defaults.viewer_properties,
        internal: {
          enableBookmarkLink: true,
        },
      });

      if (api) {
        /** Determine if POINTCLOUD is loaded
         * state isPointCloudLoad is needed for tour to
         * enable start when POINTCLOUD dataset,tools etc is loaded
         */
        api.pointcloud.events.loaded.addEventListener(() => {
          setIsPointCloudLoaded(true);
        });

        api.pointcloud.load(pointerraId);

        api.bookmarks.setBookmarkLinkCallback((id) => {
          const viewerPage = routes.view3D.project(projectId);
          return `${process.env.LARKI_APP_URL}${viewerPage}?bookmark=${id}`;
        });

        if (params?.bookmark) {
          api.bookmarks.events.loaded.addEventListener(() => {
            api.bookmarks.open(params.bookmark);
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaults = async (pointerraId, source) => {
    try {
      const { token } = getUser();

      const { data: tokens } = await larkiApiNoAuthentication.post(
        '/jwt/token/',
        {
          password: token,
        }
      );

      const { data: defaults } = await axios.get(
        `https://app.pointerra.io/api/pointclouds/${pointerraId}/scene_defaults`,
        {
          headers: {
            Authorization: `Bearer ${tokens.access}`,
          },
          cancelToken: source.token,
        }
      );

      return defaults;
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch({
          type: alertConstants.ALERT_ERROR,
          payload: {
            message: text('pointerraFailure', {
              link: (value) => (
                <Alert.Link
                  key='pointerraFailure'
                  href={`${process.env.LARKI_MARKETING_URL}/contact-us`}
                >
                  {value}
                </Alert.Link>
              ),
            }),
          },
        });
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const init = async (pointerraId) => {
      const defaults = await getDefaults(pointerraId, source);

      if (defaults) {
        loadPointerraClient(pointerraId, defaults);
      }
    };

    if (pointCloud?.pointerra_id) {
      init(pointCloud.pointerra_id);
    }

    return () => source.cancel();
  }, [pointCloud]);

  useEffect(() => {
    if (isPointCloudLoaded) {
      if (tourSelected) {
        setSteps(GET_STARTED_TOURS[tourSelected]);
        setCurrentStep(0);
      }

      if (steps.length) {
        dispatch(showModal(INITIATE_TOUR_MODAL));
      }
    }
  }, [steps, tourSelected, isPointCloudLoaded]);

  useEffect(() => {
    dispatch(getTourStatus(TOURS.TOUR_3D_VIEWER, setSteps, setCurrentStep));
    return () => {
      setIsPointCloudLoaded(false);
      setSteps([]); // clean up tour steps on unmount
    };
  }, []);

  return (
    <Page
      className='PointerraViewer'
      title={text('project3Dviewer', {
        projectName: !isEmpty(jobsList) && jobsList[projectId]?.project?.name,
      })}
    >
      <div id={POINTERRA_VIEWER_ID} className='Viewer-pointerra' tabIndex='0' />
      <div className='pointerra-logo'>
        <Img img='pointerra-logo' />
      </div>
    </Page>
  );
};

export default PointerraViewer;
