import { nanoid } from 'nanoid';
import geohash from 'ngeohash';

import _ from 'lodash/fp';
import {
  MAP_TYPE,
  MAX_MAP_ZOOM,
  NZ_STATES,
} from '../components/mapView/constants';
import { computeSurroundingPolygonFromPolyline } from '../components/mapView/geometry';
import { NON_TRIPOD_DATA_TYPE } from '../constants/product';

const isPathClockwise = (path) => {
  let area = 0;

  for (let i = 0; i < path.length; i++) {
    let j = (i + 1) % path.length;
    area += path[i][0] * path[j][1];
    area -= path[j][0] * path[i][1];
  }

  return area < 0;
};

export const convertToGeoJson = (regionSelection) => {
  // for each geometry object generate a GeoJSON feature
  const features = [];
  regionSelection.forEach((geometry) => {
    // add include and exclude geometries to GeoJSON coordinates
    var coordinates = [];

    if (geometry.region.type === 'Polygon') {
      // the latitude & longitude pairs need to be reversed to be valid GeoJSON
      coordinates.push(
        geometry.region.include.map((point) => [point[1], point[0]])
      );
      geometry.region.exclude?.forEach((excludedRegion) => {
        coordinates.push(excludedRegion.map((point) => [point[1], point[0]]));
      });
    } else if (geometry.region.type === 'Rectangle') {
      if (geometry.region.include.length === 2) {
        const path = [];
        path.push([
          geometry.region.include[0][1],
          geometry.region.include[0][0],
        ]);
        path.push([
          geometry.region.include[1][1],
          geometry.region.include[0][0],
        ]);
        path.push([
          geometry.region.include[1][1],
          geometry.region.include[1][0],
        ]);
        path.push([
          geometry.region.include[0][1],
          geometry.region.include[1][0],
        ]);
        path.push([
          geometry.region.include[0][1],
          geometry.region.include[0][0],
        ]);
        coordinates.push(path);
      } else {
        coordinates.push(
          geometry.region.include.map((point) => [point[1], point[0]])
        );
      }

      geometry.region.exclude?.forEach((excludedRegion) => {
        coordinates.push(excludedRegion.map((point) => [point[1], point[0]]));
      });
    } else if (geometry.region.type === 'Polyline') {
      const path = _.map(
        ([lat, lng]) => new google.maps.LatLng(lat, lng),
        geometry.region.include
      );
      const polyline = new google.maps.Polyline({
        path,
        type: 'polyline',
      });
      const polygon = computeSurroundingPolygonFromPolyline(
        polyline,
        geometry.region.width
      );
      coordinates = polygon.map((ring) =>
        ring.map((point) => [point.lng(), point.lat()])
      );
    }

    // make sure the last pair equals the first pair to close the polygon
    coordinates.forEach((coordinatePoints, index, currentCoordinates) => {
      if (
        coordinatePoints.length > 1 &&
        (coordinatePoints[0][0] !==
          coordinatePoints[coordinatePoints.length - 1][0] ||
          coordinatePoints[0][1] !==
            coordinatePoints[coordinatePoints.length - 1][1])
      ) {
        currentCoordinates[index].push(coordinatePoints[0]);
      }
    });

    // check winding order of path
    // first polygon should be clockwise
    // subsequent ones should be anticlockwise
    coordinates.forEach((polygonPath, index, currentCoordinates) => {
      if (
        (index === 0 && isPathClockwise(polygonPath)) ||
        (index > 0 && !isPathClockwise(polygonPath))
      ) {
        currentCoordinates[index] = polygonPath.reverse();
      }
    });

    // add the new feature to the feature list
    features.push({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates,
      },
    });
  });

  // return a feature collection object
  return {
    type: 'FeatureCollection',
    features,
  };
};

export const convertToGoogleGeometry = (region) => {
  const paths = [];
  const bounds = new google.maps.LatLngBounds();

  region.forEach((geometry) => {
    const latLng = new google.maps.LatLng({
      lat: geometry[1],
      lng: geometry[0],
    });
    paths.push(latLng);
    bounds.extend(latLng);
  });

  return { paths, bounds };
};

export const convertGeoJsonToRegionSelection = (geoJson) => {
  const geometry = [];

  geoJson.features.forEach((feature) => {
    const exclude = [];
    feature.geometry.coordinates.slice(1).forEach((geometry) => {
      exclude.push(
        geometry.map((points) => [points[1], points[0]]).slice(0, -1)
      );
    });

    geometry.push({
      data: {},
      region: {
        type: 'Polygon',
        include: feature.geometry.coordinates[0]
          .map((points) => [points[1], points[0]])
          .slice(0, -1),
        exclude,
      },
    });
  });

  return geometry;
};

export const convertSelectionToRegionSelection = (selection) => {
  const type = selection.ui_state?.type || selection.region.type;

  const include =
    type === 'Polyline'
      ? selection.ui_state.line_path.map((points) => [points[1], points[0]])
      : selection.region.coordinates[0]
          .map((points) => [points[1], points[0]])
          .slice(0, -1);

  const exclude = [];
  selection.region.coordinates
    .slice(1)
    .forEach((coordinates, index, currentCoordinates) => {
      if (
        (index === 0 && isPathClockwise(coordinates)) ||
        (index > 0 && !isPathClockwise(coordinates))
      ) {
        currentCoordinates[index] = coordinates.reverse();
      }
      exclude.push(
        coordinates.map((points) => [points[1], points[0]]).slice(0, -1)
      );
    });

  return {
    type,
    include,
    exclude,
  };
};

export const createId = () => nanoid();

export const getRegionBounds = (region) => {
  const bounds = new google.maps.LatLngBounds();
  region.include.forEach(([lat, lng]) => {
    bounds.extend({ lat, lng });
  });
  return bounds;
};

export const isShapeInsideBounds = (bounds, geometryShape) => {
  const allCoordinatesContain = geometryShape.coordinates.map(([lat, lng]) =>
    bounds.contains({ lat, lng })
  );

  return allCoordinatesContain.every(Boolean);
};

export const isRegionInsideBounds = (bounds, region) =>
  isShapeInsideBounds(bounds, {
    type: region.type,
    coordinates: region.include,
  });

export const getSimplePlaceFromGoogle = (placeResult) => {
  if (
    !(placeResult && placeResult.address_components && placeResult.geometry)
  ) {
    return null;
  }

  let address = '';
  let city = '';
  let state = '';
  let postcode = '';

  placeResult.address_components.forEach((addressComponent) => {
    if (addressComponent.types.includes('street_number')) {
      if (!address) {
        address = addressComponent.long_name;
      } else {
        address = `${addressComponent.long_name} ${address}`;
      }
    } else if (addressComponent.types.includes('route')) {
      if (!address) {
        address = addressComponent.long_name;
      } else {
        address = `${address} ${addressComponent.long_name}`;
      }
    } else if (addressComponent.types.includes('locality')) {
      city = addressComponent.long_name;
    } else if (addressComponent.types.includes('administrative_area_level_1')) {
      state = addressComponent.long_name;
    } else if (addressComponent.types.includes('postal_code')) {
      postcode = addressComponent.long_name;
    }
  });

  return {
    location: {
      lat: placeResult.geometry.location.lat(),
      lng: placeResult.geometry.location.lng(),
    },
    address,
    city,
    state,
    postcode,
  };
};

export const hasPlaceStreetNumber = (place) =>
  place &&
  place.address_components &&
  place.address_components.some(
    (component) => component.types && component.types.includes('street_number')
  );

export const getHighResMapType = (place) =>
  place?.state && NZ_STATES.includes(place.state)
    ? MAP_TYPE.HIGH_RES_NZ
    : MAP_TYPE.HIGH_RES;

export const stringCoords2LatLng = (coordsStr) => {
  // Converts <lat>,<lng> string to { lat: <lat>, Lng: <lng> } object.

  const coords = coordsStr.split(',').map(parseFloat);
  return { lat: coords[0], lng: coords[1] };
};

const GEOCODE_PRECISION = 7; // 152.9m x 152.4m

/**
 * Encodes a Google Maps LatLng object to a geohash string.
 * @param {google.maps.LatLng} latLng
 * @returns Geohash
 */
export const geoEncodeLatLng = (latLng) => {
  return geohash.encode(latLng.lat(), latLng.lng(), GEOCODE_PRECISION);
};

/**
 * Encodes a Google Maps LatLngBounds object to a list of geohashes.
 * @param {google.maps.LatLngBounds} bounds
 * @returns Geohashes
 */
export const geoEncodeBounds = (bounds) => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const [minLat, maxLat] = [sw.lat(), ne.lat()];
  const [minLng, maxLng] = [sw.lng(), ne.lng()];
  return geohash.bboxes(minLat, minLng, maxLat, maxLng, GEOCODE_PRECISION);
};

/**
 * Decodes a geohash string to a Google Maps LatLngBounds object.
 * @param {string} hash
 * @returns Google Maps LatLngBounds object
 */
export const geoDecode = (hash) => {
  const [minLat, minLon, maxLat, maxLon] = geohash.decode_bbox(hash);

  return new google.maps.LatLngBounds(
    new google.maps.LatLng(minLat, minLon),
    new google.maps.LatLng(maxLat, maxLon)
  );
};

/**
 * Converts a Google Maps bounds object to a GeoJSON feature.
 * @param {google.maps.LatLngBounds} bounds
 * @returns GeoJSON polygon feature
 */
export const convertGMBoundsToGeoJson = (bounds) => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const start = [sw.lng(), sw.lat()];
  const coordinates = [
    [
      start,
      [ne.lng(), sw.lat()],
      [ne.lng(), ne.lat()],
      [sw.lng(), ne.lat()],
      start,
    ],
  ];
  return {
    type: 'Polygon',
    coordinates,
  };
};

/**
 * Converts a Google Maps polygon object to a GeoJSON feature.
 * @param {google.maps.Polygon} polygon
 * @returns GeoJSON polygon feature
 */
export const convertGMPolygonToGeoJson = (polygon) => {
  console.assert(polygon.type === 'polygon');
  console.assert(polygon.getPath().getLength() >= 3);
  const coordinates = polygon
    .getPath()
    .getArray()
    .map((latLng) => [latLng.lng(), latLng.lat()]);
  coordinates.push(coordinates[0]);
  return {
    type: 'Polygon',
    coordinates: [coordinates],
  };
};

/**
 * Converts a Google Maps rectangle object to a GeoJSON feature.
 * @param {google.maps.Rectangle} rectangle
 * @returns GeoJSON polygon feature
 */
export const convertGMRectangleToGeoJson = (rectangle) => {
  console.assert(rectangle.type === 'rectangle');
  return convertGMBoundsToGeoJson(rectangle.bounds);
};

/**
 * Converts a Google Maps rectangle object to a GeoJSON feature.
 * @param {google.maps.Polyline} polyline
 * @returns GeoJSON polygon feature
 */
export const convertGMPolylineToGeoJson = (polyline) => {
  console.assert(polyline.type === 'polyline');
  const path = computeSurroundingPolygonFromPolyline(polyline)[0];
  const coords = path.map((latLng) => [latLng.lng(), latLng.lat()]);
  return {
    type: 'Polygon',
    coordinates: [coords],
  };
};

export const convertGMGeometryToGeoJson = (geometry) => {
  if (geometry.type === 'rectangle') {
    return convertGMRectangleToGeoJson(geometry);
  }
  if (geometry.type === 'polygon') {
    return convertGMPolygonToGeoJson(geometry);
  }
  if (geometry.type === 'polyline') {
    return convertGMPolylineToGeoJson(geometry);
  }
  throw new Error(`unknown geometry type ${geometry.type}`);
};

export const getGMGeometryBounds = (geometry) => {
  if (geometry.type === 'rectangle') {
    return geometry.getBounds();
  }
  if (geometry.type === 'polygon' || geometry.type === 'polyline') {
    const bounds = new google.maps.LatLngBounds();
    const coords = geometry.getPath();
    coords.forEach((coord) => {
      bounds.extend(coord);
    });
    return bounds;
  }
  throw new Error(`unknown geometry type ${geometry.type}`);
};

export const convertGMBoundsToBBox = (bounds) => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const [minLat, maxLat] = [sw.lat(), ne.lat()];
  const [minLng, maxLng] = [sw.lng(), ne.lng()];
  return [minLng, minLat, maxLng, maxLat];
};

// export const addBufferGMBounds = (bounds, buffer) => {
//   const poly = convertGMBoundsToGeoJson(bounds);
//   const polyBuffered = computeBuffered(poly, buffer);
//   const latLngCoordinates = polyBuffered.coordinates[0].map(([lng, lat]) => [
//     lat,
//     lng,
//   ]);
//   return getRegionBounds({
//     include: latLngCoordinates,
//   });
// };

/**
 * Gets the zoom after setting the map bounds.
 * Credit: https://stackoverflow.com/a/13274361
 * @param bounds google.maps.LatLngBounds object
 * @param mapDim - { width, height } object of the DOM element that displays the map
 * @param buffer [m]
 * @param zoomMax max zoom possible
 * @returns The zoom
 */
export const getGMBoundsZoomLevel = (
  bounds,
  mapDim,
  buffer = 0,
  zoomMax = MAX_MAP_ZOOM
) => {
  const WORLD_DIM = { width: 256, height: 256 };

  const latRad = (lat) => {
    var sin = Math.sin((lat * Math.PI) / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  function zoom(mapPx, worldPx, fraction) {
    return Math.log(mapPx / worldPx / fraction) / Math.LN2;
  }

  var ne = bounds.getNorthEast();
  var sw = bounds.getSouthWest();

  // add buffer using hand-rolled translation geodesic("+a=6378137")
  const EARTH_RADIUS_IN_KILOMETERS = 6378.137;
  const DEGREES_TO_RADIANS = Math.PI / 180.0;
  const RADIANS_TO_DEGREES = 180.0 / Math.PI;

  // todo: custom method
  ne = new google.maps.LatLng({
    lat:
      ne.lat() +
      (buffer / 1000 / EARTH_RADIUS_IN_KILOMETERS) * RADIANS_TO_DEGREES,
    lng:
      ne.lng() +
      ((buffer / 1000 / EARTH_RADIUS_IN_KILOMETERS) * RADIANS_TO_DEGREES) /
        Math.cos(ne.lat() * DEGREES_TO_RADIANS),
  });
  sw = new google.maps.LatLng({
    lat:
      sw.lat() +
      (-buffer / 1000 / EARTH_RADIUS_IN_KILOMETERS) * RADIANS_TO_DEGREES,
    lng:
      sw.lng() +
      ((-buffer / 1000 / EARTH_RADIUS_IN_KILOMETERS) * RADIANS_TO_DEGREES) /
        Math.cos(sw.lat() * DEGREES_TO_RADIANS),
  });

  var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  var lngDiff = ne.lng() - sw.lng();
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, zoomMax);
};

export const selectionChildren = (selections, selectionId) =>
  selections.filter(
    (selection) => selection.parent_selection_id === selectionId
  );

export const selectionIsNonTripod = (productType) =>
  NON_TRIPOD_DATA_TYPE.includes(productType);
