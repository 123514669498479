import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import Page from '../../components/page/Page';
import { STATUS, ACTIVE_STATUS } from '../../constants/job';
import text from '../../text';
import StatusControl from '../../components/orderListing/filters/statusControl';
import { setListJobStatus } from '../../redux/actions';
import { ALL_STATUS } from '../../components/orderListing/consts';

const AdminOrderListingControl = () => {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.jobsReducer.listJobFilter);

  useEffect(() => {
    if (isEmpty(status) || status === ALL_STATUS) {
      dispatch(setListJobStatus(ACTIVE_STATUS));
    }
  }, []);

  return (
    <Page.Control className='AdminOrderListingControl'>
      <StatusControl
        includeUnderline
        status={ACTIVE_STATUS}
        currentStatus={status}
        onClick={(newStatus) => dispatch(setListJobStatus(newStatus))}
      >
        {text('commenced')}
      </StatusControl>
      <StatusControl
        includeUnderline
        currentStatus={status}
        status={[STATUS.DRAFT]}
        onClick={(newStatus) => dispatch(setListJobStatus(newStatus))}
      >
        {text('draft')}
      </StatusControl>
      <StatusControl
        includeUnderline
        currentStatus={status}
        status={[STATUS.COMPLETED]}
        onClick={(newStatus) => dispatch(setListJobStatus(newStatus))}
      >
        {text('completed')}
      </StatusControl>
    </Page.Control>
  );
};

export default AdminOrderListingControl;
