import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { Button } from '../../components/button';
import { alertConstants, jobsConstants } from '../../redux/constants';
import text from '../../text';
import { larkiApi } from '../../utilities/api';

const InviteRow = ({ job }) => {
  const dispatch = useDispatch();

  const [{ loading, error }, updateInvite] = useAsyncFn(
    async (inviteStatus) => {
      await larkiApi.put(`/project/${job.project.id}/user`, {
        inviteStatus,
      });
    },
    [job]
  );

  useEffect(() => {
    if (error) {
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
    }
  }, [error]);

  const onAccept = async () => {
    await updateInvite('accepted');

    dispatch({
      type: jobsConstants.UPDATE_INVITE,
      payload: {
        id: job?.project.id,
        status: 'accepted',
      },
    });
  };

  const onReject = async () => {
    await updateInvite('rejected');

    dispatch({
      type: jobsConstants.DELETE_PROJECT_SUCCESS,
      payload: job?.project.id,
    });
  };

  return (
    <tr className='pending-invite-row'>
      <td colSpan='100%'>
        <div className='pending-invite'>
          {text('pendingInvite', {
            user:
              job.user_project.invited_by?.role === 'default'
                ? job.user_project.invited_by.full_name
                : 'LARKI Admin',
            project: job.project.name,
          })}
          <div className='pending-invite-btns'>
            <Button
              variant='primary'
              type='button'
              onClick={onAccept}
              disabled={loading}
              className='pending-invite-btn'
            >
              Accept invitation
            </Button>
            <Button
              variant='outline-primary'
              type='button'
              onClick={onReject}
              disabled={loading}
              className='pending-invite-btn'
            >
              Reject
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default InviteRow;
