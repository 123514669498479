import React from 'react';

import PointerraViewer from '../components/pointerraViewer';

function PointCloudViewer() {
  return (
    <>
      <PointerraViewer />
    </>
  );
}

export default PointCloudViewer;
