import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';

import PaymentMethodStep from '../../../components/wizard/PaymentMethodStep';
import { LEAD_PAYMENT_PAGE } from '../../../constants/job';
import { PAY_MODE } from '../../../constants/order';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { sendLead } from '../../../redux/actions';
import {
  assignPayment,
  createDiscountCode,
  setPaymentMethodId,
  setSelectedPayMode,
} from '../../../redux/actions/order';
import routes from '../../../routes/constants';
import text from '../../../text';

const OrderWizardPaymentMethod = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { nextStep } = useWizard();
  const { jobId } = useParams();
  const isBreakpointEnabled = useBreakpoint(['xs', 'sm']);
  const {
    job,
    currentUser,
    userProfileState,
    selectedPayMode,
    membershipPriceId,
    paymentMethodId,
    discountCode,
  } = useSelector((state) => ({
    job: state.jobsReducer.job,
    currentUser: state.profileReducer.userProfile,
    userProfileState: state.profileReducer.userProfileState,
    selectedPayMode: state.order.selectedPayMode,
    membershipPriceId: state.order.membershipPriceId,
    paymentMethodId: state.order.paymentMethodId,
    discountCode: state.order.discountCode,
  }));

  const isAssignedPayment = selectedPayMode === PAY_MODE.ASSIGNED_PAYMENT;

  const handleSubmit = async (jobIdToAssign, values) => {
    if (isAssignedPayment && values) {
      const success = await dispatch(
        assignPayment(
          membershipPriceId,
          jobIdToAssign,
          values.payerEmail,
          values.payerName,
          values.message
        )
      );
      if (success) {
        history.push({
          pathname: routes.payment.assignedStatus(),
          state: { payerEmail: values.payerEmail, payerName: values.payerName },
        });
      }
    } else {
      if (discountCode) {
        const success = await dispatch(createDiscountCode(discountCode));
        if (!success) {
          return;
        }
      }
      nextStep();
    }
  };

  useEffect(() => {
    dispatch(sendLead(jobId, LEAD_PAYMENT_PAGE));
  }, []);

  const handleSelectedPayMode = (event) =>
    dispatch(setSelectedPayMode(event.currentTarget.value));
  return (
    <PaymentMethodStep
      job={job}
      isAssignedPayment={isAssignedPayment}
      handleSelectedPayMode={handleSelectedPayMode}
      onAddCard={nextStep}
      handleSubmit={(values) => handleSubmit(jobId, values)}
      currentUser={currentUser}
      userProfileState={userProfileState}
      selectedPayMode={selectedPayMode}
      paymentMethodId={paymentMethodId}
      onPaymentMethodChange={(paymentMethodId) =>
        dispatch(setPaymentMethodId(paymentMethodId))
      }
      nextButtonProps={{
        label: text(
          isAssignedPayment
            ? 'sendQuote'
            : isBreakpointEnabled
            ? 'continue'
            : 'continueToConfirmation'
        ),
      }}
    />
  );
};

export default OrderWizardPaymentMethod;
