import { isEmpty, reduce } from 'lodash';
import _ from 'lodash/fp';
import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMap } from 'react-use';

import { PRODUCT_TYPE } from '../../constants/product';
import {
  showModal,
  toggleAutomaticDataTableVisibility,
  toggleManualDataTableVisibility,
} from '../../redux/actions';
import text, { formatCurrency } from '../../text';
import {
  isSelectionAutomatic,
  isSelectionManual,
} from '../../utilities/selection';
import Icon from '../icon/Icon';
import { MapViewContext } from '../mapView/mapViewContext';
import { DELETE_SHAPES_MODAL } from '../modal/DeleteShapesModal';
import DataTable from '../table/dataTable/DataTable';
import CartEmpty from './CartEmpty';
import OtherChargeRow from './OtherChargeRow';
import ProductTable, { ProductTableHeader } from './ProductTable';

const CartTable = (props) => {
  const { isReadOnly } = props;
  const { state } = useContext(MapViewContext);
  const dispatch = useDispatch();
  const { layout } = useSelector((reduxState) => ({
    layout: reduxState.layout,
  }));
  const [isProductCollapsed, isProductCollapsedActions] = useMap(
    reduce(
      PRODUCT_TYPE,
      (result, product) => {
        result[product] = true;
        return result;
      },
      {}
    )
  );

  const selectionsPerProduct = useMemo(() => {
    const products = new Map();
    if (state.selections) {
      state.selections.forEach((selection) => {
        if (products?.[selection.category_name]) {
          products[selection.category_name] = [
            ...products[selection.category_name],
            selection,
          ];
        } else {
          products[selection.category_name] = [selection];
        }
      });
    }
    return products;
  }, [state.selections]);

  const automaticProducts = useMemo(() => {
    return Object.entries(selectionsPerProduct).filter((product) =>
      isSelectionAutomatic(product[1][0])
    );
  }, [selectionsPerProduct]);

  const manualProducts = useMemo(() => {
    return Object.entries(selectionsPerProduct).filter((product) =>
      isSelectionManual(product[1][0])
    );
  }, [selectionsPerProduct]);

  const chargesPerProduct = useMemo(() => {
    const products = new Map();
    if (state.job?.quote?.charges) {
      state.job.quote.charges.forEach((charge) => {
        products[charge.display_name] = { ...charge };
      });
    }
    return products;
  }, [state.job]);

  // TODO: use context
  const coveragePerProduct = useMemo(() => {
    const products = {};
    if (state.job?.coverage?.selections) {
      _.forEach((selection) => {
        if (selection.category_name in products) {
          products[selection.category_name].push(selection);
        } else {
          products[selection.category_name] = [selection];
        }
      })(state.job.coverage.selections);
    }
    return products;
  }, [state.job]);

  const siteDeploymentFee = useMemo(() => {
    const fee = state.job?.quote?.fees.find(
      (fee) => fee.id === 'site_deployment_fee'
    );
    return fee
      ? formatCurrency(
          fee.price.total,
          state.job?.quote?.currency.name,
          state.job?.quote?.currency.scale_factor,
          {},
          true
        )
      : '';
  }, [state.job]);

  const handleDelete = (selectionsToDelete, { onOk }) => {
    dispatch(showModal(DELETE_SHAPES_MODAL, { selectionsToDelete, onOk }));
  };

  const isEveryProductCollapsed = (products) => {
    const productsCollapse = products.map(
      (product) => isProductCollapsed[product]
    );
    return !productsCollapse.every(Boolean);
  };

  const toggleProductCollapse = (products) => {
    products.forEach((key) =>
      isProductCollapsedActions.set(key, isEveryProductCollapsed(products))
    );
  };

  useEffect(() => {
    // note that whenever the automatic/manual data table is visible
    // the add-on button is not visible, and vice versa

    const isAutomaticDataAdded = state.selections.some(
      (selection) => selection.delivery_method === 'automatic'
    );
    dispatch(toggleAutomaticDataTableVisibility(isAutomaticDataAdded));

    const isManualDataAdded = state.selections.some(
      (selection) => selection.delivery_method === 'manual'
    );
    dispatch(toggleManualDataTableVisibility(isManualDataAdded));
  }, [state.selections]);

  return (
    <div className='CartTableV2'>
      {state.isCartEmpty ? (
        <CartEmpty hasAddOns={state.hasAddOns && !isReadOnly} />
      ) : (
        <>
          <hr className='my-2' />
          <h3 className='title green-1'>
            <Icon icon='shopping-cart' />
            {text('cart')}
          </h3>
          <hr className='my-2' />
        </>
      )}
      {/* Automatic delivery */}
      {layout.automaticDataTable.isVisible && (
        <>
          <ProductTableHeader
            headerText={text('buyNowDataInDatabase')}
            advantagesText={text('automaticDataAdvantages')}
            disadvantagesText={text('automaticDataDisadvantages')}
            isCollapsed={
              !isEveryProductCollapsed(
                automaticProducts.map(([productName]) => productName)
              )
            }
            isDeleteDisabled={
              isEmpty(state.selections) || isEmpty(automaticProducts)
            }
            onDelete={() => {
              const selectionsToDelete =
                state.selections.filter(isSelectionAutomatic);
              handleDelete(selectionsToDelete, {
                onOk: () =>
                  ['aerial', 'streetscape'].map((product) =>
                    isProductCollapsedActions.set(product, true)
                  ),
              });
            }}
            isCollapseDisabled={isEmpty(automaticProducts)}
            onCollapse={() =>
              toggleProductCollapse(
                automaticProducts.map(([productName]) => productName)
              )
            }
            isDeleteHidden={true}
            isCollapseHidden={true}
            isBadgesHidden={true}
          />
          <Collapse in={layout.automaticDataTable.isCollapsed}>
            <div>
              <DataTable className='DataTableV2'>
                {Object.keys(selectionsPerProduct).length > 0 &&
                  state.job?.quote && (
                    <Fragment key='wrapper'>
                      <tbody>
                        {Object.entries(selectionsPerProduct)
                          .filter((product) => {
                            return (
                              product[1][0].delivery_method === 'automatic'
                            );
                          })
                          .map((product, index) => {
                            return (
                              <ProductTable
                                key={index}
                                product={product}
                                chargesPerProduct={chargesPerProduct}
                                coveragePerProduct={coveragePerProduct}
                                isReadOnly={isReadOnly}
                              />
                            );
                          })}
                      </tbody>
                    </Fragment>
                  )}
              </DataTable>
            </div>
          </Collapse>
        </>
      )}

      {layout.manualDataTable.isVisible && (
        <>
          <ProductTableHeader
            headerText={text('getQuoteData')}
            advantagesText={text('manualDataAdvantages')}
            disadvantagesText={text('manualDataDisadvantages')}
            isCollapsed={
              !isEveryProductCollapsed(
                manualProducts.map(([productName]) => productName)
              )
            }
            onCollapse={() =>
              toggleProductCollapse(
                manualProducts.map(([productName]) => productName)
              )
            }
            isCollapseDisabled={isEmpty(manualProducts)}
            isDeleteDisabled={
              isEmpty(state.selections) || isEmpty(manualProducts)
            }
            onDelete={() => {
              const selectionsToDelete =
                state.selections.filter(isSelectionManual);
              handleDelete(selectionsToDelete, {
                onOk: () =>
                  ['exterior', 'interior', 'drone', 'unknown'].map((product) =>
                    isProductCollapsedActions.set(product, true)
                  ),
              });
            }}
            isDeleteHidden={true}
            isCollapseHidden={true}
            isBadgesHidden={true}
          />
          <Collapse in={layout.manualDataTable.isCollapsed}>
            <div>
              <DataTable className='DataTableV2'>
                {Object.keys(selectionsPerProduct).length > 0 &&
                  state.job?.quote && (
                    <Fragment key='wrapper'>
                      <tbody>
                        {Object.entries(selectionsPerProduct)
                          .filter(
                            (product) =>
                              product[1][0].delivery_method === 'manual'
                          )
                          .map((product, index) => {
                            return (
                              <ProductTable
                                key={index}
                                product={product}
                                isProductCollapsedActions={
                                  isProductCollapsedActions
                                }
                                isProductCollapsed={isProductCollapsed}
                                chargesPerProduct={chargesPerProduct}
                                coveragePerProduct={coveragePerProduct}
                                isReadOnly={isReadOnly}
                              />
                            );
                          })}

                        {siteDeploymentFee ? (
                          <OtherChargeRow
                            isReadOnly={isReadOnly}
                            description={text('siteDeploymentFee')}
                            amount={siteDeploymentFee}
                            infoMessage={text('siteDeploymentFeeInfo')}
                          />
                        ) : null}
                      </tbody>
                    </Fragment>
                  )}
              </DataTable>
            </div>
          </Collapse>
        </>
      )}

      {/* Manual delivery - GET QUOTE PART -- OLD*/}
      {/* {layout.manualDataTable.isVisible && (
        <>
          <ProductTableHeader
            headerText={text('getQuoteData')}
            advantagesText={text('manualDataAdvantages')}
            disadvantagesText={text('manualDataDisadvantages')}
            isCollapsed={
              !isEveryProductCollapsed(
                manualProducts.map(([productName]) => productName)
              )
            }
            onCollapse={() =>
              toggleProductCollapse(
                manualProducts.map(([productName]) => productName)
              )
            }
            isCollapseDisabled={isEmpty(manualProducts)}
            isDeleteDisabled={
              isEmpty(state.selections) || isEmpty(manualProducts)
            }
            onDelete={() => {
              const selectionsToDelete =
                state.selections.filter(isSelectionManual);
              handleDelete(selectionsToDelete, {
                onOk: () =>
                  ['exterior', 'interior', 'drone', 'unknown'].map((product) =>
                    isProductCollapsedActions.set(product, true)
                  ),
              });
            }}
          />
          <Collapse in={layout.manualDataTable.isCollapsed}>
            <div>
              <DataTable className='DataTableV2'>
                {Object.keys(selectionsPerProduct).length > 0 &&
                  state.job?.quote && (
                    <Fragment key='wrapper'>
                      {Object.entries(selectionsPerProduct)
                        .filter(
                          (product) =>
                            product[1][0].delivery_method === 'manual'
                        )
                        .map((product, index) => {
                          return (
                            <ProductTable
                              key={index}
                              product={product}
                              isProductCollapsedActions={
                                isProductCollapsedActions
                              }
                              isProductCollapsed={isProductCollapsed}
                              chargesPerProduct={chargesPerProduct}
                              coveragePerProduct={coveragePerProduct}
                              isReadOnly={isReadOnly}
                            />
                          );
                        })}
                    </Fragment>
                  )}
                <tbody>
                  {siteDeploymentFee ? (
                    <OtherChargeRow
                      isReadOnly={isReadOnly}
                      description={text('siteDeploymentFee')}
                      amount={siteDeploymentFee}
                      infoMessage={text('siteDeploymentFeeInfo')}
                    />
                  ) : null}
                </tbody>
              </DataTable>
            </div>
          </Collapse>
        </>
      )} */}
    </div>
  );
};

export default CartTable;
