import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useClickAway, useSearchParam } from 'react-use';
import { Button } from '../../../../components/button';
import MembershipButton from '../../../../components/button/MembershipButton';
import CollapsibleNavItem from '../../../../components/navigation/CollapsibleNavItem';
import NavLink from '../../../../components/navigation/NavLink';
import TrialMembershipBar from '../../../../components/progressBar/TrialMembershipBar';
import TextDivider from '../../../../components/text/TextDivider';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import { toggleSidenavVisibility } from '../../../../redux/actions';
import routes from '../../../../routes/constants';
import text from '../../../../text';
import { formatDate } from '../../../../utilities/date';
import {
  calculateRemainderDays,
  calculateRemainderHours,
  calculateTrialLengthDays,
} from '../../../../utilities/membership';
import { isRouteIncluded } from '../../../../utilities/routes';
import {
  getUserMembership,
  isAdministrator,
  isUserAnonymous,
  isUserGovernment,
  isUserUnderTrial,
  userHasActiveMembership,
} from '../../../../utilities/user';
import { LayoutContext } from '../../../LayoutContext';

const ROUTES_TO_HIDE_SIDENAV = [
  routes.login,
  routes.password.create,
  routes.password.reset,
  routes.signUp,
  routes.order.root,
  routes.view3D.project(),
  routes.payment.job(),
  routes.payment.assignedStatus(),
  routes.user.membershipChangeSuccess,
  routes.user.membershipManagement,
];

const isVisibleForRoute = (r) => !isRouteIncluded(ROUTES_TO_HIDE_SIDENAV)(r);

const getTrialRemainderMessage = (membership) => {
  const remainderHours = calculateRemainderHours(membership);
  let remainderDays = calculateRemainderDays(membership);

  const isDay = remainderHours > 24;
  const remainder = isDay ? remainderDays : remainderHours;

  return text('trialRemainder', {
    trialRemainder: () => (
      <h3 style={{ display: 'inherit' }}>{`${remainder}`}</h3>
    ),
    remainder,
    isDay,
  });
};

const Sidenav = () => {
  const isEnabledForBreakpoint = useBreakpoint(['xs', 'sm', 'md']);
  const isVisibleForBreakpoint = useBreakpoint(['lg', 'xl']);
  const admin = useSearchParam('admin');
  const ref = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(routes.home);
  const { setStartNewProjectRef, startNewProjectHighlight } =
    useContext(LayoutContext);

  // To set active key for paths with params
  const match = useRouteMatch([
    routes.fileManager.viewProject(),
    routes.fileManager.root,
  ]);

  const { sideNav, user } = useSelector((state) => ({
    sideNav: state.layout.sideNav,
    user: state.profileReducer.userProfile,
  }));

  useEffect(() => {
    if (location.pathname && location.pathname !== activeKey) {
      setActiveKey(match?.path || location.pathname);
      dispatch(toggleSidenavVisibility(false));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (admin) {
      setActiveKey(routes.administrator.projects);
    }
  }, [admin]);

  useEffect(() => {
    if (isVisibleForRoute(location.pathname)) {
      if (isVisibleForBreakpoint) {
        dispatch(toggleSidenavVisibility(true));
      } else {
        dispatch(toggleSidenavVisibility(false));
      }
    }
  }, [isVisibleForBreakpoint, location.pathname]);

  useClickAway(
    ref,
    () => {
      if (isEnabledForBreakpoint && sideNav.isVisible) {
        dispatch(toggleSidenavVisibility(false));
      }
    },
    ['mousedown']
  );

  return (
    !isEmpty(user) &&
    !isUserAnonymous(user) && (
      <div
        ref={ref}
        className={cn('Sidenav', {
          show: sideNav.isVisible,
          hide: !isVisibleForRoute(location.pathname),
          'd-none':
            !isVisibleForRoute(location.pathname) && !isEnabledForBreakpoint,
        })}
      >
        <Nav activeKey={activeKey}>
          <Nav.Item>
            <NavLink
              name='dashboard'
              eventKey={routes.dashboard}
              onClick={() => history.push(routes.dashboard)}
              icon='dashboard'
            />
          </Nav.Item>
          <Nav.Item>
            <NavLink
              name='viewer3D'
              eventKey={routes.view3D.project()}
              onClick={() => history.push(routes.view3D.root)}
              icon='3D'
            />
          </Nav.Item>
          <Nav.Item id='map2D-nav-item'>
            <NavLink
              name='map2D'
              eventKey={routes.order.root}
              onClick={() => history.push(routes.order.root)}
              icon='2d-map'
            />
          </Nav.Item>
          <Nav.Item>
            <NavLink
              name='fileManager'
              eventKey={match?.path || routes.fileManager.viewProject()}
              onClick={() => history.push(routes.fileManager.root)}
              icon='file'
            />
          </Nav.Item>
          <Nav.Item>
            <NavLink
              name='account'
              eventKey={routes.user.profile}
              onClick={() => history.push(routes.user.profile)}
              icon='profile'
            />
          </Nav.Item>
          <CollapsibleNavItem
            header={text('larkiURL')}
            isBorderHighlighted={false}
            eventKey={routes.larkiWebsiteUrl.home}
            items={[
              {
                name: 'home',
                href: routes.larkiWebsiteUrl.home,
                isBorderHighlighted: false,
                isCaretHighlighted: false,
                target: '_blank',
              },
              {
                name: 'products',
                href: routes.larkiWebsiteUrl.products,
                isBorderHighlighted: false,
                isCaretHighlighted: false,
                target: '_blank',
              },
              {
                name: 'pricing',
                href: routes.larkiWebsiteUrl.pricing,
                isBorderHighlighted: false,
                isCaretHighlighted: false,
                target: '_blank',
              },
              {
                name: 'aboutUs',
                href: routes.larkiWebsiteUrl.aboutUs,
                isBorderHighlighted: false,
                isCaretHighlighted: false,
                target: '_blank',
              },
              {
                name: 'contactUs',
                href: routes.larkiWebsiteUrl.contactUs,
                isBorderHighlighted: false,
                isCaretHighlighted: false,
                target: '_blank',
              },
            ]}
          />
          <Nav.Item
            className={cn(
              'start-new-project-item px-3',
              startNewProjectHighlight && 'highlight'
            )}
            ref={setStartNewProjectRef}
          >
            <Button
              className='w-100'
              id='button'
              variant='primary'
              onClick={() => {
                history.push(routes.order.root);
              }}
            >
              {text('startNewProject')}
            </Button>
          </Nav.Item>
          {isAdministrator(user.role) && (
            <>
              <div className='separator' />
              <Nav.Item className='Label'>{text('administration')}</Nav.Item>
              <Nav.Item>
                <NavLink
                  name='projects'
                  eventKey={routes.administrator.projects}
                  onClick={() => history.push(routes.administrator.projects)}
                />
              </Nav.Item>
            </>
          )}
          <Nav.Item id='logout-nav-item'>
            <NavLink
              name='logout'
              eventKey={routes.logout}
              href={routes.logout}
            />
          </Nav.Item>
        </Nav>
        <div className='Sidenav-Bottom'>
          {(isUserUnderTrial(user) || user.has_used_trial) && (
            <>
              {isUserUnderTrial(user) &&
              calculateRemainderHours(getUserMembership(user)) > 0 ? (
                <>
                  <span
                    className='white d-inline-block text-center w-100  m-0 mt-2'
                    style={{ color: 'white' }}
                  >
                    {getTrialRemainderMessage(getUserMembership(user))}
                  </span>
                  <TrialMembershipBar membership={getUserMembership(user)} />
                  <p className='white text-center m-0 mb-2'>
                    <small>
                      {text('trialLengthExpiryAt', {
                        trialLength: `${calculateTrialLengthDays(
                          getUserMembership(user)
                        )}-day`,
                        expiryAt: formatDate(
                          getUserMembership(user)?.trial_end
                        ),
                      })}
                    </small>
                  </p>
                </>
              ) : (
                !userHasActiveMembership(user) && (
                  <p className='text-center white'>
                    {text('trialExpiredAt', {
                      expiredAt: formatDate(getUserMembership(user)?.trial_end),
                    })}
                  </p>
                )
              )}
              {user.has_used_trial &&
                !userHasActiveMembership(user) &&
                !isUserGovernment(user) && (
                  <>
                    <TextDivider isComplete isSecondary />
                    <p className='white text-center mx-1 mb-3'>
                      {text('upgradeToEssentials')}
                    </p>
                  </>
                )}
            </>
          )}
          <Nav.Item className='px-3'>
            <MembershipButton className='w-100' isUppercase isStarred />
          </Nav.Item>
        </div>
      </div>
    )
  );
};

export default Sidenav;
