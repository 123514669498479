import React from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../../../../routes/constants';
import { isRouteMatching } from '../../../../../utilities/routes';
import FileManagerNav from './FileManagerNav';
import Map2DNav from './Map2DNav';
import Viewer3DNav from './Viewer3DNav';

const Titlebar = () => {
  const location = useLocation();
  const isVisibleToRoute = isRouteMatching(location.pathname);
  return (
    <div className='Titlebar'>
      {isVisibleToRoute(routes.order.root) && (
        <Map2DNav className='Navbar-Item' />
      )}
      {isVisibleToRoute(routes.fileManager.root) && (
        <FileManagerNav className='Navbar-Item' />
      )}
      {isVisibleToRoute(routes.view3D.project()) && (
        <Viewer3DNav className='Navbar-Item' />
      )}
    </div>
  );
};

export default Titlebar;
