/* eslint-disable react/prop-types */
import classnames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useSearchParam } from 'react-use';
import { ANONYMOUS_ORDER } from '../../constants/alert';
import { LEAD_CHECKOUT } from '../../constants/job';
import { MEMBERSHIP } from '../../constants/membership';
import { InvoiceTotalSumArea } from '../../pages/billing/Invoice/Invoice';
import {
  sendLead,
  setMembershipPriceId
} from '../../redux/actions';
import {
  createJob,
  updateJob,
  updateProjectName,
} from '../../redux/actions/jobs';
import { showModal } from '../../redux/actions/modal';
import routes from '../../routes/constants';
import text, { formatCurrency } from '../../text';
import { getNowDate } from '../../utilities/date';
import { isMembershipPriceId } from '../../utilities/membership';
import { getSearchParam } from '../../utilities/router';
import {
  isStaff,
  isUserAnonymous,
  isUserUnderTrial,
  userHasValidMembership,
} from '../../utilities/user';
import { SAVE_DRAFT_MODAL } from '../modal/SaveDraftModal';
import { FORCE_JOB_UPDATE_MODAL } from '../modal/forceJobUpdateModal';
import { STATUS } from '../orderListing/consts';
import { MapViewContext } from './mapViewContext';

const SubmitButtons = ({ isReadOnly }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mergeProjectId = useSearchParam('mergeProjectId');
  const isVisibleFor2DMap = useRouteMatch([
    routes.order.root,
    routes.order.job(),
  ]);
  const [expandInvoice, setExpandInvoice] = useState(false);
  const { state } = useContext(MapViewContext);

  const { currentUser, membershipPriceId, mapState } = useSelector(
    (reduxState) => ({
      currentUser: reduxState.profileReducer.userProfile,
      membershipPriceId: reduxState.order.membershipPriceId,
      mapState: reduxState.mapReducer,
    })
  );

  const handleSaveDraft = async (latestJob, values, force = false) => {
    const data = {
      address: {
        ...state.address,
      },
      message: state.requestMessage,
      ...values,
    };
    if (values?.projectName && latestJob.project?.id) {
      await dispatch(
        updateProjectName(latestJob.project.id, values.projectName)
      );
    }

    const job = await dispatch(
      latestJob?.id
        ? updateJob({
            ...data,
            jobId: latestJob.id,
            updatedAt: getNowDate(),
            force,
            onConflict: onShowForceSave,
          })
        : createJob(data)
    );

    return job;
  };

  const handleSubmit = async (job, coupon) => {
    if (job) {
      const params = new URLSearchParams();
      if (mergeProjectId) {
        params.append('mergeProjectId', mergeProjectId);
      }
      let redirectUrl = routes.payment.job(job.id);

      if (isUserAnonymous(currentUser)) {
        if (coupon) {
          redirectUrl = routes.order.job(job.id);
        }
        params.append('alertType', ANONYMOUS_ORDER);
        params.append('redirectUrl', redirectUrl);
        if (coupon) {
          params.append('coupon', coupon);
        }
        history.push({
          pathname: routes.login,
          search: params.toString(),
        });
      } else {
        dispatch(sendLead(job.id, LEAD_CHECKOUT));
        history.push({
          pathname: redirectUrl,
          search: params.toString(),
        });
      }
    }
  };

  const onSaveDraft = () => {
    dispatch(
      showModal(SAVE_DRAFT_MODAL, {
        onSubmit: handleSaveDraft,
        currentUser,
      })
    );
  };

  const onSaveAndExit = async () => {
    if (state.project.name) {
      const job = await handleSaveDraft(state.job, {
        projectName: state.project.name,
      });

      if (job) {
        history.push(routes.home);
      }
    } else {
      onSaveDraft();
    }
  };

  const onForceSaveAndExit = async () => {
    const job = await handleSaveDraft(
      state.job,
      {
        projectName: state.project.name,
      },
      true
    );

    if (job) {
      history.push(routes.dashboard);
    }
  };

  const onShowForceSave = () => {
    dispatch(
      showModal(FORCE_JOB_UPDATE_MODAL, {
        onSubmit: onForceSaveAndExit,
      })
    );
  };

  const isJobAwaitingPayment = [
    STATUS.COMMITTED,
    STATUS.AWAITING_PAYMENT,
  ].includes(state.job?.status);

  const grandTotal = useMemo(() => {
    const quote = state.job?.quote;

    return quote?.price.base
      ? formatCurrency(
          isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role)
            ? quote?.price.member_grand_total
            : quote?.price.grand_total,
          quote?.currency.name,
          quote?.currency.scale_factor,
          {},
          true
        )
      : text('toBeConfirmed');
  }, [state.job?.quote]);

  // RESET membershipPriceId IF USER IS TRIAL
  useEffect(() => {
    if (
      (currentUser && isStaff(currentUser.role)) ||
      (userHasValidMembership(currentUser) && isUserUnderTrial(currentUser))
    ) {
      dispatch(setMembershipPriceId(MEMBERSHIP.NO_MEMBERSHIP));
    }
  }, [currentUser]);

  return (
    <div
      className={classnames('SubmitButtons', {
        'read-only': isReadOnly,
        expanded: expandInvoice,
      })}
    >
      <InvoiceTotalSumArea
        quote={state.job?.quote}
        membershipPriceId={membershipPriceId}
        currentUser={currentUser}
        toggleIcon={expandInvoice ? 'caret-down' : 'caret-up'}
        toggleInvoiceSummary={() => setExpandInvoice(!expandInvoice)}
        isInvoiceExpanded={expandInvoice}
      />

      <div className='mb-2' />

      {!isReadOnly ? (
        <>
          <div className='submitButtonsFooter'>
            {isVisibleFor2DMap && (
              <Button
                style={{
                  width: 100,
                }}
                id='dashboardButton'
                variant='outline-primary'
                onClick={onSaveAndExit}
                disabled={state.isCartLoading}
              >
                {text(
                  mapState.isLoadingSmartSelections
                    ? 'saving'
                    : 'saveAndGoToDashboard'
                )}
              </Button>
            )}
            <Button
              id='orderDataButton'
              data-toggle='modal'
              data-target='#purchase'
              onClick={() =>
                handleSubmit(state.job, getSearchParam('coupon', location))
              }
              disabled={state.isCartLoading}
            >
              {`${grandTotal} - ${text('checkout')}`}
            </Button>
          </div>
        </>
      ) : (
        <Button
          className='full-width'
          onClick={() =>
            history.push({
              pathname: isJobAwaitingPayment
                ? routes.payment.job(state.job.id)
                : routes.dashboard,
            })
          }
        >
          {text(isJobAwaitingPayment ? 'payNow' : 'goToDashboard')}
        </Button>
      )}
    </div>
  );
};

export default SubmitButtons;
