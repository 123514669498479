import React from 'react';
import { Button } from 'react-bootstrap';
import T from 'prop-types';

import Icon from '../icon/Icon';
import classNames from 'classnames';

const IconButton = (props) => {
  const { icon, iconProps = {}, ...restProps } = props;
  return (
    <Button bsPrefix='btn-icon' {...restProps}>
      <Icon
        icon={icon}
        className={classNames({ original: iconProps.isOriginal })}
      />
    </Button>
  );
};

IconButton.propTypes = {
  icon: T.string,
};

export default IconButton;
