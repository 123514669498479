import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import Timeline from '../../components/timeline/Timeline';
import { listProjectActivities } from '../../redux/actions';
import { generateDefaultActivity } from '../../utilities/activity';
import { isStaff } from '../../utilities/user';
import JobActivity from './JobActivity';

const JobTimeline = () => {
  const dispatch = useDispatch();
  // TODO: confusing, projectId vs currentProject?
  const { projects, currentUser, currentProject, isStaffActivityListVisible } =
    useSelector((state) => ({
      projects: state.activity.projects,
      isStaffActivityListVisible: state.activity.isStaffActivityListVisible,
      currentUser: state.profileReducer.userProfile,
      currentProject: state.project.currentProject,
    }));
  const { projectId } = useParams();

  useEffect(async () => {
    if (projectId && !projects[projectId]?.ui?.isLoading) {
      dispatch(listProjectActivities(projectId));
    }
  }, [projectId]);

  const rawActivities = projects[projectId]?.activities?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  const activities = isEmpty(rawActivities)
    ? [generateDefaultActivity()]
    : filter(rawActivities, (activity) =>
        !isStaffActivityListVisible ? !isStaff(activity?.visibility) : true
      );
  // TODO: refactor job to project timeline and activity
  return (
    <Timeline className='JobTimeline'>
      {projects[projectId]?.ui?.isLoading ? (
        <Loading />
      ) : (
        activities &&
        activities.map((activity, i) => (
          <JobActivity
            key={i}
            currentProject={currentProject}
            jobActivity={activity}
            currentUser={currentUser}
          />
        ))
      )}
    </Timeline>
  );
};

export default JobTimeline;
