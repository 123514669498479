import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { alertConstants } from '../redux/constants';
import history from '../routes/history';
import { getUser } from './storage';

export const stripePromise = process.env.DISABLE_STRIPE
  ? null
  : loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

export const larkiApiNoAuthentication = axios.create({
  baseURL: process.env.LARKI_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data, headers) => {
      headers['Larki-Version'] = process.env.BUILD_NUMBER;

      return data;
    },
    ...axios.defaults.transformRequest, // don't lose axios' default JSON => string behaviour
  ],
});

export const larkiApi = axios.create({
  baseURL: process.env.LARKI_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data, headers) => {
      let sessionToken;
      try {
        sessionToken = getUser().token;
      } catch (err) {
        sessionToken = '<not logged in>'; // trigger usual unauthenticated error path
      }
      headers['Authorization'] = 'Bearer ' + sessionToken;
      headers['Larki-Version'] = process.env.BUILD_NUMBER;

      return data;
    },
    ...axios.defaults.transformRequest, // don't lose axios' default JSON => string behaviour
  ],
});

export const setupInterceptors = ({ dispatch }) => {
  larkiApi.interceptors.response.use(
    (response) => {
      if (response.headers['larki-version-outdated'] === 'true') {
        dispatch({
          type: alertConstants.ALERT_VERSION_OUTDATED,
        });
      }

      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        localStorage.clear();
        history.push('/login');
      } else {
        throw error;
      }
    }
  );
};

export const client = {
  // Projects
  listProjects: async ({ isAdmin, status, inviteStatus, includeDemo } = {}) =>
    larkiApi.get('/project', {
      params: { isAdmin, status, inviteStatus, includeDemo },
    }),
  getLatestProject: async ({
    isAdmin,
    inviteStatus,
    includeDemo,
    includeJobs,
    includePointCloud,
  }) =>
    larkiApi.get('/project/latest', {
      params: {
        isAdmin,
        inviteStatus,
        includeDemo,
        includeJobs,
        includePointCloud,
      },
    }),
  listProjectActivities: async (projectId, query) =>
    larkiApi.get(`/project/${projectId}/activities`, { params: { query } }),
  inviteUserToProject: async (projectId, email) =>
    larkiApi.post(`/project/${projectId}/user`, { email }),
  getProjectById: async (projectId, { includeJobs, includePointCloud }) =>
    larkiApi.get(`/project/${projectId}`, {
      params: { includeJobs, includePointCloud },
    }),
  createProject: async (name) => larkiApi.post('/project', { name }),
  removeUserFromProject: async (projectId, email) =>
    larkiApi.delete(`/project/${projectId}/user`, {
      data: {
        email,
      },
    }),
  listProjectUsers: async (projectId) =>
    larkiApi.get(`/project/${projectId}/users`),
  updateProjectName: async (projectId, name) =>
    await larkiApi.put(`/project/${projectId}/name`, {
      name,
    }),
  registerProjectTrialMembers: async (projectId, users) =>
    larkiApi.post(`/project/${projectId}/user/register-trial`, { users }),
  updateUserProjectTrial: async (projectId, userId, newProjectRole) =>
    larkiApi.put(`/project/${projectId}/user/${userId}/project-trial`, {
      projectRole: newProjectRole,
    }),
  mergeProjects: async (projectId, targetProjectId) =>
    larkiApi.post(`/project/${projectId}/merge`, {
      targetProjectId: targetProjectId,
    }),
  generateDownloadAllUrl: async (projectId) =>
    larkiApi.get(`/project/${projectId}/asset/download-url`),
  getDownloadAllUrl: async (projectId, jobId) =>
    larkiApi.get(`/project/${projectId}/asset/download-url/poll/${jobId}`),
  toResumeDownload: async (projectId) =>
    larkiApi.get(`/project/${projectId}/asset/to-resume-download`),
  // Job
  getJobById: async (jobId) => larkiApi.get(`/job/${jobId}`),
  updateJobDetailedStatus: async (jobId, detailedStatus, sendNotification) =>
    larkiApi.put(`/job/${jobId}/detailed-status`, {
      detailedStatus,
      sendNotification,
    }),
  getJobAsset: async (projectId, assetId) =>
    larkiApi.get(`/project/${projectId}/asset/${assetId}/download-url`),
  createActivity: async (projectId, activity) =>
    larkiApi.post(`/project/${projectId}/activity`, { ...activity }),
  updatePointerraId: async (projectId, pointerraId) =>
    larkiApi.put(`/project/${projectId}/pointerra`, { pointerraId }),
  // User
  onboardUser: async (
    { fullName, phoneNumber, profession, isMarketingEnabled },
    coupon
  ) =>
    larkiApi.post(
      '/user/onboarding',
      {
        full_name: fullName,
        phone_number: phoneNumber,
        profession: profession,
        is_marketing_enabled: isMarketingEnabled,
      },
      { params: { coupon } }
    ),
  listUserCards: async () => larkiApi.get('user/cards'),
  createDiscountCode: async (discountCode) =>
    larkiApi.post('/user/discount-code', { discountCode }),
  checkout: async (jobId) => larkiApi.post(`/job/${jobId}/checkout`),
  removeCard: async (paymentMethodId) =>
    larkiApi.delete(`/user/payment-method`, { params: { paymentMethodId } }),
  createPaymentMethodSetup: async () =>
    larkiApi.post(`/user/payment-method-setup`),
  getJobReceipt: async (jobId, paymentIntentId) =>
    larkiApi.get(`/job/${jobId}/receipt`, { params: { paymentIntentId } }),
  getMembershipReceipt: async (paymentIntentId) =>
    larkiApi.get('/user/membership/receipt', { params: { paymentIntentId } }),
  copyJob: (jobId) => larkiApi.post(`/job/${jobId}/copy`),
  deleteActivity: (projectId, activityId) =>
    larkiApi.delete(`/project/${projectId}/activity/${activityId}`),
  deleteAsset: (jobId, assetId) =>
    larkiApi.delete(`/job/${jobId}/asset/${assetId}`),
  getSmartSelections: (place) =>
    larkiApi.post('/engine/product/selections', place),
  getSelectionFeaturesFile: (jobId, selections, fileType) =>
    larkiApi.post(`/job/${jobId}/selections/download-url`, {
      selections,
      fileType,
    }),
  getTrialMembershipDetails: () =>
    larkiApi.get('/user/membership/trial-details'),
  createMembership: (priceId, paymentMethodId, isTrial) =>
    larkiApi.post('/user/membership', { priceId, paymentMethodId, isTrial }),
  updateMembership: (priceId, paymentMethodId) =>
    larkiApi.put('/user/membership', { priceId, paymentMethodId }),
  cancelMembership: (subscriptionId) =>
    larkiApi.delete(`/user/membership/${subscriptionId}`),
  refreshCustomer: () => larkiApi.put('/user/customer-refresh'),
  sendQuote: (priceId, email, name, message, jobId) =>
    larkiApi.post('/user/send-quote', {
      priceId,
      name,
      email,
      jobId,
      message,
    }),
  acceptQuote: (quoteId, token) =>
    larkiApi.post(`/user/accept-quote/${quoteId}?token=${token}`),
  getUserMembership: () => larkiApi.get('/user/membership'),

  createProjectAsset: (projectId, filename, contentType) =>
    larkiApi.post(`/project/${projectId}/asset`, {
      filename,
      contentType,
    }),

  commitProjectAsset: (projectId, assetId) =>
    larkiApi.put(`/project/${projectId}/asset/${assetId}/commit`),

  updateLotAreaState: (jobId, lotAreaState) =>
    larkiApi.put(`/job/${jobId}/lot-area-state`, lotAreaState),
  sendLead: (jobId, type) =>
    larkiApi.post(`/job/${jobId}/lead`, {
      type,
    }),
};

export const eventClient = {
  createPointerraEvent: async (type, point_cloud_id, detail) =>
    larkiApi.post('/event/pointerra', { type, point_cloud_id, detail }),
};

export const tourApi = {
  getTourStatus: async (modalName) =>
    larkiApi.get(`/user/tour-status/${modalName}`),
  putTourStatus: async (modalname) =>
    larkiApi.put(`/user/tour-status/${modalname}`),
};
