import React from 'react';
import { PulseLoader } from 'react-spinners';

const selectColor = (color) => {
  switch (color) {
    case 'white': {
      return '#fff';
    }
    default: {
      return '#000000';
    }
  }
};

const Loading = (props) => {
  const { color } = props;
  return (
    <div className='Loading'>
      <PulseLoader color={selectColor(color)} />
    </div>
  );
};

export default Loading;
