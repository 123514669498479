import { formatDistanceToNowStrict } from 'date-fns';
import { Form, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Checkbox from '../../../components/form/Checkbox';
import Control from '../../../components/form/Control';
import { softwares } from '../../../components/mapView/constants';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';
import { isJobPayable } from '../../../utilities/job';
import TextDivider from '../../../components/text/TextDivider';
import {
  userHasValidMembership,
  isStaff,
  isUserUnderTrial,
} from '../../../utilities/user';
import MembershipInvoice from '../Invoice/MembershipInvoice';
import Invoice from '../Invoice/Invoice';
import { profileSelectors } from '../../../redux/selectors/profile';

const ConfirmationForm = () => {
  const { jobId } = useParams();
  const { job, membershipPriceId, membershipPrices, currentUser } = useSelector(
    (state) => ({
      job: state.jobsReducer.job,
      membershipPriceId: state.order.membershipPriceId,
      membershipPrices: state.profileReducer.membershipPrices,
      currentUser: state.profileReducer.userProfile,
    })
  );
  const { values, handleChange } = useFormikContext();
  const hasValidMembership = useSelector(profileSelectors.hasValidMembership);

  const selectedMembership = useMemo(() => {
    if (membershipPrices.value) {
      return membershipPrices.value.find(
        (membership) => membership.id === membershipPriceId
      );
    }
  }, [membershipPriceId, membershipPrices]);

  const estimatedDelivery = useMemo(() => {
    if (!job.quote.expected_at) {
      return text('toBeConfirmed');
    }

    return formatDistanceToNowStrict(new Date(job.quote.expected_at), {
      roundingMethod: 'round',
    });
  }, [job]);

  const totalDataPrice = isMembershipPriceId(membershipPriceId)
    ? job.quote.price.member_grand_total
    : job.quote.price.grand_total;
  return (
    <Form className='ConfirmationForm'>
      <div className='orderSummary'>
        <h4>{text('confirmOrder')}</h4>
        <div className='d-flex'>
          <p className='pr-3 font-weight-bold'>{text('projectName')}:</p>
          <p>{job.project.name}</p>
        </div>
        <div className='d-flex'>
          <p className='pr-3 font-weight-bold'>{text('yourSoftware')}:</p>
          <p>{softwares.entities[job.customer_software].name}</p>
        </div>
        {job.message && (
          <>
            <p>{text('comments')}:</p>
            <Control
              className='Message'
              as='textarea'
              rows={3}
              value={job.message}
              readOnly
            />
          </>
        )}
      </div>
      <div className='totalSummary'>
        <TextDivider isComplete />
        {selectedMembership &&
          (!userHasValidMembership(currentUser) ||
            isUserUnderTrial(currentUser)) && (
            <Row className='Price'>
              <Col xs={12} md={6}>
                <div className='PriceHeader'>{text('membership')}:</div>
              </Col>
              <Col>
                <h4 className='text-right'>
                  {formatCurrency(
                    selectedMembership.grand_total,
                    job.quote.currency.name,
                    job.quote.currency.scale_factor,
                    {},
                    true
                  )}
                </h4>
              </Col>
            </Row>
          )}
        <Row className='Price'>
          <Col xs={12} md={6}>
            <div className='PriceHeader'>
              {text(isJobPayable(job) ? 'data' : 'dataPayLater')}:
            </div>
          </Col>
          <Col>
            <h4 className='text-right'>
              {totalDataPrice
                ? formatCurrency(
                    totalDataPrice,
                    job.quote.currency.name,
                    job.quote.currency.scale_factor,
                    {},
                    true
                  )
                : text('toBeConfirmed')}
            </h4>
          </Col>
        </Row>
        <Row className='Price'>
          <Col xs={12} md={6}>
            <div className='PriceHeader'>{text('deliveryTime')}:</div>
            <h4>~{estimatedDelivery}</h4>
          </Col>
          <Col xs={12} md={6}></Col>
        </Row>
      </div>
      <hr />
      <div className='invoiceSummary'>
        {!hasValidMembership && !isStaff(currentUser.role) && (
          <MembershipInvoice jobId={jobId} />
        )}
        <Invoice jobId={jobId} />
      </div>
      <div className='Terms'>
        <Checkbox
          onChange={handleChange}
          name='terms'
          id='terms'
          value={values.terms}
        >
          {text('acceptTermsAndConditions', {
            termsConditions: (
              <Link
                key='urlTerms'
                to={{ pathname: process.env.LARKI_URL_TERMS }}
                target={'_blank'}
              >
                {text('termsAndConditions')}
              </Link>
            ),
          })}
        </Checkbox>
      </div>
    </Form>
  );
};

export default ConfirmationForm;
