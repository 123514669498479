import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import text from '../../text';
import { IconButton } from '../button';
import Container from '../containers/Container';
import { MapViewContext } from '../mapView/mapViewContext';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';

/**
 * TODO: 
 * - Add icon and button align as column
 */

const AddOnButton = ({ show, header, description, handleClick }) => {
  const { state } = useContext(MapViewContext);
  const dispatch = useDispatch();

  const { mapState } = useSelector((reduxState) => ({
    mapState: reduxState.mapReducer,
    layoutState: reduxState.layout,
  }));

  if (!show) {
    return null;
  }

  return (
    <button
      style={{
        background: 'none',
        border: 'none',
        width: '100%',
        padding: 0,
        color: 'inherit',
        marginTop: '8px',
      }}
      onClick={handleClick}
      className='add-on-button'
    >
      <Container
        noBorder
        isSecondary
        className='d-flex justify-content-between p-3 align-items-center'
      >
        <div className='text-left'>
          <div className='d-flex flex-row align-items-center'>
            <p className='font-weight-bold m-0 mr-2'>{header}</p>
            <Icon icon='question' />
          </div>
          <p className='m-0' style={{ fontSize: '12px' }}>
            {description}
          </p>
        </div>
        <div className='d-flex flex-column add-button-container'>
          <IconButton iconProps={{ isOriginal: true }} icon='plus-filled' />
          <p className='green-1 m-0 font-weight-bold'>{text('add')}</p>
        </div>
      </Container>
    </button>
  );
};

AddOnButton.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  handleClick: PropTypes.func,
};

AddOnButton.defaultProps = {
  show: true,
  description: '',
};

export default AddOnButton;
