import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { IconButton } from '../../../components/button';
import WhiteContainer from '../../../components/containers/WhiteContainer';
import List from '../../../components/list/List';
import { getJob } from '../../../redux/actions';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';
import {
  isStaff,
  isUserGovernment,
  isUserUnderTrial,
  userHasActiveMembership,
  userHasValidMembership,
} from '../../../utilities/user';
import ChargeItem from './ChargeItem';
import classNames from 'classnames';
import { FREE_TIER_DISCOUNT } from '../../../constants/price';

export const InvoiceTotalSumArea = ({
  membershipPriceId,
  currentUser,
  quote,
  toggleIcon,
  toggleInvoiceSummary,
  isInvoiceExpanded,
}) => {
  const isEssentialUser = useMemo(() => {
    if (isUserGovernment(currentUser)) return true;

    return userHasValidMembership(currentUser) || isUserUnderTrial(currentUser);
  }, [currentUser, membershipPriceId]);

  const nonMemberPrice = useMemo(() => {
    if (quote && !isEmpty(quote.price)) {
      if (isUserGovernment(currentUser) || isEssentialUser) {
        return (
          (quote.price?.member_subtotal + quote.price?.member_discount_total) *
          ((100 - FREE_TIER_DISCOUNT) / 100)
        );
      }

      return quote.price?.grand_total;
    }

    return 0;
  }, [quote, isEssentialUser]);

  return !isEmpty(quote) && !isEmpty(currentUser) ? (
    <>
      <div
        style={{
          cursor: 'pointer',
          width: '100%',
        }}
        className='d-flex flex-row align-items-center justify-content-between mb-1 price-estimate-header'
        onClick={toggleInvoiceSummary}
      >
        <div
          className='text-uppercase green-1'
          style={{ fontWeight: 600, fontSize: '12px' }}
        >
          {text('priceEstimate')}
        </div>

        <div className='d-flex flex-row flex-fill align-items-center justify-content-end'>
          {!isMembershipPriceId(membershipPriceId) &&
            !isStaff(currentUser.role) &&
            !isInvoiceExpanded && (
              <div
                className={classNames('font-weight-bold mr-1 spans-container', {
                  isEssentialUser,
                })}
                style={{ fontSize: '12px' }}
              >
                (
                <span className='mr-1'>
                  {text(isEssentialUser ? 'memberSaved' : 'member')}:{' '}
                </span>
                <span>
                  {formatCurrency(
                    isEssentialUser
                      ? quote.price?.member_discount_total
                      : quote.price?.member_subtotal,
                    quote.currency.name,
                    quote.currency.scale_factor,
                    {},
                    true
                  )}
                </span>
                )
              </div>
            )}
          <IconButton
            iconProps={{ isOriginal: true }}
            icon={toggleIcon}
            onClick={toggleInvoiceSummary}
            className='p-0'
          />
        </div>
      </div>

      <List isPaddingless className='is-borderless'>
        <tbody>
          <List.Item
            description={`${text('subtotal')}:`}
            value={formatCurrency(
              isMembershipPriceId(membershipPriceId) &&
                !isStaff(currentUser.role)
                ? quote.price.member_subtotal
                : quote.price.subtotal,
              quote.currency.name,
              quote.currency.scale_factor,
              {},
              true
            )}
          />

          {/* DISCOUNTS */}
          {!isEmpty(quote.discounts) &&
            quote.discounts.map((discount, i) => (
              <List.Item
                isSecondaryHighlight
                key={`discounts-${i}`}
                description={
                  `${
                    discount.id === process.env.STRIPE_TRIAL_COUPON_ID
                      ? text('trialDiscount')
                      : discount.display_name
                  }:` // handled inline to avoid backfilling existing records
                }
                value={`—${formatCurrency(
                  discount.price?.discount,
                  quote.currency.name,
                  quote.currency.scale_factor,
                  {},
                  true
                )}`}
              />
            ))}

          {/* GST */}
          {map((tax, i) => (
            <List.Item
              key={`gst-${i}`}
              description={`${tax.display_name}:`}
              value={formatCurrency(
                tax.price.extra,
                quote.currency.name,
                quote.currency.scale_factor,
                {},
                true
              )}
            />
          ))(
            isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role)
              ? quote.member_taxes
              : quote.taxes
          )}

          {/* HIDE IF noMembershipId */}
          {quote?.price.member_grand_total &&
          quote?.delivery_method !== 'unknown' &&
          quote?.price.member_discount_total > 0 ? (
            <>
              <tr>
                <td colSpan={2}>
                  <hr className='mb-1 mt-2' />
                </td>
              </tr>
              {/* NON-MEMBER PRICE */}
              <List.Item
                className={'font-weight-bold green-1 non-member'}
                description={text('nonMemberPrice')}
                value={formatCurrency(
                  nonMemberPrice,
                  quote.currency.name,
                  quote.currency.scale_factor,
                  {},
                  true
                )}
              />

              {/* MEMBER DISCOUNT */}
              {quote?.price.member_automatic_discount_total !== 0 &&
                quote?.price.member_automatic_discount_total && (
                  <List.Item
                    className={'pink'}
                    isSecondaryHighlight
                    description={text('memberDiscount')}
                    value={formatCurrency(
                      quote.price?.member_automatic_discount_total,
                      quote.currency.name,
                      quote.currency.scale_factor,
                      {},
                      true
                    )}
                  />
                )}

              {/* MEMBER Services DISCOUNT */}
              {quote?.price.member_manual_discount_total !== 0 &&
                quote?.price.member_manual_discount_total && (
                  <List.Item
                    className={'pink'}
                    isSecondaryHighlight
                    description={text('servicesDiscount')}
                    value={formatCurrency(
                      quote.price?.member_manual_discount_total,
                      quote.currency.name,
                      quote.currency.scale_factor,
                      {},
                      true
                    )}
                  />
                )}

              {/* MEMBER PRICE */}
              <List.Item
                className={'pink font-weight-bold'}
                isSecondaryHighlight
                description={'Member Price'}
                value={formatCurrency(
                  quote.price?.member_subtotal,
                  quote.currency.name,
                  quote.currency.scale_factor,
                  {},
                  true
                )}
              />
            </>
          ) : null}
        </tbody>
      </List>
    </>
  ) : null;
};

InvoiceTotalSumArea.propTypes = {
  membershipPriceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentUser: PropTypes.object,
  quote: PropTypes.object,
  toggleIcon: PropTypes.string,
  toggleInvoiceSummary: PropTypes.func,
  isInvoiceExpanded: PropTypes.bool,
};

InvoiceTotalSumArea.defaultProps = {
  toggleIcon: 'caret-down',
  toggleInvoiceSummary: () => null,
};

const InvoicePaymentArea = ({ membershipPriceId, currentUser, quote }) => {
  return !isEmpty(quote) && !isEmpty(currentUser) ? (
    <List isPaddingless className='py-3'>
      {(isMembershipPriceId(membershipPriceId) ||
        userHasActiveMembership(currentUser)) &&
      !isStaff(currentUser.role) &&
      quote?.price.member_discount_total !== 0 &&
      quote?.price.member_discount_total ? (
        <tbody className='ChargeItem'>
          {quote?.price.member_automatic_discount_total !== 0 &&
            quote?.price.member_automatic_discount_total && (
              <List.Item
                isSecondaryHighlight
                description={text('memberDiscount')}
                value={`—${formatCurrency(
                  quote.price.member_automatic_discount_total,
                  quote.currency.name,
                  quote.currency.scale_factor,
                  {},
                  true
                )}`}
                key={'invoice-member-discount'}
              />
            )}
          {quote?.price.member_manual_discount_total !== 0 &&
            quote?.price.member_manual_discount_total && (
              <List.Item
                isSecondaryHighlight
                description={text('servicesDiscount')}
                value={`—${formatCurrency(
                  quote.price.member_manual_discount_total,
                  quote.currency.name,
                  quote.currency.scale_factor,
                  {},
                  true
                )}`}
                key={'invoice-service-discount'}
              />
            )}
        </tbody>
      ) : null}

      <tbody>
        <List.Item
          description={text('subtotal')}
          value={formatCurrency(
            isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role)
              ? quote.price.member_subtotal
              : quote.price.subtotal,
            quote.currency.name,
            quote.currency.scale_factor,
            {},
            true
          )}
          key={'invoice-subtotal'}
        />
        {map((tax, i) => (
          <List.Item
            key={i}
            description={tax.display_name}
            value={formatCurrency(
              tax.price.extra,
              quote.currency.name,
              quote.currency.scale_factor,
              {},
              true
            )}
          />
        ))(
          isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role)
            ? quote.member_taxes
            : quote.taxes
        )}

        {!isEmpty(quote.discounts) &&
          quote.discounts.map((discount, i) => (
            <List.Item
              isSecondaryHighlight
              key={i}
              description={discount.display_name}
              value={`—${formatCurrency(
                discount.price?.discount,
                quote.currency.name,
                quote.currency.scale_factor,
                {},
                true
              )}`}
            />
          ))}
        <List.Item
          isHighlighted
          description={text('total')}
          key={'invoice-total'}
          value={
            quote.price.base
              ? formatCurrency(
                  isMembershipPriceId(membershipPriceId) &&
                    !isStaff(currentUser.role)
                    ? quote.price.member_grand_total
                    : quote.price.grand_total,
                  quote.currency.name,
                  quote.currency.scale_factor,
                  {},
                  true
                )
              : text('toBeConfirmed')
          }
        />
      </tbody>
    </List>
  ) : null;
};

InvoicePaymentArea.propTypes = {
  membershipPriceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentUser: PropTypes.object,
  quote: PropTypes.object,
};

const Invoice = ({ jobId, hasValidMembership }) => {
  const dispatch = useDispatch();
  const { job, membershipPriceId, currentUser, isGettingJob } = useSelector(
    (state) => ({
      job: state.jobsReducer.job,
      membershipPriceId: state.order.membershipPriceId,
      currentUser: state.profileReducer.userProfile,
      isGettingJob: state.jobsReducer.isGettingJob,
    })
  );

  useEffect(async () => {
    if (jobId && !isGettingJob && isEmpty(job)) {
      dispatch(getJob(jobId));
    }
  }, [jobId]);

  if (!job) {
    return null;
  }

  const quote = job.quote;

  return (
    <WhiteContainer className='Invoice'>
      {!quote ? null : (
        <>
          <h6 className='green-1'>
            {text('invoiceType', {
              invoiceType: text('survey'),
              invoiceCount:
                hasValidMembership || !isMembershipPriceId(membershipPriceId)
                  ? '1.'
                  : '2.',
            })}
          </h6>
          <List>
            {quote.charges.map(
              ({ display_name, discounts, details, price, fees }, i) => (
                <ChargeItem
                  key={i}
                  displayName={text(`${details.category_name}3D`)}
                  discounts={discounts}
                  details={details}
                  price={price}
                  fees={fees}
                  quote={quote}
                  isIndividualDiscountVisible={
                    !userHasActiveMembership(currentUser) &&
                    !isMembershipPriceId(membershipPriceId)
                  }
                />
              )
            )}
            {quote.fees.map(({ display_name, price }, i) => (
              <ChargeItem
                key={i}
                displayName={display_name}
                price={price}
                quote={quote}
              />
            ))}
          </List>

          <InvoicePaymentArea
            quote={quote}
            currentUser={currentUser}
            membershipPriceId={membershipPriceId}
          />
        </>
      )}
    </WhiteContainer>
  );
};

Invoice.propTypes = {
  jobId: PropTypes.number,
  hasValidMembership: PropTypes.bool,
};

export default Invoice;
