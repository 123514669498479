import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useInterval } from 'react-use';

import { ANONYMOUS_HIGH_RES } from '../../constants/alert';
import { AMPLITUDE_EVENTS } from '../../constants/analytics';
import useMembershipButton from '../../hooks/useMembershipButton';
import routes from '../../routes/constants';
import text from '../../text';
import { amplitudeTrack } from '../../utilities';
import { larkiApi } from '../../utilities/api';
import { getHighResMapType } from '../../utilities/map';
import { isUserAnonymous } from '../../utilities/user';
import { MAP_TYPE } from './constants';
import { MapViewContext } from './mapViewContext';

/**
 *  Some of the css properties are overridden
 * adding style according to Google Maps docs
 * */
const style = {
  highResButton: {
    zIndex: 3,
  },
};

const { SITE_ADDRESS_ENTERED } = AMPLITUDE_EVENTS;

export function EnableMetromapButton() {
  const buttonRef = useRef(null);
  const { state } = useContext(MapViewContext);
  const history = useHistory();
  const { userProfile, layout } = useSelector((state) => ({
    userProfile: state.profileReducer.userProfile,
    layout: state.layout,
  }));

  const { handleMembershipClick } = useMembershipButton();

  const handleClick = () => {
    amplitudeTrack(SITE_ADDRESS_ENTERED.EVENT, {
      action: SITE_ADDRESS_ENTERED.CLICKED_ON_HIGH_RES,
    });

    if (isUserAnonymous(userProfile)) {
      return history.push({
        pathname: routes.login,
        search: `?alertType=${ANONYMOUS_HIGH_RES}&redirectUrl=${routes.order.job(
          state.job.id
        )}`,
      });
    } else {
      if (!state.isHighResEnabled) {
        return handleMembershipClick({
          redirectTo: {
            path: `${routes.order.root}/${state.job.id}`,
            label: text('navigateToRoute', {
              navigation: 'Back',
              routeName: text('map2D'),
            }),
          },
        });
      }

      const mapType = getHighResMapType(state.address);
      return state.map.setMapTypeId(mapType);
    }
  };

  useInterval(
    () => {
      larkiApi.post('/event/high-res-map-view', {
        job_id: state.job?.id,
      });
    },
    state.isHighResEnabled && state.map?.getMapTypeId() === MAP_TYPE.HIGH_RES
      ? 60000
      : null
  );

  return (
    <div className='enable-metromap-button-wrapper'>
      <button
        ref={buttonRef}
        id='high-res-button'
        className={`enable-metromap-button ${
          state.isHighResEnabled
            ? `switch-to-highres-layer-button${
                state.currentMapTypeId === MAP_TYPE.HIGH_RES ||
                (!state.currentMapTypeId &&
                  state.map?.mapTypeId === MAP_TYPE.HIGH_RES)
                  ? '-active'
                  : ''
              }`
            : `start-trial-button`
        }`}
        onClick={handleClick}
        style={style.highResButton}
        title={text('highResTooltip')}
      >
        High-res
        {!state.isHighResEnabled ? (
          <img
            style={{
              margin: '0 -5px 5px 5px',
            }}
            className='icon-img'
            src='/public/img/lock.svg'
            alt='star'
          />
        ) : null}
      </button>
    </div>
  );
}
