import { noop } from 'lodash/fp';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteProject, hideModal, alertSuccess } from '../../redux/actions';
import text from '../../text';

import { Button } from '../button';
import Icon from '../icon/Icon';

export const DELETE_PROJECT_MODAL = 'DELETE_PROJECT_MODAL';

const DeleteProjectModal = ({ show, projectId, name, onSuccess = noop }) => {
  const dispatch = useDispatch();
  const projectName = name ?? text('untitled');
  const onHide = () => dispatch(hideModal(DELETE_PROJECT_MODAL));

  const onOk = () => {
    dispatch(deleteProject(projectId));
    dispatch(
      alertSuccess(text('deleteProjectSuccess', { projectName }), {
        icon: 'trash',
        dismissible: true,
      })
    );
    onHide();
    onSuccess();
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header className='centered' closeButton>
        <Icon icon='circled-exclamation' />
      </Modal.Header>
      <Modal.Body className='text-center'>
        <h3 className='mb-3'>
          {text('areYouSureDeleteProject', {
            projectName,
          })}
        </h3>
        <div>{text('deleteWarningMessage')}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='full-width'
          variant='outline-primary'
          onClick={onHide}
        >
          {text('cancel')}
        </Button>
        <Button className='full-width' onClick={onOk}>
          {text('confirmDeleteProject')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteProjectModal;
