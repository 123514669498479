import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import _ from 'lodash/fp';
import { ORDER_FAILURE_MODAL } from '../../components/modal/OrderFailureModal';
import { QUOTING_FAILURE_MODAL } from '../../components/modal/QuotingFailureModal';
import { client, larkiApi } from '../../utilities/api';
import { createId } from '../../utilities/map';
import * as metroMapUtils from '../../utilities/metromap';
import { alertConstants, jobsConstants, mapConstants } from '../constants';
import { showModal } from './modal';

export const getMapQueryData = (payload) => async (dispatch) => {
  try {
    const { data } = await larkiApi.post('/engine/product/query', payload);

    dispatch({
      type: mapConstants.SET_MAP_DATA_QUERY,
      payload: data,
    });

    return data;
  } catch (error) {
    dispatch({
      type: mapConstants.SET_MAP_DATA_QUERY,
      payload: {},
    });
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const getMapDefaultQueryData = (payload) => async (dispatch) => {
  // like getMapQueryData but calls the SET_MAP_DEFAULT_DATA_QUERY action instead
  try {
    const { data } = await larkiApi.post('/engine/product/query', payload);
    dispatch({
      type: mapConstants.SET_MAP_DEFAULT_DATA_QUERY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: mapConstants.SET_MAP_DEFAULT_DATA_QUERY,
      payload: {},
    });
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const quoteOrder =
  (jobId, payload, includeFreeTierDiscount, property) => async (dispatch) => {
    try {
      dispatch({ type: jobsConstants.QUOTE_ORDER });
      const { data } = await larkiApi.post(
        `/job/${jobId}/order/quote`,
        { ...payload, ...(property && { property }) },
        {
          params: {
            ...(includeFreeTierDiscount && { includeFreeTierDiscount }),
          },
        }
      );

      dispatch({
        type: jobsConstants.QUOTE_ORDER_SUCCESS,
        payload: data,
      });

      return data;
    } catch {
      dispatch(showModal(QUOTING_FAILURE_MODAL));
    }
  };

export const commitOrder = (jobId) => async (dispatch) => {
  try {
    const { data } = await larkiApi.post(`/job/${jobId}/order/commit`);

    return data;
  } catch {
    dispatch(showModal(ORDER_FAILURE_MODAL));
  }
};

const createSmartSelections = (data) => {
  const createSelection = (type) => {
    return type && type.coverage && type.selection
      ? {
          id: createId(),
          region: type.selection.geometry,
          product_id: type.coverage?.product?.product_id,
          category_name: type.coverage?.product?.category_name,
          display_name: type.coverage?.product?.display_name,
          delivery_method: type.coverage?.product?.delivery_method,
        }
      : null;
  };

  const smartSelections = [];

  if (!isEmpty(data.property_vicinity)) {
    data.property_vicinity.forEach((vicinity) => {
      smartSelections.push(createSelection(vicinity));
    });
  }

  if (!isEmpty(data.neighbourhood.coverage)) {
    smartSelections.push(createSelection(data.neighbourhood));
  }

  if (!isEmpty(data.roads)) {
    smartSelections.push(
      ...data.roads.map((road) => ({
        ...createSelection(road),
        ui_state: {
          type: 'Polyline',
          line_path: road.selection.raw.coordinates,
        },
      }))
    );
  }

  if (data.buildings) {
    smartSelections.push(
      ...data.buildings.map((building) => createSelection(building))
    );
  }

  return smartSelections;
};

export const checkAutomaticSmartSelectionsAvailability =
  (place) => async (dispatch) => {
    dispatch({
      type: mapConstants.GET_PLACE_SMART_SELECTIONS,
      payload: { deliveryMethod: 'automatic' },
    });
    const { data } = await client.getSmartSelections(place);
    const smartSelections = createSmartSelections(data);
    const isAutomaticDataAvailable = smartSelections.some(
      (selection) => selection.delivery_method === 'automatic'
    );
    dispatch({
      type: mapConstants.SET_AUTOMATIC_SMART_SELECTIONS_AVAILABILITY,
      payload: {
        boundaries: !isEmpty(smartSelections) ? data.property : undefined,
        lotArea: data.property.area_in_sqm,
        isAutomaticDataAvailable,
      },
    });
    return isAutomaticDataAvailable;
  };

export const disableSmartSelectionsApi = (payload) => async (dispatch) => {
  dispatch({
    type: mapConstants.DISABLE_SMART_SELECTIONS_API,
    payload,
  });
};

export const getPlaceSmartSelection =
  (place, deliveryMethod) => async (dispatch) => {
    try {
      dispatch({
        type: mapConstants.GET_PLACE_SMART_SELECTIONS,
        payload: { deliveryMethod },
      });
      const { data } = await client.getSmartSelections(place);

      const smartSelections = createSmartSelections(data);
      const isAutomaticDataAvailable = smartSelections.some(
        (selection) => selection.delivery_method === 'automatic'
      );
      const filteredSelections = smartSelections.filter((selection) =>
        isAutomaticDataAvailable
          ? deliveryMethod && selection.delivery_method === deliveryMethod
          : true
      );

      dispatch({
        type: mapConstants.GET_PLACE_SMART_SELECTIONS_SUCCESS,
        payload: {
          boundaries: !isEmpty(filteredSelections) ? data.property : undefined,
          lotArea: data.property.area_in_sqm,
          smartSelections: filteredSelections,
        },
      });

      return filteredSelections;
    } catch {
      dispatch(showModal(ORDER_FAILURE_MODAL));
    }
  };

export const clearBoundaries = () => async (dispatch) =>
  dispatch({ type: mapConstants.CLEAR_BOUNDARIES });

export const clearSmartSelections = () => async (dispatch) =>
  dispatch({ type: mapConstants.CLEAR_SMART_SELECTIONS });

export const debouncedResetToggleBoundaryVisibility = (dispatch) =>
  debounce(
    () =>
      dispatch({
        type: mapConstants.RESET_ZOOM_TOGGLE_BOUNDARY_VISIBILITY,
      }),
    300
  );

export const setZoomToggle =
  (isZoomToggle = false) =>
  async (dispatch) => {
    dispatch({
      type: mapConstants.SET_ZOOM_TOGGLE,
      payload: { isZoomToggle },
    });

    if (isZoomToggle) {
      debouncedResetToggleBoundaryVisibility(dispatch)();
    }
  };

export const loadHighResCollections = () => async (dispatch) => {
  dispatch({ type: mapConstants.LOAD_HIGH_RES_COLLECTIONS });
  try {
    const collections = await metroMapUtils.getHighResCollections();
    dispatch({
      type: mapConstants.LOAD_HIGH_RES_COLLECTIONS_SUCCESS,
      payload: collections,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: mapConstants.LOAD_HIGH_RES_COLLECTIONS_FAILURE,
      payload: 'something went wrong',
    });
  }
};

export const loadHighResLayers =
  (collectionIds, bbox, currentDate = null) =>
  async (dispatch) => {
    dispatch({ type: mapConstants.LOAD_HIGH_RES_LAYERS });
    try {
      const layersArr = (
        await Promise.all(
          collectionIds.map(
            // see https://advancedweb.hu/how-to-use-async-functions-with-array-map-in-javascript/ for how this works
            async (collectionId) =>
              (await metroMapUtils.getHighResLayers(collectionId, bbox)) || []
          )
        )
      ).flat();
      const layers = metroMapUtils.groupHighResLayersByDate(layersArr);
      dispatch({
        type: mapConstants.LOAD_HIGH_RES_LAYERS_SUCCESS,
        payload: layers,
      });
      if (!currentDate || !_.has(currentDate, layers)) {
        const latestDate = _.keys(layers)?.[0];
        dispatch({
          type: mapConstants.SET_HIGH_RES_CURRENT_DATE,
          payload: latestDate,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: mapConstants.LOAD_HIGH_RES_LAYERS_FAILURE,
        payload: 'something went wrong',
      });
    }
  };

export const nextHighResLayer = () => (dispatch) => {
  dispatch({
    type: mapConstants.NEXT_HIGH_RES_LAYER,
  });
};

export const prevHighResLayer = () => (dispatch) => {
  dispatch({
    type: mapConstants.PREV_HIGH_RES_LAYER,
  });
};
