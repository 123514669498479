import { convertToGeoJson } from '../../utilities/map';
import { PRODUCT_DATA, Z_INDEX, defaultSelectionColor } from './constants';
import { MAP_STATES } from './drawingControls/drawingControls';

// MapManager is an abstraction for the Google Maps API.
// Other components shouldn't deal with the Google Maps API directly, and should instead use MapManager.
class MapManager {
  constructor(map) {
    this.map = map;
    this.availableDataOverlay = [];
    this.hoveredDataOverlay = [];
    this.boundariesDataOverlay = [];
    this.selectionDataOverlay = {};
    this.polygonStrokeColor = defaultSelectionColor;
    this.drawingMode = google.maps.drawing.OverlayType.RECTANGLE;
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: false,
    });
  }

  clearAvailableData() {
    this.availableDataOverlay.forEach((selection) => {
      selection.setMap(null);
    });
    this.availableDataOverlay = [];
  }

  clearHoveredData() {
    this.hoveredDataOverlay.forEach((selection) => {
      selection.setMap(null);
    });
    this.hoveredDataOverlay = [];
  }

  clearBoundariesData() {
    this.boundariesDataOverlay.forEach((boundary) => {
      boundary.setMap(null);
    });
    this.boundariesDataOverlay = [];
  }

  drawOverlayFromCoordinates(
    coordinates,
    category,
    hovered,
    focused,
    // TODO: it's not clear what "selected" means here, it's always true
    selected,
    isHovered = false
  ) {
    let paths = [];
    coordinates.forEach((polygonCoordinates) => {
      paths.push(
        polygonCoordinates.map((coordinate) => ({
          lat: coordinate[1],
          lng: coordinate[0],
        }))
      );
    });

    let strokeOpacity = 0.8;
    let strokeWeight = 3;
    let fillOpacity = 0.4;
    if (focused) {
      strokeWeight = 5.5;
      strokeOpacity = 1.0;
      fillOpacity = 0.5;
    } else if (hovered) {
      strokeWeight = 4;
      strokeOpacity = 1.0;
      fillOpacity = 0.5;
    }

    const categoryColor =
      hovered || selected
        ? PRODUCT_DATA.entities[category].display_color
        : '#9b9b9b';

    const overlay = new google.maps.Polygon({
      paths,
      strokeColor: categoryColor,
      strokeOpacity,
      strokeWeight,
      strokePosition: google.maps.StrokePosition.INSIDE,
      fillColor: categoryColor,
      fillOpacity,
      draggable: false,
      editable: false,
      map: this.map,
      zIndex: Z_INDEX.COVERAGE,
    });

    if (isHovered) {
      this.hoveredDataOverlay.push(overlay);
    } else {
      this.availableDataOverlay.push(overlay);
    }
  }

  drawDashedOverlay(coordinates) {
    const path = coordinates.map(([lng, lat]) => ({ lng, lat }));
    const overlay = new google.maps.Polyline({
      path,
      strokeColor: '#dc0822',
      strokeOpacity: 0,
      strokeWeight: 2,
      map: this.map,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillOpacity: 1,
            scale: 2,
          },
          offset: '0',
          repeat: '10px',
        },
      ],
    });

    this.boundariesDataOverlay.push(overlay);
  }

  drawSelectionRegion(selection, currentMapState, makeSelectionActive) {
    const paths = [];
    convertToGeoJson([selection]).features[0].geometry.coordinates.forEach(
      (polygonCoordinates) => {
        paths.push(
          polygonCoordinates.map((coordinate) => ({
            lat: coordinate[1],
            lng: coordinate[0],
          }))
        );
      }
    );

    const color = '#696767';
    const strokeOpacity = 0.5;
    const strokeWeight = 0.5;

    const overlay = new google.maps.Polygon({
      paths,
      strokeWeight: 0,
      strokePosition: google.maps.StrokePosition.INSIDE,
      fillOpacity: 0,
      clickable: true,
      draggable: false,
      editable: false,
      map: this.map,
      zIndex: Z_INDEX.CLICKABLE,
    });

    const outline = new google.maps.Polygon({
      paths,
      strokeColor: color,
      strokeOpacity,
      strokeWeight,
      strokePosition: google.maps.StrokePosition.INSIDE,
      fillOpacity: 0,
      clickable: false,
      draggable: false,
      editable: false,
      map: this.map,
      zIndex: Z_INDEX.SELECTION,
    });

    if (makeSelectionActive) {
      google.maps.event.addListener(overlay, 'click', (event) => {
        if (
          currentMapState !== MAP_STATES.DRAWING_RECTANGLE &&
          currentMapState !== MAP_STATES.DRAWING_POLYGON &&
          currentMapState !== MAP_STATES.DRAWING_POLYLINE
        ) {
          makeSelectionActive(selection);
        } else {
          google.maps.event.trigger(this.map, 'click', event);
        }
      });
    }
    google.maps.event.addListener(overlay, 'mousemove', (event) => {
      google.maps.event.trigger(this.map, 'mousemove', event);
    });

    this.availableDataOverlay.push(overlay);
    this.availableDataOverlay.push(outline);
  }
}

export { MapManager };
