import React from 'react';

import { TourProvider } from '@reactour/tour';
import Alerts from '../components/alert';
import VersioningAlert from '../components/alert/versioning';
import Footer from '../components/footer';
import ModalManager from '../components/modal/ModalManager';
import useLoadAmplitude from '../hooks/useLoadAmplitude';
import Routes from '../routes/routes';
import { initializeSentry } from '../utilities/sentry';
import { LayoutProvider } from './LayoutContext';
import Navbar from './dashboard/components/navigation/navbar/Navbar';
import GetStartedButton from './dashboard/components/onboarding/tour/GetStartedButton';
import TourContent from './dashboard/components/onboarding/tour/TourContent';

initializeSentry();

const App = () => {
  useLoadAmplitude();
  // useLoadContextual();
  return (
    <TourProvider
      steps={[]}
      ContentComponent={TourContent}
      onClickMask={() => {}}
    >
      <LayoutProvider>
        <div className='App'>
          <Navbar />
          <div className='alert-container text-center w-100'>
            <VersioningAlert />
            <Alerts />
          </div>
          <Routes />
          <Footer />
          <ModalManager />
          <GetStartedButton />
        </div>
      </LayoutProvider>
    </TourProvider>
  );
};

export default App;
