import cn from 'classnames';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import NavProjectSelect from '../../../../../components/select/jobSelect/NavProjectSelect';
import routes from '../../../../../routes/constants';
import text from '../../../../../text';

const FileManagerNav = (props) => {
  const { className } = props;
  const admin = useSearchParam('admin');
  const history = useHistory();
  const _projectId = location.pathname.split(`${routes.fileManager.root}/`)[1];
  const projectId = useMemo(
    () => (_projectId ? parseInt(_projectId, 10) : null),
    [_projectId]
  );
  return (
    <Nav className={cn('Title', className)}>
      <Navbar>
        <span>{`${text(
          admin ? 'administrationFileManager' : 'fileManager'
        )}:`}</span>
        <NavProjectSelect
          projectId={projectId}
          admin={admin}
          onProjectChange={(nextProject, prevProject) => {
            if (nextProject.id !== prevProject.id) {
              history.replace(routes.fileManager.viewProject(nextProject.id));
            }
          }}
        />
      </Navbar>
    </Nav>
  );
};

FileManagerNav.propTypes = {
  className: T.string,
};

export default FileManagerNav;
