import React, { useRef, useState } from 'react';
import { Button as BootstrapButton, Overlay, Tooltip } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';


import Icon from '../icon/Icon';
import useBreakpoint from '../../hooks/useBreakpoint';

const variantSpinnerColorMap = (variant) => {
  switch (variant) {
    case 'outline-primary':
      return '#027959';
    case 'disabled':
      return '#dcdcdc';
    default:
      return '#fff';
  }
};

const Button = ({
  children,
  loading,
  disabled,
  icon,
  tooltipProps,
  variant,
  onClick,
  ...rest
}) => {
  const target = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const isEnabledForBreakpoint = useBreakpoint(['md', 'lg', 'xl']);
  const enableTooltip = !isEmpty(tooltipProps) && isEnabledForBreakpoint;
  return (
    <>
      <BootstrapButton
        onBlur={() => enableTooltip && setIsTooltipVisible(false)}
        onMouseEnter={() => enableTooltip && setIsTooltipVisible(true)}
        onMouseLeave={() => enableTooltip && setIsTooltipVisible(false)}
        ref={target}
        disabled={disabled}
        variant={variant}
        onClick={loading ? noop : onClick}
        {...rest}
      >
        {loading ? (
          <PulseLoader
            color={variantSpinnerColorMap(!disabled ? variant : 'disabled')}
            size={12}
          />
        ) : (
          <>
            {icon && <Icon icon={icon} />}
            {children}
          </>
        )}
      </BootstrapButton>
      {enableTooltip && (
        <Overlay
          target={target.current}
          show={isTooltipVisible}
          placement={tooltipProps.placement}
        >
          {(props) => (
            <Tooltip id='button-tooltip' {...props}>
              {tooltipProps.text}
            </Tooltip>
          )}
        </Overlay>
      )}
    </>
  );
};

export default Button;
