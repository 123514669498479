import cn from 'classnames';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMount, useSearchParam } from 'react-use';

import NavProjectSelect from '../../../../../components/select/jobSelect/NavProjectSelect';
import useCurrentProject from '../../../../../hooks/useCurrentProject';
import { getUserProfile } from '../../../../../redux/actions';
import { alertConstants } from '../../../../../redux/constants';
import { profileSelectors } from '../../../../../redux/selectors/profile';
import routes from '../../../../../routes/constants';
import text from '../../../../../text';
import { jobHasPointCloud } from '../../../../../utilities/job';
import { isDemoProject } from '../../../../../utilities/project';

const Viewer3DNav = (props) => {
  const { className } = props;
  const admin = useSearchParam('admin');
  const { userProfileState } = useSelector((state) => state.profileReducer);
  const is3dViewerAccessibleToUser = useSelector(
    profileSelectors.get3dViewerEnabled
  );
  const history = useHistory();
  const location = useLocation();
  const _projectId = location.pathname.split(`${routes.view3D.root}/`)[1];
  const projectId = useMemo(
    () => (_projectId ? parseInt(_projectId, 10) : null),
    [_projectId]
  );
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getUserProfile());
  });

  useCurrentProject();

  return (
    <Nav className={cn('Title', className)}>
      <Navbar>
        <span>{`${text(admin ? 'administrationViewer3D' : 'viewer3D')}:`}</span>
        {userProfileState !== 'loading' && (
          <NavProjectSelect
            projectId={projectId}
            admin={admin}
            filterPredicate={(project) =>
              (is3dViewerAccessibleToUser || isDemoProject(project)) &&
              jobHasPointCloud(project)
            }
            onProjectChange={(nextProject, prevProject) => {
              if (nextProject.id !== prevProject.id) {
                history.replace(routes.view3D.project(nextProject.id));
                dispatch({
                  type: alertConstants.ALERT_RESET,
                });
              }
            }}
          />
        )}
      </Navbar>
    </Nav>
  );
};

Viewer3DNav.propTypes = {
  className: T.string,
};

export default Viewer3DNav;
