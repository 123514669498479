import { get } from 'lodash';
import debounce from 'lodash/fp/debounce';
import isEmpty from 'lodash/fp/isEmpty';
import keyBy from 'lodash/fp/keyBy';
import noop from 'lodash/noop';
import { JOB_FAILURE_MODAL } from '../../components/modal/JobFailureModal';
import { ORDER_FAILURE_MODAL } from '../../components/modal/OrderFailureModal';
import { RESET_FORM_DELAY } from '../../pages/constants';
import routes from '../../routes/constants';
import history from '../../routes/history';
import text from '../../text';
import { client, larkiApi } from '../../utilities/api';
import { jobsConstants, mapConstants } from '../constants';
import { alertError, alertSuccess } from './index';
import { showModal } from './modal';

const processJobList = (data) =>
  keyBy(
    'project_id',
    data.projects?.map((project) => ({
      // TODO: this is a temp fix to remove projects with no jobs; we should delete
      ...(!isEmpty(project.jobs)
        ? project.jobs[0]
        : {
            projectId: project.id,
          }),
      user_project: project.user_project,
      project,
      jobs: project.jobs,
      point_clouds: project.point_clouds,
      updated_at: project.updated_at,
    }))
  );

export const setJob = (job) => async (dispatch) =>
  dispatch({ type: jobsConstants.SET_JOB, payload: job });

export const setLotArea = (lotArea) => async (dispatch) =>
  dispatch({ type: mapConstants.SET_LOT_AREA, payload: { lotArea } });

export const getJob = (id) => async (dispatch) => {
  try {
    dispatch({
      type: jobsConstants.GET_JOB,
    });
    const { data } = await larkiApi.get(`/job/${id}`);
    dispatch({
      type: jobsConstants.GET_JOB_SUCCESS,
      payload: data,
    });
    const lotArea = get(data, 'user_order.property.area_in_sqm');
    if (lotArea) {
      await dispatch(setLotArea(lotArea));
    }
    return data;
  } catch (error) {
    dispatch({
      type: jobsConstants.GET_JOB_FAILED,
      payload: error.response?.data?.message,
    });
    if (error.response?.status === 403) {
      history.push(routes.accessDenied);
    } else {
      console.error(error);
      dispatch(alertError(error.response?.data?.message));
    }
  }
};

// TODO: Refactor this to its own project state
export const getJobsList = () => async (dispatch) => {
  try {
    dispatch({ type: jobsConstants.GET_JOBS_LIST });

    const { data } = await client.listProjects();

    dispatch({
      type: jobsConstants.SET_JOBS_LIST,
      jobsList: processJobList(data),
    });

    dispatch({
      type: jobsConstants.SET_JOB_STATUS_COUNTS,
      statusCounts: data.statusCounts,
    });
  } catch (error) {
    dispatch(alertError(error.response.data.message));
  }
};
// TODO: Refactor this to its own project state
export const getAdminJobsList =
  ({ status = [] } = {}) =>
  async (dispatch) => {
    dispatch({ type: jobsConstants.GET_ADMIN_JOB_LIST });
    try {
      const { data } = await client.listProjects({ isAdmin: true, status });

      dispatch({
        type: jobsConstants.SET_ADMIN_JOB_LIST,
        jobsList: processJobList(data),
      });

      dispatch({
        type: jobsConstants.SET_ADMIN_STATUS_COUNT,
        statusCounts: data.statusCounts,
      });
    } catch (error) {
      dispatch(alertError(error.response.data.message));
    }
  };

// export const getPointCloud = (jobId) => async (dispatch) => {
export const getPointCloud = (projectId) => async (dispatch) => {
  try {
    // const response = await larkiApi.get(`/job/${jobId}/get-point-cloud`);
    const response = await larkiApi.get(`/project/${projectId}/point-cloud`);

    if (response.status === 200) {
      dispatch({
        type: jobsConstants.SET_POINT_CLOUD,
        pointCloud: response.data,
      });
    }
  } catch (error) {
    if (error.response.status === 403) {
      history.push(routes.accessDenied);
    } else {
      dispatch(alertError(error.response.data.message));
    }
  }
};

export const createJob =
  ({ address, ...rest }) =>
  async (dispatch) => {
    try {
      const { data } = await larkiApi.post('/job', {
        ...address,
        ...rest,
      });

      dispatch({
        type: jobsConstants.SET_JOB,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch(showModal(JOB_FAILURE_MODAL));
    }
  };
// TODO: Refactor this to its own project state
export const resetUpdateJobProject = (projectId) => ({
  type: jobsConstants.RESET_UPDATE_JOB_PROJECT_NAME,
  payload: { id: projectId },
});

export const updateJob =
  ({ onConflict = noop, jobId, address, ...rest }) =>
  async (dispatch) => {
    try {
      const { data } = await larkiApi.put(`/job/${jobId}`, {
        ...address,
        ...rest,
      });

      dispatch({
        type: jobsConstants.SET_JOB,
        payload: data,
      });

      dispatch({
        type: jobsConstants.UPDATE_JOB_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      console.log(`Update job error: `, error);
      if (error.response.status === 409) {
        onConflict();
      } else {
        dispatch(showModal(JOB_FAILURE_MODAL));
      }
    }
  };
// TODO: Refactor this to its own project state
export const updateProjectName = (projectId, name) => async (dispatch) => {
  try {
    dispatch({
      type: jobsConstants.UPDATE_JOB_PROJECT_NAME,
      payload: { id: projectId },
    });

    const { data } = await client.updateProjectName(projectId, name);

    dispatch({
      type: jobsConstants.UPDATE_JOB_PROJECT_NAME_SUCCESS,
      payload: { id: projectId, project: data.project },
    });
  } catch (error) {
    dispatch({
      type: jobsConstants.UPDATE_JOB_PROJECT_NAME_FAILED,
      payload: { id: projectId },
    });
  } finally {
    debounce(RESET_FORM_DELAY, () => {
      dispatch(resetUpdateJobProject(projectId));
    })();
  }
};

export const commitJob = (jobId) => async (dispatch) => {
  try {
    await larkiApi.post(`/job/${jobId}/commit`);

    return true;
  } catch (error) {
    dispatch(showModal(ORDER_FAILURE_MODAL));
  }
};

export const deleteProject = (projectId) => async (dispatch) => {
  try {
    dispatch({
      type: jobsConstants.DELETE_PROJECT,
      payload: projectId,
    });
    await larkiApi.delete(`/project/${projectId}`);

    dispatch({
      type: jobsConstants.DELETE_PROJECT_SUCCESS,
      payload: projectId,
    });
  } catch (error) {
    dispatch(alertError(error.response.data.message));
  }
};

export const mergeProject = (project, targetProject) => async (dispatch) => {
  try {
    await client.mergeProjects(project.id, targetProject.id);
    dispatch(
      alertSuccess(
        text('projectsHaveMerged', {
          projectName: project.name,
        }),
        {
          icon: 'circled-tick',
        }
      )
    );
    return true;
  } catch (error) {
    dispatch(alertError(error.response.data.message));
    return false;
  }
};

export const updateProjectPointerraId =
  ({ projectId, pointerraId }) =>
  async (dispatch) => {
    await client.updatePointerraId(projectId, pointerraId);

    dispatch({
      type: jobsConstants.POINTERRA_ID_UPDATED,
      payload: {
        id: projectId,
        pointerraId,
      },
    });
  };

export const setListJobStatus = (status) => ({
  type: jobsConstants.SET_LIST_JOB_STATUS,
  payload: status,
});

export const setListJobSearchText = (status) => ({
  type: jobsConstants.SET_LIST_JOB_SEARCHTEXT,
  payload: status,
});

export const getJobReceipt = (jobId, paymentIntentId) => async (dispatch) => {
  try {
    dispatch({ type: jobsConstants.GET_JOB_RECEIPT });
    const { data } = await client.getJobReceipt(jobId, paymentIntentId);
    dispatch({
      type: jobsConstants.GET_JOB_RECEIPT_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch(showModal(jobsConstants.GET_JOB_RECEIPT_FAILED));
  }
};

export const copyJob = (jobId) => async (dispatch) => {
  try {
    const { data } = await client.copyJob(jobId);

    dispatch({
      type: jobsConstants.ADD_COPIED_JOB,
      payload: data,
    });
  } catch (error) {
    dispatch(alertError(error.response.data.message));
  }
};

export const clearJob = () => async (dispatch) =>
  dispatch({
    type: jobsConstants.CLEAR_JOB,
  });

export const toggleJobBoundaryVisibility = (isVisible) => async (dispatch) => {
  dispatch({
    type: jobsConstants.TOGGLE_JOB_BOUNDARY_VISIBILITY,
    payload: isVisible,
  });
};

export const toggleJobBoundaryHidden = (isHidden) => async (dispatch) => {
  dispatch({
    type: jobsConstants.TOGGLE_JOB_BOUNDARY_HIDDEN,
    payload: isHidden,
  });
};

export const setUndefinedAddress = (status) => async (dispatch) => {
  dispatch({
    type: jobsConstants.SET_UNDEFINED_ADDRESS,
    payload: status,
  });
};

export const updateLotAreaState = (jobId, lotAreaState) => async (dispatch) => {
  try {
    const job = await client.updateLotAreaState(jobId, lotAreaState);
  } catch (error) {
    console.warn(
      error.response?.data?.message
        ? error.response.data.message
        : text('putTourStatusFail')
    );
  }
};

export const sendLead = (jobId, triggerType) => async (dispatch) => {
  if (process.env.DISABLE_CRM) return;

  try {
    await client.sendLead(jobId, triggerType);
  } catch (error) {
    console.warn(
      error.response?.data?.message
        ? error.response.data.message
        : 'An error occurred on sending lead.'
    );
  }
};
