import { useTour } from '@reactour/tour';
import React, { useContext, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { GET_STARTED_TOURS, TOURS } from '.';
import routes from '../../../../../routes/constants';
import Icon from '../../../../../components/icon/Icon';
import text from '../../../../../text';
import { IconButton } from '../../../../../components/button';
import { MapViewContext } from '../../../../../components/mapView/mapViewContext';
import { isRouteIncluded } from '../../../../../utilities/routes';
import { useDispatch } from 'react-redux';
import { setTourSelected } from '../../../../../redux/actions/tour';

export default function GetStartedButton() {
  const dispatch = useDispatch();
  const { setSteps, setCurrentStep } = useTour();
  const history = useHistory();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  const shouldShowGetStartedButton = useMemo(
    () =>
      isRouteIncluded([
        routes.dashboard,
        routes.fileManager.root,
        routes.user.profile,
      ])(location.pathname),
    [location.pathname]
  );

  const toggleDropdown = (isOpen) => {
    setShowMenu(isOpen);
  };

  return (
    shouldShowGetStartedButton && (
      <div id='tour-menu'>
        <Dropdown
          bsPrefix='dropdown-tour-container'
          onToggle={toggleDropdown}
          show={showMenu}
        >
          <Dropdown.Toggle id='dropdown-tour-menu'>
            <Icon icon='question-circled-white' />
            <span className='mr-1'>{text('getStarted')}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className='position-absolute' style={{ right: 8 }}>
              <IconButton
                icon='circled-close'
                onClick={() => toggleDropdown(false)}
              />
            </div>

            <Dropdown.Header className='d-flex flex-column align-items-center'>
              <h4 className='green-1'>{text('Tours')}</h4>
            </Dropdown.Header>
            <Dropdown.Item
              onClick={() => {
                dispatch(setTourSelected(TOURS.TOUR_2D_MAP));
                history.push(routes.order.root);
              }}
            >
              {text('getStarted2D')}
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              onClick={() => {
                dispatch(setTourSelected(TOURS.TOUR_FILE_MANAGER));
                setSteps(GET_STARTED_TOURS[TOURS.TOUR_FILE_MANAGER]);
                setCurrentStep(0);
                if (
                  !isRouteIncluded([routes.fileManager.root])(location.pathname)
                ) {
                  history.push(routes.fileManager.root);
                }
              }}
            >
              {text('getStartedFileManager')}
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              onClick={() => {
                dispatch(setTourSelected(TOURS.TOUR_3D_VIEWER));
                setSteps(GET_STARTED_TOURS[TOURS.TOUR_3D_VIEWER]);
                setCurrentStep(0);
                history.push(routes.view3D.root);
              }}
            >
              {text('getStarted3D')}
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Header className='d-flex flex-column align-items-center'>
              <h4 className='green-1'>{text('help')}</h4>
            </Dropdown.Header>

            <Dropdown.Divider />

            <Dropdown.Item
              onClick={() => {
                window.open(
                  'https://help.pointerra.io/articles/40420-the-viewer-interface',
                  '_blank'
                );
              }}
            >
              {text('getStartedPointerraHelp')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  );
}
