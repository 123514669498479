export const FREE = 'free';
export const TRIAL = 'trial';
export const ESSENTIALS = 'essentials';
export const LARKI_SUPPORT = 'larkiSupport';
export const GOVERNMENT = 'government';

export const USER_TYPE = {
  FREE,
  TRIAL,
  ESSENTIALS,
  LARKI_SUPPORT,
  GOVERNMENT,
};
