import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { Tabs, Tab } from 'react-bootstrap';

import Titlebar from '../../pages/dashboard/components/navigation/navbar/Titlebar';

const Page = (props) => {
  const { id, children, className, title, noPadding } = props;
  return (
    <div id={id} className={cn('Page', { [className]: className, noPadding })}>
      <Helmet>
        <title>{title ? `LARKI - ${title}` : `LARKI`}</title>
      </Helmet>
      <Titlebar />
      {children}
    </div>
  );
};

const PageControl = (props) => {
  const { children, className } = props;
  return (
    <div className={cn('PageControl', { [className]: className })}>
      {children}
    </div>
  );
};

const PageTop = (props) => {
  const { children } = props;
  return <div className='PageTop'>{children}</div>;
};
const PageTopControl = (props) => {
  const { children } = props;
  return <div className='PageTopControl'>{children}</div>;
};

export const PageBody = (props) => {
  const { children, className } = props;
  return (
    <div className={cn('PageBody', { [className]: className })}>
      {children || null}
    </div>
  );
};

Page.Top = PageTop;
Page.Top.Control = PageTopControl;
Page.Body = PageBody;
Page.Control = PageControl;

export default Page;
