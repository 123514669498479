import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import { DETAILED_STATUS, STATUS } from '../constants/job';
import { convertSelectionToRegionSelection } from './map';

export const isJobViewable = (job) =>
  (job.status === 'completed' &&
    get('user_project.invite_status', job) === 'accepted' &&
    !isEmpty(job.point_clouds)) ||
  job.status === 'demo';

export const isDetailedStatusCompleted = (status) =>
  status && status === DETAILED_STATUS.COMPLETED;
export const isDetailedStatusInProgress = (status) =>
  status && status === DETAILED_STATUS.IN_PROGRESS;

export const jobHasPointCloud = get('point_clouds.0.pointerra_id');

export const anySelectionVisible = (selections) =>
  selections && selections.some((selection) => selection.visible);

export const getSelectionProductId = get('product_id');

export const allSelectionsVisible = (selections) =>
  selections.every((selection) => selection.visible);

export const allProductSelectionsVisible = (selections, productId) =>
  selections &&
  productId &&
  allSelectionsVisible(
    selections.filter(
      (selection) => getSelectionProductId(selection) === productId
    )
  );

export const isJobPayable = (job) =>
  job?.status &&
  [STATUS.DRAFT].includes(job?.status) &&
  job?.quote?.delivery_method === 'automatic';

export const isDemoJob = (job) => job && job.status && job.status === 'demo';

export const transformUserOrderToSelections = (job) => {
  const selectionsToResume = [];
  if (job.user_order?.selections) {
    job.user_order.selections.forEach((selection) => {
      const category_name =
        job?.coverage?.products?.[selection?.product_id]?.category_name ||
        'unknown';
      const display_name =
        job?.coverage?.products?.[selection?.product_id]?.display_name ||
        'Unknown';
      const delivery_method =
        job?.coverage?.products?.[selection?.product_id]?.delivery_method;

      selectionsToResume.push({
        created_at: selection.created_at,
        product_id: selection.product_id,
        available: true,
        category_name,
        display_name,
        delivery_method,
        region: convertSelectionToRegionSelection(selection),
        id: selection.id,
        name: selection.ui_state.name,
        layers: { ...job?.coverage?.layers },
        products: { ...job?.coverage?.products },
        visible: true,
        parent_selection_id: selection.parent_selection_id || '',
      });
    });
  }
  return selectionsToResume;
};
