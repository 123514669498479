import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';
import { useWizard } from 'react-use-wizard';

import { Button } from '../../components/button';
import FormContainer from '../../components/containers/FormContainer';
import { alertConstants } from '../../redux/constants';
import { larkiApiNoAuthentication } from '../../utilities/api';
import { getUser } from '../../utilities/storage';
import { isUserAnonymous } from '../../utilities/user';

const CreateNewAccount = ({ onGoogleRedirect }) => {
  const dispatch = useDispatch();
  const { values, resetForm } = useFormikContext();
  const { previousStep, nextStep, goToStep } = useWizard();
  const [isLoading, setLoading] = useState(false);
  const redirectUrl = useSearchParam('redirectUrl');
  const coupon = useSearchParam('coupon');
  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));

  const onSignup = async () => {
    try {
      setLoading(true);
      let headers = {};
      if (isUserAnonymous(currentUser)) {
        headers['Authorization'] = `Bearer ${getUser().token}`;
      }
      const { status } = await larkiApiNoAuthentication.post(
        '/user/sign-up',
        {
          ...values,
          ...(process.env.DISABLE_EMAIL && { noEmail: true }),
          ...(redirectUrl && { redirectUrl }),
        },
        { headers, params: { ...(coupon && { coupon }) } }
      );

      sessionStorage.clear();

      if (status === 201) {
        nextStep();
      } else {
        goToStep(2);
      }
    } catch (error) {
      if (error.response.status === 403) {
        await onGoogleRedirect(); // the email provided has used google before, try login with google
      } else {
        dispatch({
          type: alertConstants.ALERT_ERROR,
          payload: { message: error.response.data.message },
        });
        setLoading(false);
      }
    }
  };

  const onBack = () => {
    resetForm();
    previousStep();
  };

  return (
    <FormContainer className='CreateNewAccount'>
      <FormContainer.SecondaryHeader>
        <h2>New account?</h2>
      </FormContainer.SecondaryHeader>
      <FormContainer.Body>
        <FormContainer.PrimaryHeader>
          <h2>New account?</h2>
        </FormContainer.PrimaryHeader>
        <p className='text-center mb-2'>
          Would you like to create a new account using the below email address?
        </p>
        <p className='text-center font-weight-bold'>{values.email}</p>
        <div className='CreateNewAccountButtons'>
          <Button variant='outline-primary' onClick={onBack}>
            Back
          </Button>
          <Button onClick={onSignup} loading={isLoading}>
            Create new account
          </Button>
        </div>
      </FormContainer.Body>
    </FormContainer>
  );
};

export default CreateNewAccount;
