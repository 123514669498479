import classNames from 'classnames';
import { isEmpty, isObject } from 'lodash';
import _ from 'lodash/fp';
import React, { memo, useContext, useEffect, useMemo, useRef } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_CURRENCY } from '../../constants/price';
import { PRODUCT_TYPE, SALES_HELP_DISCOUNT } from '../../constants/product';
import { USER_TYPE } from '../../constants/user';
import { useClickOutside } from '../../hooks/useClickOutside';
import { clearBoundaries, showModal } from '../../redux/actions';
import text, { formatCurrency, formatSqmArea } from '../../text';
import { formatDate, formatMonth } from '../../utilities/date';
import { convertToGeoJson } from '../../utilities/map';
import { isMembershipPriceId } from '../../utilities/membership';
import { roundToNearest } from '../../utilities/number';
import {
  isStaff,
  isUserGovernment,
  isUserUnderTrial,
  userHasValidMembership,
} from '../../utilities/user';
import SimpleDataRow from '../RightMenu/SimpleDataRow';
import CollapseButton from '../button/CollapseButton';
import Icon from '../icon/Icon';
import { computeArea, computeUnion } from '../mapView/geometry';
import { MapViewContext } from '../mapView/mapViewContext';
import ProductBadge from '../mapView/productControls/productBadge/ProductBadge';
import DataTable from '../table/dataTable/DataTable';
import { DELETE_SHAPES_MODAL } from './DeleteShapesModal';
import ProductShapeMenuModal from './ProductShapeMenuModal';
import { IconWrapper } from '../RightMenu/Product';

// Temporarily hide these buttons until the underlying features are implemented
const HIDE_PREVIEW = true;
const HIDE_SCREENSHOT = true;
const SQM_IN_SQKM = 1e6;
const SHOW_HEADER = !HIDE_PREVIEW || !HIDE_SCREENSHOT;

const WithLoader = ({
  children,
  hasCharges = true,
  className,
  showLoaderText = true,
  fullWidth = true,
}) => {
  if (hasCharges) return children;
  return (
    <div
      className={classNames('d-flex flex-row align-items-center', className, {
        'flex-fill': fullWidth,
      })}
    >
      <Spinner animation='border' className='size-12 grey-3' />
      {showLoaderText && (
        <p className='mb-0 ml-1 font-12 grey-3'>{text('fetching')}</p>
      )}
    </div>
  );
};

export default memo(function ProductSelectedModal() {
  const { state, actions } = useContext(MapViewContext);
  const dispatch = useDispatch();
  const { data, product: productType, isReadOnly } = state.productSelected;
  const dataRepresentative = data[1][0];
  const currency = state.job?.quote?.currency || DEFAULT_CURRENCY;
  const productModalPopupRef = useRef(null);

  // redux selector
  const { currentUser, membershipPriceId } = useSelector((reduxState) => ({
    currentUser: reduxState.profileReducer.userProfile,
    membershipPriceId: reduxState.order.membershipPriceId,
  }));

  const isProductSelectedFocused = useMemo(
    () => state.activeSelection?.category_name === productType,
    [state.activeSelection, productType]
  );

  useClickOutside(
    productModalPopupRef,
    () => actions.clearProductSelected(),
    [
      '#product-dropdown-menu-container .modal-content',
      '.product-row-container',
      '.layers-panel-container .layer',
    ],
    isProductSelectedFocused
  );

  const isEssentialUser = useMemo(() => {
    if (isUserGovernment(currentUser)) return true;

    return userHasValidMembership(currentUser) || isUserUnderTrial(currentUser);
  }, [currentUser]);

  const selectionsPerProduct = useMemo(() => {
    const products = new Map();
    if (state.selections) {
      state.selections.forEach((selection) => {
        if (products?.[selection.category_name]) {
          products[selection.category_name] = [
            ...products[selection.category_name],
            selection,
          ];
        } else {
          products[selection.category_name] = [selection];
        }
      });
    }
    return products;
  }, [state.selections]);

  const charges = useMemo(() => {
    const products = new Map();
    if (state.job?.quote?.charges) {
      state.job.quote.charges.forEach((charge) => {
        products[charge.display_name] = { ...charge };
      });
    }
    return products[dataRepresentative.display_name];
  }, [state.job, data]);

  const age = useMemo(() => {
    if (!charges) {
      return;
    }

    const acquiredDates = charges.items.reduce((acc, next) => {
      if (!next.details.acquired_at) {
        return []; // to avoid undefined
      }
      return [...acc, formatDate(next.details.acquired_at)];
    }, []);

    if (acquiredDates.length <= 0) {
      return;
    }

    const sortedAcquiredDates = Array.from(new Set(acquiredDates)).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );

    const [startDate, endDate] = [
      sortedAcquiredDates[0],
      sortedAcquiredDates[sortedAcquiredDates.length - 1],
    ];

    if (startDate !== endDate) {
      return text('age', {
        dates: `${formatMonth(startDate)} - ${formatMonth(endDate)}`,
      });
    }

    return text('age', { dates: formatMonth(startDate) });
  }, [charges]);

  const shapes = useMemo(() => {
    return selectionsPerProduct[productType];
  }, [selectionsPerProduct, productType]);

  const productTotalArea = useMemo(() => {
    if (!charges) {
      return {
        formatted: '',
        value: 0,
      };
    }

    if (charges?.details?.area_in_sqm) {
      if (charges.details.area_in_sqm > SQM_IN_SQKM) {
        return {
          formatted: `${(
            charges.details.area_in_sqm / SQM_IN_SQKM
          ).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })} km²`,
          value: charges.details.area_in_sqm / SQM_IN_SQKM,
        };
      }

      return {
        formatted: formatSqmArea(charges.details.area_in_sqm),
        value: charges.details.area_in_sqm,
      };
    }

    return {
      formatted: '',
      value: 0,
    };
  }, [charges]);

  // Only calculate for Interior shapes union since
  // it's the only shape that computes area linearly
  // other shapes area is equal to union by default
  const shapesUnionArea = useMemo(() => {
    if (
      ![
        PRODUCT_TYPE.STREETSCAPE,
        PRODUCT_TYPE.INTERIOR,
        PRODUCT_TYPE.BIM_INTERIOR,
        PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
      ].includes(productType)
    ) {
      return {
        formatted: '',
        value: 0,
      };
    }

    const shapeCoordinates = convertToGeoJson(
      state.selections?.filter(
        (selection) =>
          selection.display_name === dataRepresentative.display_name
      )
    );

    const unionCoordinates = [...shapeCoordinates.features].reduce(
      (prev, next) => {
        if (!isEmpty(prev)) {
          return computeUnion(prev, next.geometry);
        }
        return next.geometry;
      },
      {}
    );

    let unionArea = !isEmpty(unionCoordinates) && computeArea(unionCoordinates);

    if (unionArea) {
      const diff = Math.abs(Math.floor(unionArea) - productTotalArea.value);
      unionArea = roundToNearest(unionArea, 1);

      if (diff <= 1) {
        // deny shape union if difference between area & union is <= 1,
        // this happens due to rouding off of area
        unionArea = 0;
      }

      if (unionArea > SQM_IN_SQKM) {
        return {
          formatted: `${(unionArea / SQM_IN_SQKM).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })} km²`,
          value: unionArea / SQM_IN_SQKM,
        };
      }

      return {
        formatted: unionArea > 0 ? formatSqmArea(unionArea) : '',
        value: unionArea,
      };
    }
  }, [productTotalArea]);

  const price = useMemo(() => {
    if (!charges) {
      return {
        formatted: '',
        value: 0,
      };
    }

    if (!charges || !charges.price) {
      return {
        formatted: text('notAvailable'),
        value: null,
      };
    }
    if (!charges.price.total) {
      return {
        formatted: text('toBeConfirmed'),
        value: null,
      };
    }

    const price = isEssentialUser
      ? charges.price.member_total
      : charges.price.total;

    return {
      formatted: formatCurrency(
        price,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
      value: price,
    };
  }, [charges?.price, currency]);

  const handleDelete = async (event) => {
    const selectionsToDelete = state.selections.filter(
      (selection) => selection.product_id === dataRepresentative.product_id
    );

    const childrenSelections = state.selections.filter((selection) =>
      selectionsToDelete.some(
        (selectionToDelete) =>
          selectionToDelete.id === selection.parent_selection_id
      )
    );

    if (state.selections.length === selectionsToDelete.length) {
      dispatch(clearBoundaries());
    }

    dispatch(
      showModal(DELETE_SHAPES_MODAL, {
        selectionsToDelete: [...selectionsToDelete, ...childrenSelections],
        associatedAddOns: childrenSelections,
      })
    );
    actions.clearProductSelected();
    event.stopPropagation();
  };

  const isAllSelectionsVisible = state.selections
    ? _.every(
        ['visible', true],
        _.filter(['category_name', productType])(state.selections)
      )
    : false;

  const isAllSelectionsHidden = state.selections
    ? _.every(
        ['visible', false],
        _.filter(['category_name', productType])(state.selections)
      )
    : false;

  // TODO: move to context
  // this type of functionality should really be abstracted away from components
  // we should have a setVisible method for a selection that automatically handles cases such as when the active selection becomes hidden
  const toggleProductVisibility = () => {
    let didActiveSelectionBecomeHidden = false;
    actions.setSelections(
      state.selections.map((selection) => {
        if (selection.display_name !== dataRepresentative.display_name) {
          return { ...selection };
        }
        const newVisible = !isAllSelectionsVisible ? true : !selection.visible;
        if (
          state.activeSelection &&
          state.activeSelection.id === selection.id
        ) {
          didActiveSelectionBecomeHidden = !newVisible;
        }
        return {
          ...selection,
          visible: newVisible,
          focused: false,
        };
      }) || []
    );
    if (didActiveSelectionBecomeHidden) {
      actions.setActiveSelectionId(null);
      actions.setGeometriesToLoad({});
    }
  };

  const isFreeOrTrial = useMemo(() => {
    if (!charges) return;

    return (
      !isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role)
    );
  }, [charges?.price]);

  const hasMemberDiscount = useMemo(() => {
    if (!charges) return;

    return charges.price?.member_discount_total > 0;
  }, [charges?.price]);

  const memberDiscount = useMemo(() => {
    const totalAmount = charges ? charges.price.member_total : 0;
    const savingAmount = charges ? charges.price.member_discount_total : 0;

    const saving = formatCurrency(
      savingAmount,
      currency.name,
      currency.scale_factor,
      {},
      true
    );

    if (!isEssentialUser) {
      return text('memberPriceDiscount', {
        totalAmount: formatCurrency(
          totalAmount,
          currency.name,
          currency.scale_factor,
          {},
          true
        ),
        saving,
      });
    }

    return (
      <>
        <span className='text-strikethrough'>
          {text('fullPrice', {
            totalAmount: formatCurrency(
              charges?.price?.subtotal,
              currency.name,
              currency.scale_factor,
              {},
              true
            ),
          })}
        </span>
        {', '}
        {text('savingAmount', { saving })}
      </>
    );
  }, [isEssentialUser, charges?.price]);

  const priceWithSalesHelp = useMemo(() => {
    if (!charges) {
      return {
        formatted: '',
        value: 0,
      };
    }
    const userType = isEssentialUser ? USER_TYPE.ESSENTIALS : USER_TYPE.FREE;
    const salesHelpDiscount = SALES_HELP_DISCOUNT[productType][userType];

    if (salesHelpDiscount <= 0) {
      return text('notAvailable');
    }

    if (!charges || !charges.price) {
      return text('notAvailable');
    }
    if (!charges.price.total) {
      return text('toBeConfirmed');
    }
    const salesHelpSaving = charges.price.subtotal * (salesHelpDiscount / 100);
    return {
      total: formatCurrency(
        price.value + salesHelpSaving,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
      saved: formatCurrency(
        salesHelpSaving,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
    };
  }, [charges?.price, currency, productType]);

  const eyeIcon = useMemo(() => {
    return isAllSelectionsVisible
      ? 'eye'
      : isAllSelectionsHidden
      ? 'eye-slash'
      : 'eye-mixed-state';
  }, [isAllSelectionsVisible, isAllSelectionsHidden]);

  const CloseButton = () => (
    <Button
      onClick={() => {
        if (state.activeSelection) {
          actions.setShowProductModal(false);
        } else {
          actions.clearProductSelected();
        }
      }}
      variant='light'
      className='row-action icon-button bg-transparent'
    >
      <Icon icon='close' />
    </Button>
  );

  useEffect(() => {
    if (!shapes || shapes?.length <= 0) {
      actions.clearProductSelected();
    }
  }, [shapes]);

  return (
    <>
      <Modal.Dialog id='product-modal' ref={productModalPopupRef}>
        <CollapseButton
          id='productInfoCollapseButton'
          openDirection='down'
          isOpen={state.isProductModalExpanded}
          closeText={'More info'}
          className={'flex-row-reverse '}
          onClick={() =>
            actions.setIsProductModalExpanded(!state.isProductModalExpanded)
          }
        />

        {SHOW_HEADER && (
          <Modal.Header>
            <div className='d-flex flex-row' style={{ gap: 16 }}>
              {!HIDE_PREVIEW && (
                <Button
                  variant='light'
                  className='row-action with-text icon-button'
                >
                  <Icon icon='preview' />
                  <span>{text('preview')}</span>
                </Button>
              )}

              {!HIDE_SCREENSHOT && (
                <Button
                  variant='light'
                  className='row-action with-text icon-button'
                >
                  <Icon icon='camera' />
                  <span>{text('screenshot')}</span>
                </Button>
              )}
            </div>

            <CloseButton />
          </Modal.Header>
        )}

        <Modal.Body
          className={classNames('pt-0 px-0', {
            'pb-4': !state.isProductModalExpanded,
          })}
        >
          <div
            className={classNames(
              'd-flex flex-row product align-items-center p-3',
              productType
            )}
          >
            {/* <Icon icon={productType} className='mr-1' /> */}
            <IconWrapper icon={productType} className='mr-1' />
            <div className='text-truncate' style={{ width: '100%' }}>
              {text(`${productType}3D`)}
            </div>
            <div className='d-flex flex-row'>
              <ProductBadge
                productType={productType}
                variant='secondary'
                withExplanation
                placement='top'
                asPopover={true}
                disableSetHoveredProductType={true}
              />
              {!SHOW_HEADER && <CloseButton />}
            </div>
          </div>

          <div className='px-3 pt-3'>
            <div
              className={classNames('d-flex flex-row', {
                'pb-1': isObject(priceWithSalesHelp),
              })}
            >
              <WithLoader hasCharges={!!charges}>
                <div className={classNames('price')}>{price.formatted}</div>
              </WithLoader>

              <div
                className={classNames(
                  'grey-1 font-weight-bold font-12 d-flex flex-row flex-fill align-items-center justify-content-end'
                )}
              >
                {text('productAreaTotal', {
                  delimiter: ':',
                  productTotalArea: (
                    <WithLoader
                      key={productType}
                      hasCharges={!!charges}
                      showLoaderText={false}
                      className={'ml-3'}
                      fullWidth={false}
                    >
                      <span
                        key={new Date().getTime()}
                        className={`${productType}-product-area mb-0 ml-1 px-1 font-14`}
                      >
                        {productTotalArea.formatted}
                      </span>
                    </WithLoader>
                  ),
                })}
              </div>
            </div>

            <hr className='mx-0 emphasis' />

            {isObject(priceWithSalesHelp) && priceWithSalesHelp.value > 0 && (
              <div className={classNames('discount mb-1')}>
                <span className='text-strikethrough'>
                  {text('salesHelp', {
                    amount: priceWithSalesHelp.total,
                  })}
                </span>
                {', '}
                {text('savingAmount', {
                  saving: priceWithSalesHelp.saved,
                })}
              </div>
            )}

            {/* SHOW MEMBERSHIP DISCOUNT IF NOT MEMBER */}
            <WithLoader hasCharges={!!charges}>
              {isFreeOrTrial && hasMemberDiscount && (
                <div
                  className={classNames('discount', {
                    'font-pink': !isEssentialUser,
                  })}
                >
                  {memberDiscount}{' '}
                </div>
              )}
            </WithLoader>

            {state.isProductModalExpanded && (
              <>
                {hasMemberDiscount && <hr className='mx-0' />}
                <DataTable className='product-selected-shapes mb-0'>
                  <tbody>
                    <tr>
                      <td className='pointCloudColumn' />
                      <td
                        colSpan={3}
                        className='areaColumn product-area text-left pl-3'
                      >
                        <span className='border-bottom'>
                          {`${text('productArea')}s`}
                        </span>
                      </td>
                    </tr>
                    {shapes?.map((selection, index) => {
                      const coverage = _.find(['selection_id', selection.id])(
                        state.coveragePerProduct[productType]
                      );
                      return (
                        <SimpleDataRow
                          key={selection.id}
                          data={selection}
                          coverage={coverage}
                          index={index}
                          isReadOnly={isReadOnly}
                          useMeatballDropdown={false}
                          showClearWhenShapeSelected={false}
                        />
                      );
                    })}
                  </tbody>
                </DataTable>

                {/* SHAPES UNION TOTAL */}
                {/* SHOW ONLY IF shapesUnionArea.value > 0 */}
                {shapesUnionArea.value > 0 && (
                  <>
                    <div className='grey-3 font-12 mt-3'>
                      {text('shapesUnion', {
                        shapesUnionArea: shapesUnionArea.formatted,
                      })}
                    </div>
                    <hr className='mx-0' />
                  </>
                )}

                <div
                  className={classNames('d-flex flex-column', {
                    'mt-3': shapesUnionArea.value <= 0,
                  })}
                >
                  <div className='grey-3 font-12'>{age}</div>
                  <div
                    className='grey-2'
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {text('turnAroundTime')}
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>

        {state.isProductModalExpanded && (
          <Modal.Footer>
            <Button
              style={{ width: '72px' }}
              variant='light'
              className='row-action with-text icon-button'
              onClick={toggleProductVisibility}
            >
              <Icon
                className={classNames({
                  original: !isAllSelectionsVisible,
                })}
                icon={eyeIcon}
              />
              <span>
                {isAllSelectionsVisible ? text('hide') : text('show')}
              </span>
            </Button>

            <Button
              variant='light'
              className='row-action with-text icon-button'
              onClick={handleDelete}
            >
              <Icon icon='trash' />
              <span>{text('delete')}</span>
            </Button>
          </Modal.Footer>
        )}
      </Modal.Dialog>

      {state.productShapeSelected && <ProductShapeMenuModal />}
    </>
  );
});
