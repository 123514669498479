/* eslint-disable react/prop-types */
import _ from 'lodash/fp';
import React, { useCallback, useContext, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { isEmpty, isNil } from 'lodash';
import {
  AREA_TYPE,
  AREA_TYPE_CONFIG,
  PRODUCT_TYPE,
} from '../../constants/product';
import { showModal } from '../../redux/actions';
import text, { formatSqmArea } from '../../text';
import DropdownItemLabel from '../dropdown/DropdownItemLabel';
import Submenu from '../dropdown/Submenu';
import { computePerimeter } from '../mapView/geometry';
import GeospatialDownloadsMenu from '../mapView/layersPanel/GeospatialDownloadsMenu';
import { MapViewContext } from '../mapView/mapViewContext';
import { DELETE_SHAPES_MODAL } from '../modal/DeleteShapesModal';
import ProductMenu from '../productMenu/ProductMenu';
import { selectionChildren } from '../../utilities/map';
import { getAddOnConfig } from '../mapView/drawingManager/utilities';

const SQM_IN_SQKM = 1e6;

// ShapeMenuContent should be inside of a react-bootstrap <Dropdown.Menu /> component
// Also must be within a <MapViewContext.Provider /> to access the context
export default function ShapeMenuContent({
  data,
  showShapeName,
  areaType = AREA_TYPE.PRODUCT_AREA,
  popupTrigger = '',
}) {
  const dispatch = useDispatch();
  const { actions, state } = useContext(MapViewContext);
  const { key: area_type_key, label: area_type_label } =
    AREA_TYPE_CONFIG[areaType];

  const coverage = useMemo(() => {
    return _.find(
      ['selection_id', data.id],
      state.coveragePerProduct[data.category_name]
    );
  }, [state.coveragePerProduct, data]);

  const shapeArea = useMemo(() => {
    if (!isEmpty(coverage) && !isNil(coverage[area_type_key])) {
      if (coverage[area_type_key] > SQM_IN_SQKM) {
        return `${(coverage[area_type_key] / SQM_IN_SQKM).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }
        )} km²`;
      }
      return formatSqmArea(coverage[area_type_key]);
    }
    return '';
  }, [coverage]);

  const handleToggleVisibility = useCallback(() => {
    actions.toggleSelectionVisibility(data.id);

    if (state.productShapeSelected) {
      actions.setProductShapeSelected(null);
    }

    // If from LHS(left-hand-side) Menu
    if (popupTrigger === 'LHS') {
      // empty product selected to remove right-menu product activeClass
      actions.setProductSelected({});
    }
  }, [data, state.productShapeSelected]);

  const handleDelete = useCallback(() => {
    if (state.productShapeSelected) {
      actions.setProductShapeSelected(null);
    }

    const childShapes = state.selections.filter(
      (selection) => selection.parent_selection_id === data.id
    );
    const selectionsToDelete = [data, ...childShapes];

    dispatch(
      showModal(DELETE_SHAPES_MODAL, {
        selectionsToDelete: [...selectionsToDelete, ...childShapes],
        associatedAddOns: childShapes,
      })
    );
  }, [data]);

  const draftData = _.find(
    ['category_name', 'unknown'],
    state.selectionProductAvailability?.[data.id]
  );
  const isDraft = data.category_name === PRODUCT_TYPE.UNKNOWN;

  const canRemoveFromCart = !isDraft && !!draftData;

  const removeFromCart = () => {
    const fn = () => {
      const isAddOn = !!getAddOnConfig(data.category_name);
      actions.changeSelectionProductType(
        data.id,
        draftData,
        {},
        isAddOn && !!data.parent_selection_id ? data.id : null
      );

      if (state.productShapeSelected || state.showProductModal) {
        actions.setProductShapeSelected(null);
        actions.clearProductSelected();
      }
    };

    const activeSelectionChildren = selectionChildren(
      state.selections,
      state.activeSelection?.id || data.id
    );

    if (activeSelectionChildren.length > 0) {
      actions.showChangeProductTypeConfirmation(
        data.category_name,
        activeSelectionChildren,
        () => {
          fn();
        },
        true
      );
    } else {
      fn();
    }
  };

  const selectionPerimeter = data?.region
    ? computePerimeter(data.region.include, data.region.exclude)
    : null;

  const handleDuplicateSelection = () => {
    actions.duplicateSelection(data, {
      makeDuplicatedSelectionActive: true,
    });

    if (state.productShapeSelected) {
      actions.setProductShapeSelected(null);
    }
  };

  return (
    <>
      <Dropdown.Header>
        {showShapeName && (
          <div className='shape-name'>
            <p className='font-weight-bold mb-0 font-16'>{data.name}</p>
          </div>
        )}
        <div className='area-section'>
          {`${area_type_label}: ${shapeArea}`}
          {selectionPerimeter && (
            <>
              <br />
              {`Perimeter: ${formatSqmArea(selectionPerimeter)}`}
            </>
          )}
        </div>
      </Dropdown.Header>
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleToggleVisibility}>
        <DropdownItemLabel
          label={!data.visible ? text('showShape') : text('hideShape')}
          icon={!data.visible ? 'eye' : 'eye-slash'}
        />
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          actions.zoomToShapeExtents(data);
        }}
      >
        <DropdownItemLabel label={text('zoomExtents')} icon={'zoom_extents'} />
      </Dropdown.Item>
      <Dropdown.Item onClick={handleDuplicateSelection}>
        <DropdownItemLabel icon='duplicate' label={text('duplicateShape')} />
      </Dropdown.Item>
      <Submenu label={text('download2dShape')} menuIcon='file-download'>
        <GeospatialDownloadsMenu selections={[data]} />
      </Submenu>
      <Dropdown.Divider />
      <Submenu
        label={text(isDraft ? 'addToCart' : 'changeTypeInCart')}
        menuIcon={isDraft ? 'add-on' : 'change-type'}
      >
        <ProductMenu data={data} />
      </Submenu>
      {canRemoveFromCart && (
        <Dropdown.Item onClick={removeFromCart}>
          <DropdownItemLabel icon='unknown' label={text('removeFromCart')} />
        </Dropdown.Item>
      )}
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleDelete}>
        <DropdownItemLabel
          label={text('deleteShape')}
          icon='trash'
          className={'danger-button'}
        />
      </Dropdown.Item>
    </>
  );
}
