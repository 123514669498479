import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import classnames from 'classnames';
import {
  calculateRemainderDays,
  calculateRemainderHours,
  calculateTrialLengthDays,
} from '../../utilities/membership';

const TrialMembershipBar = ({ membership, ...restProps }) => {
  let variant = 'success';
  const remainderHours = calculateRemainderHours(membership);
  let remainderDays = calculateRemainderDays(membership);

  const trialLengthDays = calculateTrialLengthDays(membership);
  if (remainderDays <= 4 && remainderHours > 24) {
    variant = 'warning';
  } else if (remainderHours <= 24) {
    variant = 'danger';
  }
  // Allow for hours to show on progress bar
  if (remainderDays === 0) {
    remainderDays = remainderHours / 24;
  }

  return (
    <ProgressBar
      className={classnames('inverted', { [variant]: variant })}
      now={((trialLengthDays - remainderDays) / trialLengthDays) * 100}
      variant={variant}
      {...restProps}
    />
  );
};

export default TrialMembershipBar;
