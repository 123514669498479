import fp from 'lodash/fp';
import T from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Dropdown } from 'react-bootstrap';
import { USER_TYPE } from '../../../constants/user';
import UserAvatar from '../../../pages/profile/UserAvatar';
import text from '../../../text';
import { getProjectLatestJob } from '../../../utilities/project';
import { getUserType, isStaff } from '../../../utilities/user';
import MeatballDropdown from '../../dropdown/MeatballDropdown';
import Table from '../Table';

const USER_TYPE_LABEL = {
  [USER_TYPE.FREE]: text('free'),
  [USER_TYPE.TRIAL]: text('trial'),
  [USER_TYPE.ESSENTIALS]: text('essentials'),
  [USER_TYPE.LARKI_SUPPORT]: text('larkiSupport'),
  [USER_TYPE.GOVERNMENT]: text('governmentFullWord'),
};

const InvitationTable = ({
  invitations,
  project,
  resendInvitation,
  removeProjectUser,
}) => {
  const job = getProjectLatestJob(project);
  const { userProfile } = useSelector((state) => state.profileReducer);
  const groupedInvitations = fp.flow(
    fp.map((invite) => ({ ...invite, user_type: getUserType(invite.user) })),
    fp.groupBy('user_type')
  )(invitations);
  const showInvite = (invite) =>
    isStaff(userProfile.role) || !isStaff(invite.user.role);
  const isCurrentUser = (invite) => invite.user.id === userProfile.id;
  return (
    <Table className='InvitationTable'>
      <thead>
        <tr>
          <th>{text('projectUsers')}</th>
          <th>{text('status')}</th>
          <th>{text('membership')}</th>
          <th className='ActionCell'>{text('actions')}</th>
        </tr>
      </thead>
      <tbody>
        {fp.flow(
          fp.mapValues((invites) => invites.filter(showInvite)),
          fp.pickBy((invites) => !fp.isEmpty(invites)), // `filter` for objects
          fp.map((invites) => (
            <>
              {invites.map((invite, i) => (
                <tr key={i}>
                  <td className='UserNameCell'>
                    <UserAvatar profileImage={invite.user.profile_image} />
                    {invite.user.full_name || invite.user.email}
                  </td>
                  <td className='StatusCell'>
                    {fp.capitalize(invite?.invite_status)}
                  </td>
                  <td className='MembershipCell'>
                    {USER_TYPE_LABEL[getUserType(invite.user)]}
                  </td>
                  <td className='ActionCell'>
                    <MeatballDropdown>
                      <Dropdown.Item
                        disabled={!(invite?.invite_status === 'pending')}
                        onClick={() => resendInvitation(invite?.user?.email)}
                      >
                        {text('resendInvitation')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={job?.owner_user_id === invite.user.id}
                        onClick={() => removeProjectUser(project, invite, i)}
                      >
                        {isCurrentUser(invite, userProfile)
                          ? text('leaveProject')
                          : text('removeProjectUser')}
                      </Dropdown.Item>
                    </MeatballDropdown>
                  </td>
                </tr>
              ))}
            </>
          ))
        )(groupedInvitations)}
      </tbody>
    </Table>
  );
};

InvitationTable.propTypes = {
  invitations: T.array.isRequired,
  project: T.object.isRequired,
  resendInvitation: T.func.isRequired,
  removeProjectUser: T.func.isRequired,
};

export default InvitationTable;
