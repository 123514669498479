import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions/modal';
import text from '../../text';
import { Modal } from 'react-bootstrap';

export const FORCE_JOB_UPDATE_MODAL = 'FORCE_JOB_UPDATE_MODAL';

const ForceJobUpdateModal = (props) => {
  const { onSubmit, show } = props;
  const dispatch = useDispatch();

  const onHide = () => dispatch(hideModal(FORCE_JOB_UPDATE_MODAL));

  const onHandleSubmit = () => {
    onHide();
    onSubmit();
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Body>
        <div>
          <h2 className='text-center'>{text('overwriteChanges')}</h2>
          <p className='text-center'>
            {text('overwriteRecentlyUpdatedProject')}
          </p>
          <div className='form-footer'>
            <Button onClick={onHandleSubmit}>{text('saveAnyway')}</Button>
            <a name='cancel' href='#' onClick={(ev) => {
              ev.preventDefault();
              onHide();
            }}>
              {text('cancel')}
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ForceJobUpdateModal;
