import React, { useEffect, useMemo } from 'react';
import { Navbar, NavDropdown, Nav, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { getUserProfile } from '../../../../../redux/actions';
import { getUser } from '../../../../../utilities/storage';
import text from '../../../../../text';
import UserAvatar from '../../../../profile/UserAvatar';
import routes from '../../../../../routes/constants';
import { getUserType, isUserAnonymous } from '../../../../../utilities/user';

/* eslint-disable react/prop-types */
const UserNav = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetailsState = useSelector(
    (state) => state.profileReducer.userProfile
  );
  const userBadge = useMemo(
    () => userDetailsState && getUserType(userDetailsState),
    [userDetailsState]
  );

  useEffect(() => {
    if (getUser() && userDetailsState.full_name === undefined) {
      dispatch(getUserProfile());
    }
  }, [userDetailsState]);

  return (
    !isEmpty(userDetailsState) &&
    !isUserAnonymous(userDetailsState) && (
      <Nav className={cn('UserNav', { [className]: className })}>
        <Navbar.Collapse>
          <NavDropdown
            alignRight
            title={
              userBadge && (
                <div className='avatar-wrapper'>
                  {/* <span className='mr-1'>
                    {userDetailsState.full_name || userDetailsState.email}
                  </span> */}
                  <UserAvatar profileImage={userDetailsState.profile_image} />
                  <Badge className={userBadge}>{text(userBadge)}</Badge>
                </div>
              )
            }
          >
            <NavDropdown.Item onClick={() => history.push(routes.dashboard)}>
              {text('dashboard')}
            </NavDropdown.Item>

            <NavDropdown.Item onClick={() => history.push(routes.user.profile)}>
              {text('account')}
            </NavDropdown.Item>

            <NavDropdown.Item
              href={routes.larkiWebsiteUrl.home}
              target='_blank'
            >
              {text('larkiURL')}
            </NavDropdown.Item>

            <NavDropdown.Item href={routes.logout}>
              {text('logout')}
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Nav>
    )
  );
};

export default UserNav;
