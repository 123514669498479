import T from 'prop-types';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAsyncFn, useList } from 'react-use';
import text from '../../../text';
import { client } from '../../../utilities/api';
import InviteUserForm from '../../form/inviteUserForm/InviteUserForm';
import InvitationTable from '../../table/invitationTable/InvitationTable';

export const INVITE_PROJECT_USERS_MODAL = 'INVITE_PROJECT_USERS_MODAL';

const InviteProjectUsersModal = (props) => {
  const { show, job, project, onHide, ...restProps } = props;
  const [projectInvitation, { set, insertAt, removeAt, clear }] = useList();

  const isValidInvitee = (inviteeEmail) =>
    !projectInvitation.some(({ user }) => user.email === inviteeEmail);

  const [inviteState, inviteUser] = useAsyncFn(
    async (project, email, isResend = false) => {
      const { data } = await client.inviteUserToProject(project.id, email);
      if (!isResend) {
        insertAt(0, { ...data });
      }
    }
  );
  const removeProjectUser = async (
    projectToRemove,
    invite,
    invitationIndex
  ) => {
    await client.removeUserFromProject(
      projectToRemove?.id,
      invite?.user?.email
    );
    removeAt(invitationIndex);
  };

  useEffect(async () => {
    if (project && show) {
      const { data } = await client.listProjectUsers(project.id);
      set(data);
    }
  }, [show]);

  const onHandleHide = () => {
    clear();
    onHide();
  };

  return (
    <Modal centered scrollable show={show} onHide={onHandleHide} {...restProps}>
      <Modal.Header className='align-end' closeButton>
        <div>
          <h3 className='secondary'>{text('inviteProjectUser')}</h3>
          {project?.name && <h6 className='mt-2'>{project.name}</h6>}
        </div>
      </Modal.Header>
      <InviteUserForm
        isLoading={inviteState.loading}
        isValidUser={isValidInvitee}
        onSubmit={(email) => inviteUser(project, email)}
      />
      <InvitationTable
        callback={set}
        resendInvitation={(email) => inviteUser(project, email, true)}
        removeProjectUser={removeProjectUser}
        project={project}
        job={job}
        invitations={projectInvitation}
      />
    </Modal>
  );
};

InviteProjectUsersModal.propTypes = {
  project: T.shape(),
  show: T.bool,
};

export default InviteProjectUsersModal;
