import cn from 'classnames';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobsList } from '../../redux/actions';
import text from '../../text';
import { isStaff } from '../../utilities/user';
import Loading from '../loading/Loading';
import { UNTITLED, statuses } from './consts';
import StatusFilterIcon from './filters/StatusFilterIcon';
import OrderListingItem, { COLUMN } from './orderListingItem';
import { capitalize } from 'lodash';

const isAscendingOrder = (order) => order && order === 'asc';

// TODO: rename to ProjectListing
export const OrderListing = (props) => {
  const {
    jobsList,
    currentUser,
    statusCounts,
    isAdminOrigin,
    isStaff,
    isLoading,
    status,
    searchText = '',
  } = props;

  const [sortingProperty, setSortingProperty] = useState('updated_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [orderedJobsList, setOrderedJobsList] = useState(jobsList);
  const filterStatus = status.status;

  useEffect(() => {
    if (jobsList) {
      const sortedList = orderBy(
        sortingProperty === 'status'
          ? [(job) => statuses[job.status].order]
          : [get(sortingProperty)],
        [sortOrder],
        jobsList
      );

      const filteredList = filter(({ status, project, full_address }) => {
        const filterByStatus = (status) => {
          return filterStatus.includes(status);
        };

        const filterBySearchText = (projectName, fullAddress) => {
          const searchByText = (property) => {
            return property.toLowerCase().includes(searchText.toLowerCase());
          };

          if (searchText === '') return true;
          if (projectName)
            return searchByText(projectName) || searchByText(fullAddress);
          if (projectName === null)
            return searchByText(UNTITLED) || searchByText(fullAddress);
          return false;
        };

        return (
          filterByStatus(status) &&
          filterBySearchText(project?.name, full_address)
        );
      })(sortedList);

      setOrderedJobsList(filteredList);
    }
  }, [sortingProperty, sortOrder, jobsList, status]);

  const handleSetSortingProperty = (property) => {
    setSortingProperty(property);
    setSortOrder(isAscendingOrder(sortOrder) ? 'desc' : 'asc');
  };

  if (orderedJobsList === undefined) {
    return null;
  }

  const renderSortableHeading = (header, propertyId, className) => {
    return (
      <th
        className={cn('column-header', {
          [className]: className,
          'position-relative': propertyId === 'status',
        })}
        onClick={() => handleSetSortingProperty(propertyId)}
      >
        <>
          {propertyId === 'status' ? (
            <>
              <StatusFilterIcon status={filterStatus} />
              <span className='mx-4'>{header}</span>
            </>
          ) : (
            header
          )}
        </>

        <a className='up-icon'>
          {sortingProperty === propertyId ? (
            <img
              src={
                !isAscendingOrder(sortOrder)
                  ? '/public/img/icon-up.svg'
                  : '/public/img/icon-down.svg'
              }
            />
          ) : (
            <div />
          )}
        </a>
      </th>
    );
  };

  const noOrdersAvailable = [...filterStatus].map((status) => {
    if (status === 'pending') return 'Quoted';
    if (status === 'inprogress') return 'Commenced';
    return capitalize(status);
  });

  return !isLoading ? (
    <div id='OrderListing'>
      {orderedJobsList.length === 0 && (
        <p className='text-center mb-0'>
          {text('noOrdersAvailable', {
            status: (
              <span className='font-weight-bold'>
                {noOrdersAvailable.join(', ')}.
              </span>
            ),
          })}
        </p>
      )}
      {orderedJobsList.length > 0 && (
        <div className='dashboard-table table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                {renderSortableHeading(
                  text('projectName'),
                  'project.name',
                  COLUMN.NAME
                )}
                {renderSortableHeading(
                  text('siteAddress'),
                  'full_address',
                  COLUMN.ADDRESS
                )}
                {renderSortableHeading(
                  text('createdDate'),
                  'created_at',
                  COLUMN.DATE
                )}
                {renderSortableHeading(
                  text('updatedDate'),
                  'updated_at',
                  COLUMN.DATE
                )}
                {renderSortableHeading(text('status'), 'status', COLUMN.STATUS)}
                <th className={COLUMN.ACTIONS}>{text('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {map(
                (job) => (
                  <OrderListingItem
                    key={job.id}
                    job={job}
                    currentUser={currentUser}
                    isAdminOrigin={isAdminOrigin}
                    isStaff={isStaff}
                  />
                ),
                orderedJobsList
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  ) : (
    <Loading />
  );
};

const OrderListingWithRedux = () => {
  const dispatch = useDispatch();
  const {
    jobsList,
    isJobsListLoading,
    statusCounts,
    currentUser,
    status,
    searchText,
  } = useSelector((state) => ({
    jobsList: state.jobsReducer.jobsList,
    statusCounts: state.jobsReducer.statusCounts,
    isJobsListLoading: state.jobsReducer.isJobsListLoading,
    currentUser: state.profileReducer.userProfile,
    status: state.jobsReducer.listJobFilter,
    searchText: state.jobsReducer.listJobFilter.searchText,
  }));
  useEffect(() => {
    dispatch(getJobsList());
  }, []);

  return (
    <OrderListing
      currentUser={currentUser}
      isLoading={isJobsListLoading}
      isStaff={isStaff(currentUser.role)}
      jobsList={jobsList}
      statusCounts={statusCounts}
      status={status}
      searchText={searchText}
    />
  );
};

export default OrderListingWithRedux;
