import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { hideModal } from '../../../redux/actions';
import StartTrialWizard from './StartTrialWizard/StartTrialWizard';

export const START_TRIAL_MODAL = 'START_TRIAL_MODAL';

const StartTrialModal = ({ ...rest }) => {
  const dispatch = useDispatch();

  const handleHide = () => dispatch(hideModal(START_TRIAL_MODAL));

  return (
    <Modal
      className='StartTrialModal'
      centered
      show
      onHide={handleHide}
      {...rest}
    >
      <StartTrialWizard onCancel={handleHide} />
    </Modal>
  );
};

export default StartTrialModal;
