import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import text from '../../../../text';
import Button from '../../../button/Button';
import List from '../../../list/List';
import { formatDate } from '../../../../utilities/date';
import { getUserMembership } from '../../../../utilities/user';

const StartTrialStatusStep = ({ onNext }) => {
  const { currentUser, upsertMembership } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
    upsertMembership: state.profileReducer.upsertMembership,
  }));

  return (
    <>
      <Modal.Header className='centered' closeButton>
        <h3>{text('freeTrialHasStarted')}</h3>
      </Modal.Header>
      <Modal.Body>
        <List isBorderless>
          <tbody>
            <List.Item
              isColonAppended
              description={text('startDateTrial')}
              value={formatDate(getUserMembership(currentUser)?.start_date)}
            />
            <List.Item
              isColonAppended
              description={text('endDateTrial')}
              value={formatDate(getUserMembership(currentUser)?.end_date)}
            />
          </tbody>
        </List>
      </Modal.Body>
      <Modal.Footer className='d-flex flex-column centered'>
        <Button
          className='full-width'
          loading={upsertMembership.isLoading}
          onClick={onNext}
        >
          {text('continue')}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default StartTrialStatusStep;
