import React, { useContext } from 'react';
import T from 'prop-types';
import classnames from 'classnames';

import text from '../../../../text';
import { IconButton } from '../../../button';
import { PRODUCT_EXPLANATION } from '../../../../constants/product';
import { Tooltip } from 'react-bootstrap';

/* eslint-disable react/prop-types */

const List = ({ items, type }) => {
  return (
    items?.length > 0 && (
      <ul className={type}>
        {items.map((item, i) => {
          return (
            <li className={``} key={i}>
              {item}
            </li>
          );
        })}
      </ul>
    )
  );
};

List.propTypes = {
  type: T.string.isRequired,
  items: T.array.isRequired,
};

const BadgeCardHeader = ({ children, isSecondary, ...props }) => {
  return !isSecondary ? (
    <button className='BadgeCardHeader' {...props}>
      {children}
    </button>
  ) : (
    <div className='BadgeCardHeader'>{children}</div>
  );
};

BadgeCardHeader.propTypes = {
  children: T.node.isRequired,
};

const BadgeCardBody = ({
  pros,
  cons,
  withExplanation = false,
  productType,
}) => {
  return (
    <div className='BadgeCardBody'>
      {withExplanation && PRODUCT_EXPLANATION[productType] && (
        <p className='explanation'>{PRODUCT_EXPLANATION[productType]}</p>
      )}
      <div className='pros-and-cons'>
        <List items={pros} type='pros' />
        <List items={cons} type='cons' />
      </div>
    </div>
  );
};

BadgeCardBody.propTypes = {
  productType: T.string.isRequired,
  cons: T.array,
  pros: T.array,
  withExplanation: T.bool,
};

const ProductBadgeCard = ({
  pros,
  cons,
  productType,
  isDisabled,
  handleClick,
  variant = 'default',
  withExplanation = false,
}) => {


  return (
    <div
      className={classnames('ProductBadgeCard', {
        secondary: variant === 'secondary',
        tertiary: variant === 'tertiary',
      })}
    >
      <BadgeCardHeader onClick={handleClick} disabled={isDisabled}>
        <h6>{text(`${productType}3D`)}</h6>
        {variant !== 'secondary' && (
          <IconButton disabled={isDisabled} icon='add' />
        )}
      </BadgeCardHeader>
      <BadgeCardBody
        pros={pros}
        cons={cons}
        productType={productType}
        withExplanation={withExplanation}
      />
    </div>
  );
};

ProductBadgeCard.propTypes = {
  productType: T.string.isRequired,
  pros: T.array,
  cons: T.array,
  isDisabled: T.bool.isRequired,
  handleClick: T.func.isRequired,
  variant: T.oneOf(['default', 'secondary', 'tertiary']),
  withExplanation: T.bool,
};

export default ProductBadgeCard;
