export const AMPLITUDE_EVENTS = {
  SITE_ADDRESS_ENTERED: {
    EVENT: 'Site Address entered',
    CHANGED_THE_SCOPE_AREA: 'Changed the scope area',
    CLICK_ON_A_TOOL: 'Click on a tool',
    CLICKED_ON_HIGH_RES: 'Clicked on High-res',
  },
  DID_SITE_TOUR: {
    EVENT: 'Did Site Tour',
    TOUR_2D_MAP: '2D Map Tour',
    TOUR_FILE_MANAGER: 'File Manager Tour',
    TOUR_3D_VIEWER: '3D Viewer Tour',
  },
  ACCEPTED_INTRO_OFFER: {
    EVENT: 'Accepted intro offer',
    TRIAL_USER: 'Trial User',
    CLOSE_TRIAL: 'Close trial',
  },
  ONBOARDED: {
    EVENT: 'Onboarded',
    GOOGLE_SIGNUP: 'Google Signup',
    EMAIL_SIGNUP: 'Email Signup',
  },
  ORDERED_DATA: {
    EVENT: 'Ordered Data',
    STREETSCAPE_AERIAL: 'Streetscape, Aerial',
    EXTERIOR_INTERIOR_DRONE: 'Exterior, Interior, Drone',
  },
  VIEWED_DEMO_PROJECT_IN_3D_VIEWER: {
    EVENT: 'Viewed Demonstration Project in 3D Viewer',
  },
  PAYMENT_FLOW: {
    EVENT: 'Payment Flow',
    TYPE_OF_DATA: 'Type of Data',
    SOFTWARE: 'Software',
    MEMBERSHIPS: 'Memberships',
    PAID_THEMSELVES: 'Paid themselves',
    SOMEONE_ELSE_PAYS: 'Someone else pays',
  },
  FILE_MANAGER: {
    EVENT: 'File Manager',
    SHARE_BUTTON: 'Share Button',
    NOTE_BUTTON: 'Note Button',
    UPLOAD_BUTTON: 'Upload Button',
    DOWNLOAD_INDIVIDUAL_FILES: 'Download Individual Files',
    DOWNLOAD_ALL_BUTTON: 'Download All Button',
  },
  SITE_TOUR_ACTIVITY: {
    EVENT: 'Site Tour Activity',
    TOUR_2D_MAP: {
      INC: 'Incomplete 2D Map Tour',
      COMPLETED: 'Completed 2D Map Tour',
    },
    TOUR_FILE_MANAGER: {
      INC: 'Incomplete File Manager Tour',
      COMPLETED: 'Completed File Manager Tour',
    },
    TOUR_3D_VIEWER: {
      INC: 'Incomplete 3D Viewer Tour',
      COMPLETED: 'Completed 3D Viewer Tour',
    },
  },
};
