import React from 'react';
import { map, isEmpty } from 'lodash';
import { flow as flowFP, groupBy as groupByFP } from 'lodash/fp';
import Activity from '../../components/timeline/Activity';
import {
  ASSET_ICON_MAP,
  STATUS_COLOR_MAP,
  STATUS_TEXT_MAP,
} from '../../constants/product';
import text from '../../text';
import { formatDate, formatTime } from '../../utilities/date';
import AssetIcon from '../../components/icon/common/AssetIcon';
import { DETAILED_STATUS } from '../../constants/job';

const JobStatusUpdate = (props) => {
  const { activityTitle, activityDescription, detailedStatuses, createdDate } =
    props;

  // We asume that a status activity with not detailed status is by default a 'draft'
  const groupedUpdates = !isEmpty(detailedStatuses)
    ? flowFP([
        (result) => map(result, (value, product) => ({ ...value, product })),
        groupByFP('status'),
      ])(detailedStatuses)
    : ['Draft'];

  return map(groupedUpdates, (products, prodStatus) => {
    return (
      <Activity key={prodStatus} className='JobStatusUpdate'>
        <>
          <Activity.Icon
            iconProps={{
              icon: 'circle',
              className: !isEmpty(detailedStatuses)
                ? STATUS_COLOR_MAP[prodStatus]
                : STATUS_COLOR_MAP.Draft,
            }}
          />
          <div className='DetailedStatus'>
            <Activity.Card.Header>
              <Activity.Card.Date
                date={formatDate(createdDate, true)}
                time={formatTime(createdDate, true)}
              />
              <Activity.Card.Type type={activityTitle} />
            </Activity.Card.Header>
            <div className='DetailedStatusContent'>
              {!isEmpty(detailedStatuses)
                ? map(products, ({ product, status, expectedAt }, i) => (
                    <div key={i} className='DetailedStatusContent-Item'>
                      <AssetIcon
                        product={ASSET_ICON_MAP[product] || ''}
                        defaultIcon='cube'
                      />
                      <span>
                        {ASSET_ICON_MAP[product]
                          ? text('productPointCloud3DOrder', {
                              productType: product,
                            })
                          : product}
                        <strong>{` ${STATUS_TEXT_MAP[status]}`}</strong>
                        <span>
                          {' '}
                          {expectedAt &&
                            status !== DETAILED_STATUS.COMPLETED &&
                            text('expectedAt', {
                              eta: formatDate(expectedAt),
                            })}
                        </span>
                      </span>
                    </div>
                  ))
                : activityDescription}
            </div>
          </div>
        </>
      </Activity>
    );
  });
};

export default JobStatusUpdate;
