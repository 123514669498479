import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import get from 'lodash/fp/get';
import identity from 'lodash/fp/identity';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import {
  hideModal,
  registerProjectTrialMembers,
  showModal,
} from '../../../redux/actions';
import text from '../../../text';
import Button from '../../button/Button';
import Control from '../../form/Control';
import routes from '../../../routes/constants';
import { PROJECT_TRIAL_MEMBERS_SUCCESS_MODAL } from './ProjectTrialMembersSuccess';
import Icon from '../../icon/Icon';

export const PROJECT_TRIAL_MEMBERS_MODAL = 'PROJECT_TRIAL_MEMBERS_MODAL';

export const usersSchema = yup.object({
  users: yup.array().test('is-users', identity, (value) => {
    const emailSchema = yup.string().email(text('enterValidEmail')).required();
    return value.every((v) => v.email)
      ? value.every((v) => emailSchema.isValidSync(v.email))
      : value.some((v) => emailSchema.isValidSync(v.email));
  }),
});

const FEATURES = [
  {
    icon: '2d-map',
    text: text('trialFeature2D'),
  },
  {
    icon: 'file',
    text: text('trialFeatureFileManager'),
  },
  {
    icon: '3D',
    text: text('trialFeature3D'),
  },
];

const DATA_FEATURES = [
  {
    icon: 'streetscape',
    text: text('streetscapePointCloudDescription'),
    iconClassName: 'streetscape enabled',
  },
  {
    icon: 'aerial',
    text: text('aerialPointCloudDescription'),
    iconClassName: 'aerial enabled',
  },
];

const ProjectTrialMembersModal = ({ show, projectId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleHide = () => dispatch(hideModal(PROJECT_TRIAL_MEMBERS_MODAL));
  const handleClick = async (values) => {
    const users = values.users.filter((u) => u.email);
    await dispatch(registerProjectTrialMembers(projectId, users));
    history.push(routes.dashboard);
    dispatch(showModal(PROJECT_TRIAL_MEMBERS_SUCCESS_MODAL, { projectId }));
  };

  return (
    <Modal
      centered
      className='ProjectTrialMemberModal'
      contentClassName='w-auto px-4'
      show={show}
      onHide={handleHide}
    >
      <Modal.Body className='centered'>
        <h3 className='mb-1'>{text('projectTrial3Users3Months')}</h3>
        <p>{text('projectTrialCongratulationsAccess')}</p>

        <h5 className='mb-1'>{text('measureCollaboratePlatform')}</h5>

        <div className='mb-3 center-content'>
          <ul className='p-0 d-flex flex-column align-items-start'>
            {FEATURES.map(({ text, iconClassName, icon }, i) => (
              <li key={i} className='my-1 center-content'>
                <Icon
                  className={classNames('mr-3 framed outline-primary', {
                    [iconClassName]: iconClassName,
                  })}
                  icon={icon}
                />
                <h6>{text}</h6>
              </li>
            ))}
          </ul>
        </div>

        <h5 className='m-2'>{text('getProjectTrialFreeData')}</h5>
        <div className='mb-3 center-content'>
          <ul className='p-0 d-flex flex-column align-items-start'>
            {DATA_FEATURES.map(({ text, iconClassName, icon }, i) => (
              <li key={i} className='my-1 center-content'>
                <Icon
                  className={classNames('mr-3 framed outline-primary', {
                    [iconClassName]: iconClassName,
                  })}
                  icon={icon}
                />
                <h6>{text}</h6>
              </li>
            ))}
          </ul>
        </div>

        <Formik
          initialValues={{
            users: [{ email: undefined }, { email: undefined }],
          }}
          onSubmit={handleClick}
          validationSchema={usersSchema}
          isInitialValid={false}
        >
          {({
            values,
            touched,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
          }) => {
            return (
              <>
                <Modal.Body className='p-0'>
                  <h5>{text('shareWithTheseUsers')}</h5>
                  <>
                    {values.users.map((user, i) => (
                      <div key={i} className='center-content w-100'>
                        {text('projectUserNumber', {
                          email: '',
                          number: i + 1,
                        })}
                        <Control
                          className='ml-2'
                          required
                          as='input'
                          type='text'
                          name={`users.${i}.email`}
                          value={user.email}
                          onBlur={() =>
                            setFieldTouched(`users.${i}.email`, true)
                          }
                          onChange={(event) =>
                            setFieldValue(
                              `users.${i}.email`,
                              event.target.value
                            )
                          }
                          isFailed={
                            get(`users.${i}.email`, touched) &&
                            get(`users.${i}.email`, errors)
                          }
                          validationError={get(`users.${i}.email`, errors)}
                        />
                      </div>
                    ))}
                  </>
                </Modal.Body>
                <Modal.Footer className='center-content'>
                  <Button variant='link' onClick={handleHide}>
                    {text('maybeLater')}
                  </Button>
                  <Button
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {text('sharePlatform')}
                  </Button>
                </Modal.Footer>
              </>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectTrialMembersModal;
