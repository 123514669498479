import { useStripe } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import FormFooter from '../../../components/form/FormikFormFooter';
import { MANUAL_ORDER_MODAL } from '../../../components/modal/ManualOrderModal/ManualOrderModal';
import { PAYMENT_FAILURE_MODAL } from '../../../components/modal/PaymentFailureModal';
import StripeElements from '../../../components/stripe/StripeElements';
import Step from '../../../components/wizard/Step';
import { LEAD_CONFIRMATION_PAGE } from '../../../constants/job';
import {
  commitJob,
  commitOrder,
  refreshCustomer,
  sendLead,
  subscribe,
} from '../../../redux/actions';
import { showModal } from '../../../redux/actions/modal';
import routes from '../../../routes/constants';
import text, { formatCurrency } from '../../../text';
import { client } from '../../../utilities/api';
import { isJobPayable } from '../../../utilities/job';
import { isMembershipPriceId } from '../../../utilities/membership';
import { isStaff, userHasActiveMembership } from '../../../utilities/user';
import ConfirmationForm from '../ConfirmationForm/Form';

const validationSchema = yup.object({
  terms: yup.bool().oneOf([true]),
});

const ConfirmationStep = () => {
  const { jobId } = useParams();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const history = useHistory();
  const returnPage = routes.payment.job(jobId);
  const returnUrl = `${process.env.LARKI_APP_URL}${returnPage}`;

  const {
    job,
    paymentMethodId,
    membershipPriceId,
    membershipPrices,
    currentUser,
  } = useSelector((state) => ({
    membershipPriceId: state.order.membershipPriceId,
    paymentMethodId: state.order.paymentMethodId,
    currentUser: state.profileReducer.userProfile,
    job: state.jobsReducer.job,
    membershipPrices: state.profileReducer.membershipPrices,
  }));
  const selectedMembership = useMemo(() => {
    if (membershipPrices.value) {
      return membershipPrices.value.find(
        (membership) => membership.id === membershipPriceId
      );
    }
  }, [membershipPriceId, membershipPrices]);

  const totalPrice = useMemo(() => {
    let membershipTotal = 0;
    if (
      membershipPriceId &&
      selectedMembership &&
      job &&
      !userHasActiveMembership(currentUser)
    ) {
      membershipTotal = selectedMembership.grand_total;
    }
    const jobTotal = isMembershipPriceId(membershipPriceId)
      ? job.quote.price.member_grand_total
      : job.quote.price.grand_total;
    const dataTotalToPay = isJobPayable(job) ? jobTotal : 0;
    const totalToPay = membershipTotal + dataTotalToPay;
    return totalToPay
      ? formatCurrency(
          totalToPay,
          job.quote.currency.name,
          job.quote.currency.scale_factor,
          {},
          true
        )
      : text('toBeConfirmed');
  }, [membershipPrices]);

  const commit = async (jobToCommit, redirectPath) => {
    const order = await dispatch(commitOrder(jobToCommit.id));
    if (order) {
      await dispatch(commitJob(jobToCommit.id));
    }

    if (redirectPath) {
      history.push(redirectPath);
    } else {
      dispatch(showModal(MANUAL_ORDER_MODAL));
    }
  };

  const onPayOrder = async (priceId, paymentMethod, jobToCommit) => {
    let membershipResult;
    let membershipIntentSecret;

    if (isMembershipPriceId(priceId) && !userHasActiveMembership(currentUser)) {
      const upsertResponse = await dispatch(
        subscribe({
          user: currentUser,
          priceId,
          paymentMethodId,
          isTrial: false,
        })
      );

      if (upsertResponse.paymentIntent?.status === 'requires_confirmation') {
        membershipResult = await stripe.confirmCardPayment(
          upsertResponse.payment_intent_secret,
          {
            payment_method: paymentMethod,
            return_url: returnUrl,
          },
          { handleActions: false }
        );

        if (membershipResult?.paymentIntent?.status === 'succeeded') {
          membershipIntentSecret =
            membershipResult?.paymentIntent?.client_secret;
        }

        if (membershipResult?.error) {
          dispatch(
            showModal(PAYMENT_FAILURE_MODAL, {
              message: text('membershipPaymentFailed'),
            })
          );
        }
      }
    }

    if (!isMembershipPriceId(priceId)) {
      await dispatch(refreshCustomer());
    }

    if (job.quote.delivery_method === 'automatic') {
      let paymentStatusPath;
      const { data: checkoutPaymentIntent } = await client.checkout(jobId);

      const orderResult = await stripe.confirmCardPayment(
        checkoutPaymentIntent,
        {
          payment_method: paymentMethod,
          return_url: returnUrl,
        },
        { handleActions: false }
      );

      if (orderResult?.paymentIntent?.status === 'succeeded') {
        paymentStatusPath = `${routes.payment.status(
          jobId
        )}?orderClientSecret=${orderResult.paymentIntent.client_secret}`;

        if (membershipIntentSecret) {
          paymentStatusPath = paymentStatusPath.concat(
            `&membershipClientSecret=${membershipIntentSecret}`
          );
        }

        await commit(jobToCommit, paymentStatusPath);
      }

      if (orderResult.error) {
        dispatch(
          showModal(PAYMENT_FAILURE_MODAL, {
            message:
              membershipResult?.paymentIntent.status === 'succeeded'
                ? text('membershipSuccessButOrderFailed')
                : orderResult.error.message ?? text('paymentFailed'),
          })
        );
      }
    } else {
      await commit(jobToCommit);
    }
  };

  useEffect(() => {
    dispatch(sendLead(jobId, LEAD_CONFIRMATION_PAGE));
  }, []);

  return (
    <Formik
      initialValues={{
        terms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={() =>
        !isStaff(currentUser.role)
          ? onPayOrder(membershipPriceId, paymentMethodId, job)
          : commit(job)
      }
      validateOnMount
    >
      {({ isValid }) => (
        <>
          <Step>
            <ConfirmationForm />
          </Step>
          <FormFooter
            topContent={
              <div className='d-flex justify-content-between align-items-center mb-2'>
                <p className='text-uppercase pr-3 mb-0'>
                  {text('totalPayNow')}
                </p>
                <h5 className='green-1'>{totalPrice}</h5>
              </div>
            }
            label={text(
              isStaff(currentUser.role)
                ? 'commitOrder'
                : isJobPayable(job) || isMembershipPriceId(membershipPriceId)
                ? 'payNow'
                : 'order'
            )}
            disabled={!isValid}
          />
        </>
      )}
    </Formik>
  );
};

const ConfirmationStepWithStripe = (props) => {
  return (
    <StripeElements>
      <ConfirmationStep {...props} />
    </StripeElements>
  );
};

export default ConfirmationStepWithStripe;
