import React, { useMemo } from 'react';
import _ from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../../icon/Icon';
import { nextHighResLayer, prevHighResLayer } from '../../../redux/actions';
import classNames from 'classnames';

export const HIGH_RES_DATE_ID = 'high-res-date-wrapper';

const HighResDate = ({ isVisible }) => {
  const dispatch = useDispatch();
  const {
    highRes: { currentDate, layers },
  } = useSelector((state) => state.mapReducer);

  const dates = !layers.isLoading && layers.data ? _.keys(layers.data) : null;
  const isCurrentDateLatest = dates ? dates.indexOf(currentDate) === 0 : false;
  const isCurrentDateEarliest = dates
    ? dates.indexOf(currentDate) === dates.length - 1
    : false;
  const isNextDisabled =
    layers.isLoading || !currentDate || isCurrentDateLatest;
  const isPrevDisabled =
    layers.isLoading || !currentDate || isCurrentDateEarliest;

  const formattedDate = useMemo(() => {
    if (currentDate) {
      const date = new Date(currentDate);
      return date.toLocaleDateString('en-AU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }
    return null;
  }, [currentDate]);

  return (
    <div
      id={HIGH_RES_DATE_ID}
      className={classNames('high-res-date', isVisible && 'visible')}
    >
      <button
        className='action left'
        disabled={isPrevDisabled}
        onClick={() => dispatch(nextHighResLayer())}
      >
        <Icon icon='chevron-left' />
      </button>
      <div className='date'>{formattedDate ?? 'MM/DD/YYYY'}</div>
      <button
        className='action right'
        disabled={isNextDisabled}
        onClick={() => dispatch(prevHighResLayer())}
      >
        <Icon icon='chevron-right' />
      </button>
    </div>
  );
};

export default HighResDate;
