import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';
import pull from 'lodash/fp/pull';
import isUndefined from 'lodash/fp/isUndefined';
import identity from 'lodash/fp/identity';
import {
  DELETE_ACTIVITY,
  LIST_ACTIVITIES,
  LIST_ACTIVITIES_SUCCESS,
  TOGGLE_STAFF_ACTIVITIES,
  ENABLE_ACTIVITY_FILTER,
  DISABLE_ACTIVITY_FILTER,
  FILTER_ACTIVITIES,
} from '../constants/activity';
import { DELETE_ASSET } from '../constants/asset';

const defaultMapState = {
  projects: {},
  enabledFilters: [],
  isStaffActivityListVisible: false,
};

const activityReducer = (state = defaultMapState, action) => {
  switch (action.type) {
    case LIST_ACTIVITIES: {
      return flow([
        set(['projects', action.payload.projectId, 'ui', 'isLoading'], true),
      ])(state);
    }
    case TOGGLE_STAFF_ACTIVITIES: {
      return flow([
        set('isStaffActivityListVisible', !state.isStaffActivityListVisible),
      ])(state);
    }
    case LIST_ACTIVITIES_SUCCESS: {
      const { projectId, activities } = action.payload;
      return flow([
        set(['projects', projectId, 'ui', 'isLoading'], false),
        set(['projects', projectId, 'activities'], activities),
        set(['projects', projectId, 'nonFilteredActivities'], activities),
      ])(state);
    }
    case FILTER_ACTIVITIES: {
      const { projectId, activities } = action.payload;
      return flow([
        set(['projects', projectId, 'activities'], activities),
        isUndefined(state.projects[projectId]?.nonFilteredActivities)
          ? set(['projects', projectId, 'nonFilteredActivities'], activities)
          : identity,
      ])(state);
    }
    case ENABLE_ACTIVITY_FILTER: {
      const { filterName } = action.payload;
      return flow([
        set(['enabledFilters'], state.enabledFilters.concat(filterName)),
      ])(state);
    }
    case DISABLE_ACTIVITY_FILTER: {
      const { filterName } = action.payload;
      return flow([
        set(['enabledFilters'], pull(filterName, state.enabledFilters)),
      ])(state);
    }
    case DELETE_ACTIVITY: {
      const { projectId, activityId } = action.payload;
      const activities = state.projects[projectId].activities.filter(
        (activity) => activity.id !== activityId
      );

      return flow([set(['projects', projectId, 'activities'], activities)])(
        state
      );
    }
    case DELETE_ASSET: {
      const { jobId, activityId, assetId } = action.payload;
      const index = state.projects[jobId].activities.findIndex(
        (activity) => activity.id === activityId
      );
      const assets = state.projects[jobId].activities[
        index
      ].created_assets.filter((asset) => asset.id !== assetId);

      return flow([
        set(['projects', jobId, 'activities', index, 'created_assets'], assets),
      ])(state);
    }

    default:
      return state;
  }
};

export default activityReducer;
