import React from 'react';

import Page from '../../components/page/Page';
import text from '../../text';
import AdminOrderListing from '../../components/orderListing/adminOrderListing';
import AdminOrderListingControl from './adminOrderListingControl';

const AdminProject = () => {
  return (
    <Page title={text('adminProjects')}>
      <Page.Top>
        <h2>{text('adminProjects')}</h2>
        <AdminOrderListingControl />
      </Page.Top>
      <Page.Body>
        <AdminOrderListing />
      </Page.Body>
    </Page>
  );
};

export default AdminProject;
