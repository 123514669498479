export const jobsConstants = {
  GET_JOBS_LIST: 'GET_JOBS_LIST',
  SET_JOBS_LIST: 'SET_JOBS_LIST',

  GET_JOB: 'GET_JOB',
  GET_JOB_SUCCESS: 'GET_JOB_SUCCESS',
  GET_JOB_FAILED: 'GET_JOB_FAILED',

  SET_JOB: 'SET_JOB',
  CLEAR_JOB: 'CLEAR_JOB',
  SET_PROJECT: 'SET_PROJECT',
  SET_JOB_STATUS_COUNTS: 'SET_JOB_STATUS_COUNTS',

  SET_POINT_CLOUD: 'SET_POINT_CLOUD',

  OPEN_JOB_CREATED_DIALOG: 'OPEN_JOB_CREATED_DIALOG',
  CLOSE_JOB_CREATED_DIALOG: 'CLOSE_JOB_CREATED_DIALOG',

  UPDATE_JOB_SUCCESS: 'UPDATE_JOB_SUCCESS',
  UPDATE_JOB_FAILED: 'UPDATE_JOB_FAILED',

  UPDATE_JOB_PROJECT_NAME: 'UPDATE_JOB_PROJECT_NAME',
  UPDATE_JOB_PROJECT_NAME_SUCCESS: 'UPDATE_JOB_PROJECT_NAME_SUCCESS',
  UPDATE_JOB_PROJECT_NAME_FAILED: 'UPDATE_JOB_PROJECT_NAME_FAILED',

  RESET_UPDATE_JOB_PROJECT_NAME: 'RESET_UPDATE_JOB_PROJECT_NAME',

  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',

  UPDATE_INVITE: 'UPDATE_INVITE',

  POINTERRA_ID_UPDATED: 'POINTERRA_ID_UPDATED',
  SET_LIST_JOB_STATUS: 'SET_LIST_JOB_STATUS',
  SET_LIST_JOB_SEARCHTEXT: 'SET_LIST_JOB_SEARCHTEXT',
  GET_ADMIN_JOB_LIST: 'GET_ADMIN_JOB_LIST',
  SET_ADMIN_JOB_LIST: 'SET_ADMIN_JOB_LIST',
  SET_ADMIN_STATUS_COUNT: 'SET_ADMIN_STATUS_COUNT',

  GET_JOB_RECEIPT: 'GET_JOB_RECEIPT',
  GET_JOB_RECEIPT_SUCCESS: 'GET_JOB_RECEIPT_SUCCESS',
  GET_JOB_RECEIPT_FAILED: 'GET_JOB_RECEIPT_FAILED',

  ADD_COPIED_JOB: 'ADD_COPIED_JOB',

  UPDATE_SHAPE_NAME: 'UPDATE_SHAPE_NAME',

  QUOTE_ORDER: 'QUOTE_ORDER',
  QUOTE_ORDER_SUCCESS: 'QUOTE_ORDER_SUCCESS',

  TOGGLE_JOB_BOUNDARY_VISIBILITY: 'TOGGLE_JOB_BOUNDARY_VISIBILITY',
  TOGGLE_JOB_BOUNDARY_HIDDEN: 'TOGGLE_JOB_BOUNDARY_HIDDEN',
  SET_UNDEFINED_ADDRESS: 'SET_UNDEFINED_ADDRESS',
};
