import React from 'react';

import classNames from 'classnames';
import { has, isFunction } from 'lodash';
import T from 'prop-types';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../../../components/icon/Icon';
import { AMPLITUDE_EVENTS } from '../../../../../constants/analytics';
import { toggleRightMenuVisibility } from '../../../../../redux/actions';
import {
  clearTourSelected,
  putTourStatus,
} from '../../../../../redux/actions/tour';
import text from '../../../../../text';
import { amplitudeTrack } from '../../../../../utilities';

const { SITE_TOUR_ACTIVITY } = AMPLITUDE_EVENTS;
export default function TourContent(props) {
  const totalSteps = props.steps.length;
  const currentStep = props.currentStep;
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === totalSteps - 1;
  const content = props.steps[currentStep].content;
  const customProps = props.steps[currentStep]?.customProps || {};
  const dispatch = useDispatch();
  const tourSelected = useSelector((state) => state.tourReducer.tourSelected);

  const handleNextButton = () => {
    const executeStep = () => {
      if (isLastStep) {
        props.setIsOpen(false);
      } else {
        props.setCurrentStep((s) => s + 1);
      }
    };

    if (
      has(customProps.callback, 'next') &&
      isFunction(customProps.callback.next)
    ) {
      customProps.callback.next();
    }

    if (has(customProps, 'stepDelay') && customProps.stepDelay > 0) {
      setTimeout(() => executeStep(), customProps.stepDelay + 10);
    } else {
      executeStep();
    }
  };

  const handlePrevButton = () => {
    if (
      has(customProps.callback, 'next') &&
      isFunction(customProps.callback.prev)
    ) {
      customProps.callback.prev();
    }

    if (has(customProps, 'stepDelay') && customProps.stepDelay > 0) {
      setTimeout(
        () => props.setCurrentStep((s) => s - 1),
        customProps.stepDelay + 10
      );
    } else {
      props.setCurrentStep((s) => s - 1);
    }
  };

  return (
    <div
      style={{ position: 'relative' }}
      className={classNames('TourContent p-3 px-4 with-caret', {
        [`caret-${customProps?.caret}`]: !!customProps && customProps.caret,
        [`caretPosY-${customProps?.caretPosY}`]:
          !!customProps && customProps.caretPosY,
      })}
    >
      <Icon
        icon='close'
        onClick={() => {
          amplitudeTrack(SITE_TOUR_ACTIVITY.EVENT, {
            action: SITE_TOUR_ACTIVITY[tourSelected]['INC'],
          });
          dispatch(putTourStatus(tourSelected));
          dispatch(toggleRightMenuVisibility(false));
          dispatch(clearTourSelected());
          props.setIsOpen(false);
        }}
      />
      {content}
      <div className='d-flex flex-row justify-content-end' style={{ gap: 8 }}>
        {!isFirstStep && (
          <Button
            variant='light'
            className='mt-4 grey-2'
            onClick={handlePrevButton}
          >
            {text('previous')}
          </Button>
        )}

        <Button className='mt-4' onClick={handleNextButton}>
          {`${text(isLastStep ? 'complete' : 'next')} (${
            currentStep + 1
          }/${totalSteps})`}
        </Button>
      </div>
    </div>
  );
}

TourContent.propTypes = {
  steps: T.array,
  currentStep: T.number,
  setIsOpen: T.func,
};

export const StepContent = ({
  title,
  description,
  externalLinkHelp,
  children,
}) => (
  <>
    <h5 className='mb-3 green-1 mt-2'>{title}</h5>
    {description && <p className='mb-0'>{description}</p>}
    {children}
    {externalLinkHelp && (
      <span>
        (
        <a href={externalLinkHelp} target='_blank' rel='noopener noreferrer'>
          {text('learnMore')}
        </a>
        )
      </span>
    )}
  </>
);

StepContent.propTypes = {
  title: T.string.isRequired,
  description: T.string,
  externalLinkHelp: T.string,
  children: T.oneOfType([T.element, T.string]),
};
