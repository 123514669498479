import React from 'react';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import Fit from 'react-fit';

const StatusControl = ({
  children,
  onClick,
  status,
  includeUnderline,
  currentStatus,
}) => (
  <button
    className={classnames('StatusControl', {
      'is-focused': isEqual(status, currentStatus),
    })}
    onClick={() => onClick(status)}
  >
    <strong>{children}</strong>
    {includeUnderline && (
      <Fit>
        <div
          className={classnames('StatusControlUnderline', {
            'is-focused': isEqual(status, currentStatus),
          })}
        />
      </Fit>
    )}
  </button>
);

export default StatusControl;
