import { client } from '../../utilities/api';
import { alertConstants } from '../constants';
import { DELETE_ASSET } from '../constants/asset';

export const deleteAsset = (jobId, activityId, assetId) => async (dispatch) => {
  try {
    await client.deleteAsset(jobId, assetId);

    dispatch({
      type: DELETE_ASSET,
      payload: {
        jobId,
        activityId,
        assetId,
      },
    });
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};
