import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { canUserUpgrade } from '../utilities/user';
import { showModal } from '../redux/actions';
import routes from '../routes/constants';
import { START_TRIAL_MODAL } from '../components/modal/StartTrialModal/StartTrialModal';

const useMembershipButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((reduxState) => ({
    currentUser: reduxState.profileReducer.userProfile,
  }));

  const handleMembershipClick = useCallback(
    (state) =>
      canUserUpgrade(currentUser)
        ? history.push({
            pathname: `${routes.user.membershipManagement}`,
            ...(state && {
              state: { ...state },
            }),
          })
        : dispatch(showModal(START_TRIAL_MODAL)),
    [currentUser]
  );

  return { handleMembershipClick };
};

export default useMembershipButton;
