import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSearchParam } from 'react-use';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import WhiteContainer from '../../../components/containers/WhiteContainer';
import Icon from '../../../components/icon/Icon';
import text from '../../../text';
import TextDivider from '../../../components/text/TextDivider';
import routes from '../../../routes/constants';
import Page from '../../../components/page/Page';
import { ReceiptItem } from '../PaymentSuccess';
import List from '../../../components/list/List';
import { getMembershipDetails, getUserProfile } from '../../../redux/actions';
import Loading from '../../../components/loading/Loading';
import { useLocation } from 'react-router-dom';

const MembershipStatusSuccess = () => {
  const dispatch = useDispatch();
  const isTrial = useSearchParam('isTrial');
  const { membershipDetails } = useSelector((state) => ({
    membershipDetails: state.profileReducer.membershipDetails,
  }));

  const location = useLocation();
  const redirectRoute = location.state?.redirectTo ?? {
    path: routes.user.profile,
    label: text('backToAccount'),
  };

  useEffect(() => {
    if (isTrial !== 'true') {
      dispatch(getMembershipDetails());
    }
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <Page className='MembershipStatusSuccess PageCenter'>
      <WhiteContainer isContentCentered>
        <Icon className='mb-3' icon='circled-tick_filled' />
        <h3 className='mb-3'>{text('thankYou')}</h3>
        {isTrial === 'true' && (
          <p className='ml-5 mr-5 mb-0'>{text('yourTrialHasBegun')}</p>
        )}
        {get(membershipDetails, 'value.latest_invoice') && (
          <p className='ml-5 mr-5 mb-0'>{text('yourOrderSuccessfullyPaid')}</p>
        )}
        {!isTrial === 'true' && <TextDivider isSecondary isComplete />}
        {!membershipDetails.isLoading && !isEmpty(membershipDetails.value) ? (
          <List className='mt-4' isPaddingless isBorderless>
            <ReceiptItem
              receipt={{
                value: {
                  receipt_url: get(
                    membershipDetails,
                    'value.latest_invoice.hosted_invoice_url'
                  ),
                  amount: get(
                    membershipDetails,
                    'value.latest_invoice.amount_paid'
                  ),
                  currency: get(membershipDetails, 'value.currency'),
                },
              }}
              description={text('membership')}
            />
          </List>
        ) : isTrial === 'true' ? undefined : (
          <Loading />
        )}
        <TextDivider isSecondary isComplete />
        <p className='m-0'>{text('receiptEmailSent')}</p>
        <TextDivider isSecondary isComplete />
        <a name='cancel' href={redirectRoute.path}>
          {redirectRoute.label}
        </a>
      </WhiteContainer>
    </Page>
  );
};

export default MembershipStatusSuccess;
