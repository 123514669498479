import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import isNull from 'lodash/fp/isNull';
import orderBy from 'lodash/fp/orderBy';

import text from '../text';

export const isDemoProject = (project) =>
  getProjectLatestJob(project)?.status === 'demo';

export const getProjectName = (project) =>
  !project?.name || isNull(project?.name)
    ? text('untitled')
    : get('name', project);

export const getProjectLatestJob = (project) =>
  project &&
  !isEmpty(project.jobs) &&
  flow([orderBy('updated_at', ['desc']), get('0')])(project.jobs);

export const isProject3DViewable = (project) =>
  get('user_project.invite_status', project) === 'accepted' &&
  !isEmpty(project?.point_clouds);

export const isProjectInviteAccepted = (project) =>
  get('user_project.invite_status', project) === 'accepted';

export const isInvitationProjectTrialMember = (invitation) =>
  invitation.project_role && invitation.project_role === 'project-trial';
