import React from 'react';
import T from 'prop-types';
import classnames from 'classnames';

import Img from '../img/Img';

const ActivityType = (props) => {
  const { type } = props;
  return <div className='ActivityType'>{type}</div>;
};

ActivityType.propTypes = {
  type: T.string,
};

const ActivityDate = (props) => {
  const { date, time, isLastItem } = props;
  return (
    <div className={classnames('ActivityDate', { isLastItem })}>
      <span className='Date'>{date}</span>
      <span className='Time'>{time}</span>
    </div>
  );
};

ActivityDate.propTypes = {
  date: T.string,
  time: T.string,
};

const ActivityCreator = (props) => {
  const { name, image } = props;
  return (
    <div className='ActivityCreator'>
      <Img img={image} />
      {name}
    </div>
  );
};

ActivityCreator.propTypes = {
  name: T.string,
  image: T.string,
};

export const ActivityHeader = ({ children }) => {
  return <div className='ActivityHeader'>{children}</div>;
};

export const ActivityHeaderElement = ({ children }) => {
  return <div className='ActivityHeaderElement'>{children}</div>;
};

ActivityHeader.propTypes = {
  children: T.node,
};

export const HeaderRight = ({ children }) => {
  return <div className='HeaderRight'>{children}</div>;
};
HeaderRight.propTypes = {
  children: T.node,
};

const ActivityCard = (props) => {
  const { children } = props;
  return (
    <div className='ActivityCard'>
      <div className='Arrow' />
      {children}
    </div>
  );
};
ActivityCard.propTypes = {
  children: T.node,
};

ActivityCard.Header = ActivityHeader;
ActivityCard.HeaderRight = HeaderRight;
ActivityCard.HeaderElement = ActivityHeaderElement;
ActivityCard.Date = ActivityDate;
ActivityCard.Type = ActivityType;
ActivityCard.Creator = ActivityCreator;

export default ActivityCard;
