import { default as classNames, default as classnames } from 'classnames';
import { first } from 'lodash';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import {
  useBoolean,
  useMount,
  usePrevious,
  useSearchParam,
  useUnmount,
} from 'react-use';

// Constants
import { STATUS } from '../../constants/job';
import routes from '../../routes/constants';
import {
  AUTO_MAP_ADDRESS,
  INITIAL_MAP_LOCATION,
  INITIAL_MAP_ZOOM,
  INITIAL_RECTANGLE_SIZE,
  MAP_LOAD_DELAY,
  MAP_TYPE,
  MAX_MAP_ZOOM,
  MIN_MAP_ZOOM,
} from './constants';

// REDUX ACTIONS
import {
  clearJob,
  disableSmartSelectionsApi,
  getProducts,
  loadHighResCollections,
  loadHighResLayers,
  setJob,
  setUndefinedAddress,
  setZoomToggle,
  showModal,
  toggleJobBoundaryVisibility,
  toggleRightMenuVisibility,
  updateLotAreaState,
  updateMapType,
} from '../../redux/actions';

// UTILITIES
import text, { formatSqmArea } from '../../text';
import {
  convertGMBoundsToBBox,
  getHighResMapType,
  getSimplePlaceFromGoogle,
  hasPlaceStreetNumber,
  stringCoords2LatLng,
} from '../../utilities/map';
import { isRouteIncluded } from '../../utilities/routes';

// COMPONENTS
import Onboarding from '../../pages/dashboard/components/onboarding';
import MapButton from '../button/MapButton';
import MapModalManager from '../modal/MapModalManager';
import Overlay from '../overlay';
import Page from '../page/Page';
import {
  DRAWING_CONTROLS_ID,
  MAP_STATES,
} from './drawingControls/drawingControls';
import { EnableMetromapButton } from './enableMetromapButton';
import LayersPanel from './layersPanel/LayersPanel';
import { MapManager } from './mapManager';
import { MapViewContext } from './mapViewContext';
import MetromapLogo from './metromapLogo';
import ProductControls, {
  PRODUCT_CONTROLS_ID,
} from './productControls/ProductControls';

// HOOKS
import { useTour } from '@reactour/tour';
import _ from 'lodash/fp';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import useCurrentJob from '../../hooks/useCurrentJob';
import useDebounce from '../../hooks/useDebounce';
import useFocus from '../../hooks/useFocus';
import {
  GET_STARTED_TOURS,
  TOURS,
} from '../../pages/dashboard/components/onboarding/tour';
import { getTourStatus } from '../../redux/actions/tour';
import { getHighResCollectionsContainsBBox } from '../../utilities/metromap';
import mgaUtils from '../../utilities/mga';
import useSmartSelections from '../RightMenu/useSmartSelections';
import { DELETE_SHAPES_MODAL } from '../modal/DeleteShapesModal';
import { INITIATE_TOUR_MODAL } from '../modal/InitiateTourModal';
import ProductSelectedModal from '../modal/ProductSelectedModal';
import EastingNorthing, { EASTING_NORTHING_ID } from './EastingNorthing';
import SiteAddressOverlay from './SiteAddressOverlay';
import { convertGeometries } from './drawingManager/utilities';
import { computeSquarePolygonAroundLocation } from './geometry';
import HighResDate from './highResDate/HighResDate';
import useCoverage from './useCoverage';
import useCoverageLabels from './useCoverageLabels';
import useLarkiMarker from './useLarkiMarker';
import useLoadJob from './useLoadJob';
import useMapRendering from './useMapRendering';
import useSmoothMapZoom from './useSmoothMapZoom';
import { captureError } from '../../utilities/error';

const isRightMenuVisibleByDefault = isRouteIncluded([routes.order.job()]);

// The default location before the user enters an address
export const initial_map_location = stringCoords2LatLng(
  process.env.INITIAL_MAP_LOCATION ?? INITIAL_MAP_LOCATION
);

let DrawingManager = null;

const isMapHighRes = (map) =>
  map?.mapTypeId === MAP_TYPE.HIGH_RES ||
  map?.mapTypeId === MAP_TYPE.HIGH_RES_NZ;

/**
 * Initializes the Google Maps map.
 * Only call this once the Google Maps script has loaded.
 */
const initGoogleMap = (initialZoom = INITIAL_MAP_ZOOM) => {
  console.assert(google.maps);
  const map = new google.maps.Map(document.getElementById('map'), {
    zoomControl: false,
    scrollwheel: false,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    center: initial_map_location,
    radius: 50,
    zoom: initialZoom,
    gestureHandling: 'cooperative',
    isFractionalZoomEnabled: true,
    minZoom: MIN_MAP_ZOOM,
    maxZoom: MAX_MAP_ZOOM,
    tilt: 0,
    disableDoubleClickZoom: true,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: google.maps.ControlPosition.BOTTOM_LEFT,
      mapTypeIds: [
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.SATELLITE,
      ],
    },
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  });
  return map;
};

const generateAUSMapTileUrl =
  (layer) =>
  ({ tileMatrix, tileRow, tileCol }) => {
    const format = 'png';
    const version = '1.0.0';
    const style = 'default';
    const tileMatrixSet = 'webmercator';
    return `${process.env.AEROMETREX_TILES_URL}/${process.env.AEROMETREX_TILES_KEY}/service?SERVICE=WMTS&REQUEST=GetTile&LAYER=${layer}&FORMAT=${format}&VERSION=${version}&STYLE=${style}&TileMatrixSet=${tileMatrixSet}&TileMatrix=${tileMatrix}&TileRow=${tileRow}&TileCol=${tileCol}`;
  };

const generateNZMapTileUrl = ({ tileMatrix, tileRow, tileCol }) => {
  return `${process.env.LINZ_TILES_URL}/${tileMatrix}/${tileCol}/${tileRow}.png?api=${process.env.LINZ_TILES_KEY}`;
};

const loadHighResMapType = (generateUrl, name) => {
  console.assert(google.maps);
  return new google.maps.ImageMapType({
    getTileUrl: function (coord, zoom) {
      const tileMatrix = zoom;
      const tileRow = coord.y;
      const tileCol = coord.x;
      return generateUrl({ tileMatrix, tileRow, tileCol });
    },
    tileSize: new google.maps.Size(256, 256),
    maxZoom: 24, // without maxZoom here it crashes.
    minZoom: 0,
    name,
  });
};

/**
 * Gets the date of the tile in the map bounds.
 * @param map - Google Maps map instance.
 * @returns The date in UTC format.
 */
const getTileDate = async (map) => {
  const bounds = map.getBounds().toJSON();
  const bbox = [bounds.west, bounds.south, bounds.east, bounds.north];
  const response = await fetch(
    `${process.env.AEROMETREX_STAC_URL}/search?bbox=${bbox.join(',')}&limit=1`
  );
  const json = await response.json();
  const closestDatetime = first(json.features)?.properties.datetime;
  return closestDatetime;
};

const MapView = () => {
  /* STATE */
  const siteAddress = useSearchParam('siteAddress');
  const history = useHistory();
  const location = useLocation();
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { state, actions } = useContext(MapViewContext);
  const [tileDate, setTileDate] = useState('');
  const [tilesLoaded, setTilesLoaded] = useState(false);
  const ref = useRef(null);
  const firstRender = useRef(true);
  const [viewportCenter, setViewportCenter] = useState(null);
  const debouncedViewportCenter = useDebounce(viewportCenter, 250);
  const [viewport, setViewport] = useState(null);
  const debouncedViewport = useDebounce(viewport, 500);
  const {
    mapState,
    layout,
    currentUser,
    mapType,
    trialEndNotified,
    userProfileState,
    jobsState,
    tourSelected,
    coverageState,
  } = useSelector((reduxState) => ({
    mapState: reduxState.mapReducer,
    layout: reduxState.layout,
    currentUser: reduxState.profileReducer.userProfile,
    mapType: reduxState.profileReducer.userProfile.map_type,
    trialEndNotified: reduxState.profileReducer.userProfile.products?.find(
      (product) => product.name === 'High Res'
    )?.user_product.trial_end_notified_at,
    userProfileState: reduxState.profileReducer.userProfileState,
    jobsState: reduxState.jobsReducer,
    tourSelected: reduxState.tourReducer.tourSelected,
    coverageState: reduxState.coverageReducer,
  }));
  const previousAddress = usePrevious(state.inputAddress);
  const [deletePressed] = useKeyboardJs('delete');
  const [hasFocusedSiteAddress, setHasFocusedSiteAddress] = useBoolean(false);
  const [siteAddressRef, focusSiteAddress] = useFocus();

  const addSmartSelectionsInPlace = useSmartSelections();

  const hasAddress = !!(state.address || state.job?.full_address);
  const hasLoadedAndNoAddress = !jobsState.isGettingJob && !hasAddress;
  const [isAddressChanged, setIsAddressChanged] = useState(false);

  const lotAreaState = useMemo(() => {
    if (!jobsState.job || !jobsState.job?.lot_area_state) return null;
    return jobsState.job?.lot_area_state;
  }, [jobsState.job]);

  // MGA zone
  //  set to the viewport center whenever
  //  the job is initially loaded
  //  or the address is changed
  const [mgaZone, setMgaZone] = useState(null);

  // tour hooks
  const { setSteps, steps, setCurrentStep } = useTour();

  // smooth map zoom
  const mapBind = useSmoothMapZoom();

  /* FUNCTIONS */

  /**
   * Called when e.g. user types a place into the search box, or selects a place from one of the autocomplete suggestions.
   * If the place has a street number, add smart selections with automatic data; otherwise, draw a rectangle around the place.
   * @param place - The place object returned by the Google Maps Places API.
   */
  const handlePlaceChange = (place) => {
    const simplePlace = getSimplePlaceFromGoogle(place);

    if (!simplePlace) {
      console.warn(`invalid place ${place}`);
    }

    actions.setAddress({
      address: simplePlace.address,
      city: simplePlace.city,
      state: simplePlace.state,
      postcode: simplePlace.postcode,
    });
    actions.setMapPlace(place);
    actions.setInputAddress(place.formatted_address);
    actions.setIsAddressInfoVisible(false);

    if (state.isHighResEnabled && getHighResMapType(simplePlace)) {
      actions.setCurrentMapTypeId(getHighResMapType(simplePlace));
    }

    actions.setRectangleToDraw(null);
    actions.setActiveSelectionId(null);
    actions.setGeometries({});
    actions.setGeometriesToLoad({});
    setIsAddressChanged(true);
    actions.setIsCartLoading(true);

    if (hasPlaceStreetNumber(place)) {
      dispatch(setUndefinedAddress(false));
      addSmartSelectionsInPlace(simplePlace, 'automatic');
    } else {
      dispatch(setUndefinedAddress(true));
      actions.setRectangleToDraw({
        centre: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        size: INITIAL_RECTANGLE_SIZE,
      });
    }
    dispatch(toggleRightMenuVisibility(true));
  };

  const geocodeAddress = (address, parent = null) => {

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        const place = results[0];
        // use same address in input for consistency
        place.formatted_address = address;
        if(parent) parent.value = place.formatted_address;
        handlePlaceChange(place);
      } else {
        console.warn('Geocode failed: ' + status);
        captureError(new Error(`An error has occured in Geocoding. status: ${status}, address: ${address}`));
      }
    });
  };

  /**
   * Initializes the map.
   * Loads the high-res tiles, and adds the high-res button to the map.
   * Adds the search box and biases the autocomplete results towards the current map viewport.
   * Listens for place changes, when the user enters a place manually or selects a place from
   * the autocomplete suggestions, and calls `onHandlePlaceChange`.
   */
  const initMap = () => {
    const initialZoom = INITIAL_MAP_ZOOM;
    const map = initGoogleMap(initialZoom);

    const highResMapType = loadHighResMapType(
      generateAUSMapTileUrl('Australia_latest'),
      'High-res 2D Map'
    );
    const highResMapTypeNZ = loadHighResMapType(
      generateNZMapTileUrl,
      'High-res 2D Map NZ'
    );

    map.mapTypes.set(MAP_TYPE.HIGH_RES, highResMapType);
    map.mapTypes.set(MAP_TYPE.HIGH_RES_NZ, highResMapTypeNZ);

    const highResButton = document.getElementById('high-res-button');
    map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(highResButton);

    // Wrap metromap logo and high-res date selector
    const metromapControlsContainer = document.getElementById(
      'metromap-controls-container'
    );
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
      metromapControlsContainer
    );

    const mapManager = new MapManager(map);
    actions.setMap(map);
    actions.setMapManager(mapManager);
    actions.incrementMapLoadCount();

    /** ************for search box***********************/
    // Create the search box and link it to the UI element.
    const inputContainer = document.getElementById('pac-input-container');
    const input = document.getElementById('pac-input');
    const messages = document.getElementById('messages');
    // const mapShadowOverlay = document.getElementById('map-shadow-overlay');
    const siteAddressOverlay = document.getElementById('site-address-overlay');

    if (process.env.ENABLE_AUTO_MAP_SEARCH) {
      actions.setAddress(AUTO_MAP_ADDRESS);
      actions.setIsAddressInfoVisible(false);
    }

    const searchBox = new google.maps.places.Autocomplete(input);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputContainer);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(messages);
    map.controls[google.maps.ControlPosition.CENTER].push(siteAddressOverlay);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener(
      'bounds_changed',
      debounce(() => {
        searchBox.setBounds(map.getBounds());
        const date = getTileDate(map);
        setTileDate(date);
      }, 100)
    );

    map.addListener('projection_changed', () => {
      actions.setMapHasProjection(!!map.getProjection());
    });

    // listen for map type change
    google.maps.event.addListener(map, 'maptypeid_changed', function () {
      actions.setCurrentMapTypeId(map.getMapTypeId());
    });

    // listen for map to finish loading
    // TODO: hacky
    google.maps.event.addListenerOnce(map, 'idle', function () {
      if (!state.isMapVisible) {
        // Map takes a bit of time to fully load
        setTimeout(() => actions.setIsMapVisible(true), MAP_LOAD_DELAY);
      }
    });

    // Listen for the event fired when the user selects a prediction and retrieve more details for that place.
    google.maps.event.addListener(searchBox, 'place_changed', () => {

      const placeResult = searchBox.getPlace();
      if(!placeResult.formatted_address){
        console.warn('address not found');
        return;
      }

      geocodeAddress(placeResult.formatted_address);
 
    });

    // listen for search completions in the address search box
    google.maps.event.addListener(input, 'keydown', (e) => {
      if (e.key !== 'Enter') {
        return;
      }

      const item = document.querySelector(
        '.pac-container .pac-item:first-child'
      );
      if (!item) {
        console.warn('no search results');
        return;
      }

      const matched = item.querySelector('.pac-item-query')?.textContent;
      const rest = item.lastChild?.textContent;
      const address = `${matched} ${rest}`;

      geocodeAddress(address, input);

    });

    // listen to map center change
    google.maps.event.addListener(map, 'center_changed', () => {
      setViewportCenter(map.getCenter());
    });

    google.maps.event.addListener(map, 'bounds_changed', () => {
      setViewport(map.getBounds());
    });

    /* Product controls */
    const drawingControls = document.getElementById(DRAWING_CONTROLS_ID);
    const productControls = document.getElementById(PRODUCT_CONTROLS_ID);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(drawingControls);
    map.controls[google.maps.ControlPosition.LEFT_CENTER].push(productControls);

    // Easting/Northing
    const eastingNorthingControls =
      document.getElementById(EASTING_NORTHING_ID);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
      eastingNorthingControls
    );

    google.maps.event.addListener(map, 'tilesloaded', () => {
      setTilesLoaded(true);
      const date = getTileDate(map);
      setTileDate(date);
    });
  };

  const hoveredData = state.coverageInRegionSelection?.find(
    (data) => data.category_name === state.hoveredProductType
  );

  const getInfoHoverText = useCallback(() => {
    if (hoveredData) {
      let result = '';
      if (
        state.activeSelection &&
        state.activeSelection.category_name === hoveredData.category_name
      ) {
        return text('selectedDataAlreadyAdded', {
          productName: hoveredData.display_name,
        });
      }

      if (_.size(hoveredData.layers) > 0) {
        result = text('productDataAvailable', {
          productName: hoveredData.display_name,
        });

        if (!hoveredData.is_invalid) {
          result += text('dragModifySelectionArea');
        }
      } else if (!isEmpty(state.debouncedRegionSelection)) {
        result = text('productDataUnavailable', {
          productName: hoveredData.display_name,
        });
      }

      if (hoveredData.is_invalid) {
        if (hoveredData.is_invalid === 'TOO_LARGE') {
          result += text('dragDecreaseSelectionArea');
        } else {
          result += text('dragIncreaseSelectionArea');
        }
      }

      return result;
    }

    if (
      _.size(state.coverageInRegionSelection) === 0 &&
      !_.isEmpty(state.debouncedRegionSelection)
    ) {
      return text('allProductUnavailable');
    }
  }, [state.debouncedRegionSelection, hoveredData]);

  const handleDeleteActiveSelection = useCallback(() => {
    if (!state.activeSelection) {
      return;
    }
    dispatch(
      showModal(DELETE_SHAPES_MODAL, {
        selectionsToDelete: [state.activeSelection],
      })
    );
  }, [state.activeSelection]);

  const computeMgaZone = (center) => {
    const lat = center.lat();
    const lng = center.lng();
    const zone = mgaUtils.latLngToMgaZone(lat, lng);
    if (zone) setMgaZone(zone);
  };

  /* HOOKS */

  useMount(() => {
    if (!jobId) {
      dispatch(setJob(null));

      if (!isEmpty(state.selections)) {
        actions.setSelections([]);
      }
      if (state.inputAddress) {
        actions.setInputAddress();
      }
    }

    if (isRightMenuVisibleByDefault(location.pathname)) {
      dispatch(toggleRightMenuVisibility(true));
    }

    dispatch(getProducts());
  });

  useUnmount(() => {
    actions.setSelections([]);
    actions.setActiveSelectionId(null);
    actions.setGeometries({});
    actions.setGeometriesToLoad(null);
    actions.setRectangleToDraw(null);
    actions.setHoveredData(null);
    actions.setHoveredProductType(null);
    actions.setDefaultRegion(null);
    actions.setProductAvailability({});
    actions.setHasLoadedJob(false);
    actions.setIsMapVisible(false);
    actions.setProject({});
    actions.setAddress(null);
    actions.setMapPlace(null);
    actions.setInputAddress('');
    actions.setIsAddressInfoVisible(true);
    actions.setCurrentMapState(MAP_STATES.NONE);
    actions.setCoverageInRegionSelection(null);
    actions.setCoverageInDefaultRegion(null);
    actions.setMapHasProjection(false);

    setIsAddressChanged(false);
    dispatch(clearJob());
    dispatch(toggleRightMenuVisibility(false));
    firstRender.current = true;
  });

  const currentJob = useCurrentJob();
  const isReadOnly = !!currentJob && currentJob.status !== STATUS.DRAFT;

  useLoadJob((center) => computeMgaZone(center));

  useCoverage();

  useMapRendering();

  useCoverageLabels();

  useLarkiMarker();

  useEffect(async () => {
    if (state.job) {
      actions.setHasShadowOverlay(false);
    }
  }, [state.job]);

  useEffect(() => {
    // hack to avoid updated_at conflict when changing address
    const updateLotAreaState_ = (hidden = true, visibility = false) => {
      state.job &&
        dispatch(
          updateLotAreaState(state.job.id, {
            hidden,
            visibility,
          })
        );
    };

    if (isAddressChanged) {
      if (jobsState.undefinedAddress) {
        updateLotAreaState_(true, false);
      } else {
        updateLotAreaState_(false, true);
      }
    }
  }, [isAddressChanged, jobsState.undefinedAddress]);

  // Handle visibility of the lot area boundary such that it only appears
  // iff the zoom level is higher than 16
  useEffect(() => {
    let listener;
    if (state.map && state.googleMapsScriptStatus === 'ready') {
      listener = google.maps.event.addListener(
        state.map,
        'zoom_changed',
        () => {
          if (
            state.map?.zoom <= 16 &&
            lotAreaState &&
            lotAreaState.visibility
          ) {
            toggleLotAreaBoundaryVisibility(false);
            dispatch(setZoomToggle(true));
          } else if (
            state.map.zoom > 16 &&
            lotAreaState &&
            !lotAreaState.visibility &&
            mapState.isZoomToggle
          ) {
            toggleLotAreaBoundaryVisibility(true);
            dispatch(setZoomToggle(true));
          }
        }
      );
    }
    return () => listener && listener.remove();
  }, [state.map, lotAreaState, mapState.isZoomToggle]);

  // Handles fitting the map to the bounds of the place whenever it changes
  useEffect(() => {
    if (state.map && state.mapPlace) {
      const bounds = new google.maps.LatLngBounds();
      if (state.mapPlace.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(state.mapPlace.geometry.viewport);
      } else {
        bounds.extend(state.mapPlace.geometry.location);
      }

      actions.zoom.fitBounds(state.map, bounds, state.zoom.zoom);
      computeMgaZone(bounds.getCenter());
    }
  }, [state.mapPlace]);

  // Handles updating the URL with the address whenever it changes
  //
  // This could be inside of onHandlePlaceChange
  // but the map instance does not listen to state changes like location
  useDebounce(
    () => {
      if (
        state.inputAddress &&
        previousAddress !== state.inputAddress &&
        state.hasLoadedJob
      ) {
        const search = new URLSearchParams(location.search);
        search.append('siteAddress', state.inputAddress);
        history.replace({
          pathname: location.pathname,
          search: search.toString(),
        });
      }
    },
    1000,
    [state.inputAddress]
  );

  useEffect(async () => {
    if (
      state.googleMapsScriptStatus === 'ready' &&
      state.map &&
      (!jobId || (jobId && state.hasLoadedJob)) &&
      siteAddress
    ) {
      const placesService = new google.maps.places.PlacesService(state.map);
      placesService.findPlaceFromQuery(
        {
          query: siteAddress,
          fields: ['ALL'],
        },
        ([place]) => {
          placesService.getDetails(
            { placeId: place.place_id },
            (placeResult) => {
              handlePlaceChange(placeResult);
            }
          );
        }
      );
    }
  }, [siteAddress, state.map]);

  useEffect(() => {
    if (state.currentMapTypeId && state.map) {
      dispatch(updateMapType(state.currentMapTypeId));
      state.map.setMapTypeId(state.currentMapTypeId);
    }
  }, [state.currentMapTypeId, state.map]);

  useEffect(() => {
    if (tilesLoaded) {
      const btn = document.getElementById('high-res-button');

      if (btn?.previousElementSibling) {
        btn.previousElementSibling.style.zIndex = 1;
      }
    }
  }, [tilesLoaded]);

  useEffect(async () => {
    if (state.googleMapsScriptStatus === 'ready') {
      const temp = await import('./drawingManager/drawingManager');
      DrawingManager = temp.DrawingManager;

      initMap();
    }
  }, [state.googleMapsScriptStatus]);

  /**
   * Update the `defaultRegion` when the debounced viewport center changes.
   */
  useEffect(() => {
    if (!state.map) {
      return;
    }
    // let defaultRegion = actions.getDefaultRegion();
    if (debouncedViewportCenter) {
      const newRegion = computeSquarePolygonAroundLocation(
        debouncedViewportCenter
      );
      actions.setDefaultRegion(newRegion);
    }
  }, [state.map, debouncedViewportCenter]);

  useEffect(() => {
    actions.setCurrentMapTypeId(mapType);

    if (!state.isHighResEnabled && mapType === MAP_TYPE.HIGH_RES) {
      actions.setCurrentMapTypeId(MAP_TYPE.ROAD_MAP);
    }
  }, [state.isHighResEnabled, mapType]);

  // Handle delete key press
  useEffect(() => {
    if (!deletePressed) {
      return;
    }
    if (state?.activeSelection) {
      handleDeleteActiveSelection();
    }
  }, [deletePressed]);

  useEffect(() => {
    if (
      state.map &&
      tilesLoaded &&
      siteAddressRef.current &&
      state.isMapVisible &&
      hasLoadedAndNoAddress &&
      !hasFocusedSiteAddress
    ) {
      setTimeout(() => {
        focusSiteAddress();
      }, 10);
      setHasFocusedSiteAddress(true);
    }
  }, [
    state.map,
    tilesLoaded,
    siteAddressRef,
    state.isMapVisible,
    hasLoadedAndNoAddress,
  ]);

  /* ---------- HIGH-RES 2D TILES ---------- */

  // Load high res collections on mount
  useMount(() => {
    dispatch(loadHighResCollections());
  });

  // Load layers
  useEffect(() => {
    const { collections, currentDate } = mapState.highRes;
    if (
      !debouncedViewport ||
      collections.isLoading ||
      !collections.data ||
      !isMapHighRes(state.map)
    ) {
      return;
    }
    const viewportBBox = convertGMBoundsToBBox(debouncedViewport);
    const collectionIds = _.map(
      'id',
      getHighResCollectionsContainsBBox(collections.data, viewportBBox)
    );
    if (!collectionIds || collectionIds.length === 0) {
      console.warn('No high res collection contains the current viewport');
      return;
    }
    dispatch(loadHighResLayers(collectionIds, viewportBBox, currentDate));
  }, [mapState.highRes.collections, debouncedViewport]);

  // Whenever the current date changes, reload the high res map tiles
  useEffect(() => {
    const { currentDate } = mapState.highRes;
    if (!currentDate) {
      return;
    }
    const currentLayer = mapState.highRes.layers.data?.[currentDate]?.[0];
    if (currentLayer) {
      const highResMapType = loadHighResMapType(
        generateAUSMapTileUrl(currentLayer.properties.layername),
        'High-res 2D Map'
      );

      state.map.mapTypes.set(MAP_TYPE.HIGH_RES, highResMapType);
    }
  }, [mapState.highRes.currentDate]);

  /* -------------------- */

  useEffect(() => {
    dispatch(getTourStatus(TOURS.TOUR_2D_MAP, setSteps, setCurrentStep));
    dispatch(disableSmartSelectionsApi(false));

    return () => {
      setSteps([]);
      dispatch(clearJob());
      dispatch(disableSmartSelectionsApi(true));
    };
  }, []);

  useEffect(() => {
    if (tourSelected) {
      setSteps(GET_STARTED_TOURS[tourSelected]);
      setCurrentStep(0);
    }

    if (
      state.map &&
      tilesLoaded &&
      state.mapManager &&
      state.googleMapsScriptStatus === 'ready' &&
      steps.length
    ) {
      dispatch(showModal(INITIATE_TOUR_MODAL));
    }
  }, [
    state.map,
    tilesLoaded,
    state.mapManager,
    state.googleMapsScriptStatus,
    steps,
    tourSelected,
  ]);

  const toggleLotAreaBoundaryVisibility = (status) => {
    if (lotAreaState) {
      dispatch(toggleJobBoundaryVisibility(status));

      jobId &&
        dispatch(
          updateLotAreaState(jobId, {
            ...lotAreaState,
            visibility: !lotAreaState.visibility,
          })
        );
    }
  };

  /* ---------- Draft shape creation ---------- */

  useEffect(() => {
    if (!state.draftToCreate || !state.coverageInRegionSelection) {
      return;
    }
    const draftCoverage = _.find(
      ['category_name', 'unknown'],
      state.coverageInRegionSelection
    );
    if (!draftCoverage) {
      return;
    }
    console.assert(!!state.debouncedRegionSelection);
    const newRegion = _.values(
      convertGeometries(state.debouncedRegionSelection, false)
    )[0]?.region;
    if (!newRegion) {
      return;
    }
    actions.addSelection(draftCoverage, newRegion, true);
    actions.setDraftToCreate(false);
    // recall that regionSelection <- coverageInRegionSelection
    //  (whenever regionSelection changes, coverageInRegionSelection changes)
  }, [state.draftToCreate, state.coverageInRegionSelection]);

  /* -------------------- */

  // todo: remove DEBUG ONLY

  // const [hashesAdded, setHashesAdded] = useState([]);
  // useEffect(() => {
  //   if (!state.mapManager) {
  //     return;
  //   }
  //   coverageState.hashes.forEach((hash) => {
  //     if (hashesAdded.includes(hash)) {
  //       return;
  //     }
  //     const bounds = geoDecode(hash);
  //     createRectangle(bounds, hash, state.map, false);
  //     setHashesAdded([...hashesAdded, hash]);
  //   });
  // }, [coverageState.hashes]);
  // useEffect(() => {
  //   if (!state.mapManager) {
  //     return;
  //   }
  //   console.log(coverageState.caches);
  //   const merged = mergeCoverage(coverageState.caches);
  //   console.log(merged);
  // }, [coverageState.caches]);

  /* ---------- Easting/Northing coordinate ---------- */

  const eastingNorthingCoord = useMemo(() => {
    if (!state.isMapVisible || !tilesLoaded) {
      return null;
    }
    const latLng = state.mousePositionOnMap;
    if (!latLng) {
      return null;
    }
    return [latLng.lat(), latLng.lng()];
  }, [state.isMapVisible, tilesLoaded, state.mousePositionOnMap]);

  /* -------------------- */

  return (
    <Page
      id='MapView'
      className={classnames({
        'read-only': isReadOnly,
        'layers-panel-visible': layout.layersPanel.isVisible,
        'right-menu-visible': layout.rightMenu.isVisible,
      })}
      title={text(
        state.currentMapTypeId === MAP_TYPE.HIGH_RES ? 'highResMap2D' : 'map2D'
      )}
    >
      <Page.Body>
        <LayersPanel />
        <div
          className={classNames('left-section', {
            rightMenuExpanded: layout.rightMenu.isVisible,
          })}
        >
          {!tilesLoaded && (
            <Overlay
              className={classNames({
                'larki-overlay-grey': !jobId,
              })}
            >
              <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                <PulseLoader size={12} color='#31a283' loading />
              </div>
            </Overlay>
          )}
          <SiteAddressOverlay show={hasLoadedAndNoAddress} />
          <div
            id='pac-input-container'
            ref={ref}
            className={classNames('d-none', {
              'd-block': tilesLoaded,
            })}
          >
            <div>
              <input
                ref={siteAddressRef}
                type='text'
                value={state.inputAddress ?? ''}
                onChange={(event) =>
                  actions.setInputAddress(event.target.value)
                }
                id='pac-input'
                className={classNames('pac-target-input', {
                  // 'pac-danger': state.selections?.length > 0 && overlayActive,
                  noSearchIcon: state.inputAddress,
                })}
                placeholder='Search site address'
                autoComplete='off'
                disabled={state.selections?.length > 0}
              />
            </div>

            {mapState.lotArea && lotAreaState && !lotAreaState.hidden && (
              <MapButton
                className='lotArea'
                onClick={() =>
                  toggleLotAreaBoundaryVisibility(!lotAreaState.visibility)
                }
              >
                <div
                  className={classnames('lotAreaContainer', {
                    noBorder: lotAreaState && !lotAreaState.visibility,
                  })}
                >
                  <h6 className='title'>{text('lotArea')}</h6>
                  <h6>{formatSqmArea(mapState.lotArea)}</h6>
                </div>
              </MapButton>
            )}
          </div>
          <div></div>
          {/* TODO: use internationalization (text function) */}
          <div
            id='messages'
            className={classNames('d-none', {
              'd-block': tilesLoaded,
            })}
          >
            <p
              id='address-info'
              className={classNames('notification-msg px-3', {
                present: state.isAddressInfoVisible,
              })}
            >
              Enter an address to start your project.&nbsp;
              {state.addressState === 'countdown' && <span>Got it!</span>}
            </p>
            <p
              id='disabled-message'
              className={classNames('notification-msg d-none', {
                // 'd-block': state.selections?.length > 0 && overlayActive,
              })}
            >
              <span className='d-flex justify-content-center align-items-center'>
                Location cannot be modified while there are items in your added
                list.
              </span>
            </p>
            {getInfoHoverText() && (
              <p
                id='info-message'
                className={classNames('notification-msg d-none', {
                  'd-block': hoveredData,
                })}
              >
                <span className='d-flex justify-content-center align-items-center px-3'>
                  {hoveredData?.is_invalid && (
                    <img
                      className='icon-img mr-2'
                      src='/public/img/icon-warning.svg'
                      alt='polygon'
                    />
                  )}
                  {getInfoHoverText()}
                </span>
              </p>
            )}
            <p
              id='marquee-info'
              className='notification-msg px-3'
              style={{ display: state.marqueeState }}
            >
              <img
                className='icon-img'
                src='/public/img/rectangle-tool.svg'
                alt='rectangle'
              />
              &nbsp; Click corners, drag.&nbsp;
            </p>
            <p
              id='polygon-info'
              className='notification-msg px-3'
              style={{ display: state.polygonState }}
            >
              <img
                className='icon-img'
                src='/public/img/polygon-tool.svg'
                alt='polygon'
              />{' '}
              &nbsp; Click corners, double-click close, drag.&nbsp;
            </p>
            <p
              id='polyline-info'
              className='notification-msg px-3'
              style={{ display: state.polylineState }}
            >
              <img
                className='icon-img'
                src='/public/img/polyline-tool.svg'
                alt='polygon'
              />{' '}
              &nbsp; Click corners, double-click finish, drag.&nbsp;
            </p>
          </div>

          <div
            id='metromap-controls-container'
            className={classNames('d-none', {
              'd-flex':
                tilesLoaded && state.isMapVisible && isMapHighRes(state.map),
            })}
          >
            <MetromapLogo
              isVisible={state.isMapVisible && isMapHighRes(state.map)}
              mapTypeId={state.map?.mapTypeId}
              tileDate={tileDate}
            />
            <HighResDate
              isVisible={state.isMapVisible && isMapHighRes(state.map)}
            />
          </div>

          <EnableMetromapButton />

          <div className='toolbar'>
            <div
              className={classNames('d-none', {
                'd-block': tilesLoaded,
              })}
            >
              <ProductControls />
              {DrawingManager && <DrawingManager isReadOnly={isReadOnly} />}
            </div>
          </div>

          <EastingNorthing coord={eastingNorthingCoord} mgaZone={mgaZone} />

          <div
            className={classNames('h-100 w-100 visible', {
              invisible: !tilesLoaded,
            })}
            id='map'
            ref={state.mapRef}
            {...mapBind()}
          ></div>
        </div>
      </Page.Body>
      <MapModalManager />
      {/* <BlockModal
        when={isUserAnonymous(currentUser)}
        checkCondition={(pathname) =>
          !isRouteIncluded([
            routes.login,
            routes.order.root,
            routes.order.job(),
            routes.payment.job(),
          ])(pathname)
        }
        isBodyCentered
        title={text('areYouSureYouWantToLeavePage')}
        message={text('progressLost')}
        confirmText={text('leavePage')}
        onConfirm={() => sessionStorage.clear()}
        cancelText={text('stayOnPage')}
      /> */}
      <Onboarding />

      {state.showProductModal && <ProductSelectedModal />}
    </Page>
  );
};

export default MapView;
