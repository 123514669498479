import React from 'react';
import { Wizard } from 'react-use-wizard';
import StartTrialStep from './StartTrialStep';
import StartTrialStatusStep from './StartTrialStatusStep';

const StartTrialWizard = ({ onCancel }) => {
  return (
    <Wizard>
      <StartTrialStep onCancel={onCancel} />
      <StartTrialStatusStep onNext={onCancel} />
    </Wizard>
  );
};

export default StartTrialWizard;
