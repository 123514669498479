import React, { Fragment, useContext } from 'react';
import { Badge } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { IconButton } from '../button';
import CollapseButton from '../button/CollapseButton';
import { MapViewContext } from '../mapView/mapViewContext';
import Product from './Product';

export const ProductTableHeader = ({
  headerText,
  advantagesText,
  disadvantagesText,
  isCollapsed,
  isDeleteDisabled,
  onCollapse,
  onDelete,
  isCollapseDisabled,
  isDeleteHidden,
  isCollapseHidden,
  isBadgesHidden,
}) => {
  return (
    <div className='mb-2 mt-3'>
      <div className='d-flex justify-content-between center-content'>
        <h6>{headerText}</h6>
        <div className='d-flex'>
          {!isDeleteHidden && <IconButton
            disabled={isDeleteDisabled}
            icon='trash'
            onClick={onDelete}
          />}
          {!isCollapseHidden && <CollapseButton
            disabled={isCollapseDisabled}
            onClick={onCollapse}
            isOpen={isCollapsed}
            variant='icon'
          />}
        </div>
      </div>
      {!isBadgesHidden && <div>
        <div>
          <Badge variant='advantage' className='mr-1'>
            {advantagesText}
          </Badge>
          <Badge variant='disadvantage'>{disadvantagesText}</Badge>
        </div>
      </div>}
    </div>
  );
};

ProductTableHeader.propTypes = {
  headerText: PropTypes.string,
  advantagesText: PropTypes.string,
  disadvantagesText: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isDeleteDisabled: PropTypes.bool,
  onCollapse: PropTypes.func,
  onDelete: PropTypes.func,
  isCollapseDisabled: PropTypes.bool,
  isDeleteHidden: PropTypes.bool,
  isCollapseHidden: PropTypes.bool,
  isBadgesHidden: PropTypes.bool,
};

const ProductTable = ({
  product,
  chargesPerProduct,
  coveragePerProduct,
  isReadOnly,
}) => {
  const { state } = useContext(MapViewContext);
  /**
   * productType - aerial, streetscape etc.
   * productCharges - contains all product shapes
   */
  const [productType, productCharges] = product;

  return (
    <Fragment key={`${productType}-${productCharges[0]?.id}`}>
      <Product
        product={productType}
        data={product}
        charges={{
          ...chargesPerProduct[product[1][0].display_name],
          currency: { ...state.job.quote.currency },
        }}
        isReadOnly={isReadOnly}
      />
      {/* <tbody> */}
      {/* <OverallDataSelectionRow
        key={`${product[0]}-${product[1][0].id}`}
        data={product[1]}
        product={product[0]}
        toggleProductCollapse={() =>
          isProductCollapsedActions.set(
            product[0],
            !isProductCollapsed[product[0]]
          )
        }
        isProductCollapsed={isProductCollapsed[product[0]]}
        charges={{
          ...chargesPerProduct[product[1][0].display_name],
          currency: { ...state.job.quote.currency },
        }}
        isReadOnly={isReadOnly}
      /> */}

      {/* START SHOW DISCOUNT SECTION */}
      {/* {map(
        chargesPerProduct[product[1][0].display_name]?.discounts.filter(
          (discount) => discount.id !== 'member'
        ),
        (discount) => (
          <>
            <OtherChargeRow
              key={discount.id}
              description={discount.display_name}
              isReadOnly={isReadOnly}
              amount={`—${formatCurrency(
                discount.price.discount,
                state.job?.quote?.currency.name,
                state.job?.quote?.currency.scale_factor
              )}`}
            />
          </>
        )
      )} */}
      {/* END SHOW DISCOUNT SECTION */}
      {/* </tbody> */}

      {/* START COLLAPSIPLE SHAPES ITEMS */}
      {/* <Collapse
        className='simpleDataRowGroup'
        in={!isProductCollapsed[product[0]]}
      >
        <tbody>
          {product[1].map((selection, index) => {
            const coverage = _.find(['selection_id', selection.id])(
              coveragePerProduct[productType]
            );
            return (
              <SimpleDataRow
                key={selection.id}
                data={selection}
                coverage={coverage}
                index={index}
                isReadOnly={isReadOnly}
              />
            );
          })}
        </tbody>
      </Collapse> */}
      {/* END COLLAPSIPLE SHAPES ITEMS */}

      {/* START SHOW OF ADDT'L FEES e.g. Below minimum purchase fee */}
      {/* <tbody>
        {chargesPerProduct[product[1][0].display_name]?.fees.map((item) => (
          <>
            <OtherChargeRow
              key={item.id}
              description={item.display_name}
              isReadOnly={isReadOnly}
              amount={`${formatCurrency(
                item.price.total,
                state.job?.quote?.currency.name,
                state.job?.quote?.currency.scale_factor
              )}`}
            />
          </>
        ))}
        <TableRowSpacer />
      </tbody> */}
      {/* END SHOW OF ADDT'L FEES e.g. Below minimum purchase fee */}
    </Fragment>
  );
};

ProductTable.propTypes = {
  product: PropTypes.array,
  chargesPerProduct: PropTypes.object,
  coveragePerProduct: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

ProductTable.defaultProps = {
  isReadOnly: false,
};

export default ProductTable;
