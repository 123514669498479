import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WhiteContainer from '../../../components/containers/WhiteContainer';
import List from '../../../components/list/List';
import Loading from '../../../components/loading/Loading';
import { getMembershipPrices, quoteOrder } from '../../../redux/actions';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';

const MembershipInvoice = () => {
  const dispatch = useDispatch();
  const { job, membershipPriceId, membershipPrices } = useSelector((state) => ({
    job: state.jobsReducer.job,
    membershipPriceId: state.order.membershipPriceId,
    membershipPrices: state.profileReducer.membershipPrices,
  }));

  const selectedMembership = useMemo(() => {
    if (membershipPrices.value) {
      return membershipPrices.value.find(
        (membership) => membership.id === membershipPriceId
      );
    }
  }, [membershipPriceId, membershipPrices]);

  useEffect(async () => {
    if (membershipPriceId) {
      dispatch(getMembershipPrices());
      if (!isEmpty(job)) {
        dispatch(
          quoteOrder(job.id, undefined, !isMembershipPriceId(membershipPriceId))
        );
      }
    }
  }, [membershipPriceId]);

  return (
    isMembershipPriceId(membershipPriceId) && (
      <WhiteContainer className='Invoice MembershipInvoice'>
        {membershipPrices.isLoading ? (
          <Loading />
        ) : (
          selectedMembership && (
            <>
              <h6 className='green-1'>
                {text('invoiceType', {
                  invoiceType: text('membership'),
                  invoiceCount: '1.',
                })}
              </h6>
              <>
                <List>
                  <tbody>
                    <List.Item
                      description={selectedMembership?.name}
                      value={formatCurrency(
                        selectedMembership?.grand_total,
                        selectedMembership?.currency.name,
                        selectedMembership?.currency.scale_factor,
                        {},
                        true
                      )}
                    />
                  </tbody>
                </List>
                <List>
                  <tbody>
                    <List.Item
                      description={text('subtotal')}
                      value={formatCurrency(
                        selectedMembership.subtotal,
                        selectedMembership.currency.name,
                        selectedMembership.currency.scale_factor,
                        {},
                        true
                      )}
                    />
                    {selectedMembership.taxes.map((tax, i) => (
                      <List.Item
                        key={i}
                        description={tax.display_name}
                        value={formatCurrency(
                          tax.price.extra,
                          selectedMembership.currency.name,
                          selectedMembership.currency.scale_factor,
                          {},
                          true
                        )}
                      />
                    ))}
                    <List.Item
                      isHighlighted
                      description={text('total')}
                      value={formatCurrency(
                        selectedMembership.grand_total,
                        selectedMembership.currency.name,
                        selectedMembership.currency.scale_factor,
                        {},
                        true
                      )}
                    />
                  </tbody>
                </List>
              </>
            </>
          )
        )}
      </WhiteContainer>
    )
  );
};

export default MembershipInvoice;
