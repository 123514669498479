import React, { useContext } from 'react';
import map from 'lodash/map';
import { MapViewContext } from '../mapViewContext';
import ProductBadge from './productBadge/ProductBadge';
import {
  PRODUCT_TYPE,
  PRODUCT_DELIVERY_METHOD,
} from '../../../constants/product';
import text from '../../../text';

export const PRODUCT_CONTROLS_ID = 'ProductControls-wrapper';

const productsByDeliveryMethod = {
  draft: [
    {
      productType: PRODUCT_TYPE.UNKNOWN,
    },
  ],
  database: [
    {
      productType: PRODUCT_TYPE.AERIAL,
    },
    {
      productType: PRODUCT_TYPE.STREETSCAPE,
    },
  ],
  capture: [
    {
      productType: PRODUCT_TYPE.DRONE,
    },
    {
      productType: PRODUCT_TYPE.EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.INTERIOR,
    },
  ],
  addOns: [
    {
      productType: PRODUCT_TYPE.BIM_EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.BIM_INTERIOR,
    },
    {
      productType: PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
    },
  ],
};

const ProductControls = () => {
  const { state } = useContext(MapViewContext);
  return (
    <div id={PRODUCT_CONTROLS_ID} className='ProductControls'>
      {map(productsByDeliveryMethod, (products, deliveryMethod) => (
        <React.Fragment key={deliveryMethod}>
          <div className='toolbar-label'>
            {text(PRODUCT_DELIVERY_METHOD[deliveryMethod])}
          </div>
          <div className='product-control-button-group'>
            {map(products, ({ productType }) => (
              <ProductBadge
                key={productType}
                productType={productType}
                variant={
                  productType === PRODUCT_TYPE.UNKNOWN ? 'tertiary' : 'default'
                }
                withExplanation={productType === PRODUCT_TYPE.UNKNOWN}
                deliveryMethod={deliveryMethod}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProductControls;
