import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS } from '../constants';
import { MapViewContext } from '../mapViewContext';
import DrawingControlsButton from './drawingControlsButton';
import text from '../../../text';

const rectangleIcon = '/public/img/rectangle-tool.svg';
const polygonIcon = '/public/img/polygon-tool.svg';
const polylineIcon = '/public/img/polyline-tool.svg';
const booleanIcon = '/public/img/boolean-tool.svg';
const undoIcon = '/public/img/undo-tool.svg';
const redoIcon = '/public/img/redo-tool.svg';

export const DRAWING_CONTROLS_ID = 'drawing-controls';

export const MAP_STATES = {
  DRAWING_POLYGON: 'DRAWING_POLYGON',
  DRAWING_POLYLINE: 'DRAWING_POLYLINE',
  DRAWING_RECTANGLE: 'DRAWING_RECTANGLE',
  DRAWING_BOOLEAN: 'DRAWING_BOOLEAN',
  SELECTION: 'SELECTION',
  UNDO: 'UNDO',
  REDO: 'REDO',
  NONE: 'NONE',
};
export const DRAWING_STATES = {
  DRAWING: 'DRAWING',
  COMPLETE: 'COMPLETE',
  NONE: 'NONE',
};

const DrawingControls = ({
  mapState,
  setMapState,
  setDrawingState,
  isReadOnly,
}) => {
  const { state, actions } = useContext(MapViewContext);

  const [isBooleanDisabled, setIsBooleanDisabled] = useState(false);
  useEffect(() => {
    if (Object.keys(state.geometries).length < 1) {
      setIsBooleanDisabled(true);
    } else {
      let disable = true;
      Object.keys(state.geometries).forEach((geometryId) => {
        if (
          state.geometries[geometryId].type === 'rectangle' ||
          state.geometries[geometryId].type === 'polygon'
        ) {
          disable = false;
        }
      });
      setIsBooleanDisabled(disable);
    }
  }, [state.geometries]);

  useEffect(() => {
    switch (mapState) {
      case MAP_STATES.DRAWING_RECTANGLE:
        actions.setMarqueeState('block');
        actions.setIsAddressInfoVisible(false);
        actions.setPolygonState('none');
        actions.setPolylineState('none');
        actions.setActiveSelectionId(null);
        break;
      case MAP_STATES.DRAWING_POLYGON:
        actions.setMarqueeState('none');
        actions.setIsAddressInfoVisible(false);
        actions.setPolygonState('block');
        actions.setPolylineState('none');
        actions.setActiveSelectionId(null);
        break;
      case MAP_STATES.DRAWING_BOOLEAN:
        actions.setMarqueeState('none');
        actions.setIsAddressInfoVisible(false);
        actions.setPolygonState('block');
        actions.setPolylineState('none');
        break;
      case MAP_STATES.DRAWING_POLYLINE:
        actions.setMarqueeState('none');
        actions.setIsAddressInfoVisible(false);
        actions.setPolygonState('none');
        actions.setPolylineState('block');
        actions.setActiveSelectionId(null);
        break;
      default:
        actions.setMarqueeState('none');
        actions.setPolygonState('none');
        actions.setPolylineState('none');
        break;
    }
  }, [mapState]);

  return (
    <div
      id={DRAWING_CONTROLS_ID}
      className={classnames('draw-menu', {
        disabled: isReadOnly,
      })}
    >
      <div className='toolbar-label'>{text('Shapes')}</div>
      <div className='draw-menu-button-group'>
        <DrawingControlsButton
          state={MAP_STATES.DRAWING_POLYGON}
          mapState={mapState}
          setMapState={setMapState}
          setDrawingState={setDrawingState}
          icon={polygonIcon}
          tooltip='Polygon tool'
          isSelected={mapState === MAP_STATES.DRAWING_POLYGON}
          canDraw
          isDisabled={
            isReadOnly || state.map?.zoom <= MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS
          }
        />
        <DrawingControlsButton
          state={MAP_STATES.DRAWING_RECTANGLE}
          mapState={mapState}
          setMapState={setMapState}
          setDrawingState={setDrawingState}
          icon={rectangleIcon}
          tooltip='Rectangle tool'
          isSelected={mapState === MAP_STATES.DRAWING_RECTANGLE}
          canDraw
          isDisabled={
            isReadOnly || state.map?.zoom <= MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS
          }
        />
        <DrawingControlsButton
          state={MAP_STATES.DRAWING_POLYLINE}
          mapState={mapState}
          setMapState={setMapState}
          setDrawingState={setDrawingState}
          icon={polylineIcon}
          tooltip='Path tool'
          isSelected={mapState === MAP_STATES.DRAWING_POLYLINE}
          canDraw
          isDisabled={
            isReadOnly || state.map?.zoom <= MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS
          }
        />
        <DrawingControlsButton
          state={MAP_STATES.DRAWING_BOOLEAN}
          mapState={mapState}
          setMapState={setMapState}
          setDrawingState={setDrawingState}
          icon={booleanIcon}
          tooltip='Boolean tool'
          isSelected={mapState === MAP_STATES.DRAWING_BOOLEAN}
          isDisabled={
            isBooleanDisabled ||
            isReadOnly ||
            state.map?.zoom <= MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS
          }
          canDraw
        />
      </div>
      <div className='toolbar-label'>{text('Undo')}</div>
      <div className='draw-menu-button-group'>
        <DrawingControlsButton
          state={MAP_STATES.UNDO}
          mapState={mapState}
          setMapState={setMapState}
          setDrawingState={setDrawingState}
          icon={undoIcon}
          tooltip='Undo'
          isDisabled={
            isReadOnly || state.map?.zoom <= MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS
          }
        />
        <DrawingControlsButton
          state={MAP_STATES.REDO}
          mapState={mapState}
          setMapState={setMapState}
          setDrawingState={setDrawingState}
          icon={redoIcon}
          tooltip={text('redo')}
          isDisabled={
            isReadOnly || state.map?.zoom <= MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS
          }
        />
      </div>
    </div>
  );
};

DrawingControls.propTypes = {
  mapState: PropTypes.string.isRequired,
  setMapState: PropTypes.func.isRequired,
  setDrawingState: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default DrawingControls;
