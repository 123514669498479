export const downloadFile = async (dataUrl, name = null) => {
  const link = document.createElement('a');
  if (name) {
    link.download = name;
  } else {
    link.download = true;
  }
  link.href = dataUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
