import { useContext, useEffect, useMemo, useState } from 'react';

import { usePrevious } from 'react-use';
import useWorkerCallback from '../../hooks/useWorkerCallback';
import { formatMonth } from '../../utilities/date';
import { isArrayEqual } from '../../utilities/lodash';
import { MapViewContext } from './mapViewContext';

let MapLabel = null;

/**
 * `useCoverageLabels` renders the coverage labels onto the map.
 */
const useCoverageLabels = () => {
  const { state } = useContext(MapViewContext);
  const [mapOverlays, setMapOverlays] = useState([]);
  const [newLabels, setNewLabels] = useState([]);
  const previousSelections = usePrevious(state.selections);
  const previousCoverage = usePrevious(state.coverage);

  useEffect(() => {
    (async () => {
      if (state.googleMapsScriptStatus === 'ready') {
        const tempMapLabel = await import('./mapLabel');
        MapLabel = tempMapLabel.MapLabel;
      }
    })().catch((err) => {
      console.error('Error loading MapLabel:', err);
    });
  }, [state.googleMapsScriptStatus]);

  const getCoverageLabelsWorker = useMemo(
    () =>
      new Worker(
        new URL('../../workers/getCoverageLabels.worker.js', import.meta.url)
      ),
    []
  );

  const updateCoverageLabels = useWorkerCallback(
    getCoverageLabelsWorker,
    (labels) => {
      setNewLabels(labels);
    }
  );

  useEffect(() => {
    mapOverlays.forEach((overlay) => {
      overlay.setMap(null);
    });
    const overlays = [];
    newLabels.forEach((label) => {
      overlays.push(
        new MapLabel(
          new google.maps.LatLng(label.position[1], label.position[0]),
          formatMonth(label.dateAcquired * 1000),
          label.displayColor,
          state.map
        )
      );
    });
    setMapOverlays(overlays);
  }, [newLabels]);

  /**
   * Draw map labels (date indicators) whenever coverage in selections change.
   */
  useEffect(() => {
    if (!window?.Worker) {
      return;
    }

    if (!MapLabel) {
      return;
    }

    if (
      !state.selections ||
      !state.coverage ||
      (isArrayEqual(state.selections, previousSelections, ['focused']) &&
        isArrayEqual(state.coverage, previousCoverage))
    ) {
      return;
    }

    updateCoverageLabels({
      coverage: state.coverage,
      selections: state.selections,
    });
  }, [window?.Worker, state.selections, state.coverage]);
};

export default useCoverageLabels;
