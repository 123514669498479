export const statusLight = {
  draft: 'draft-bg',
  pending: 'pending-bg',
  paid: 'paid-bg',
  inprogress: 'inprogress-bg',
  completed: 'completed-bg',
  cancelled: 'cancelled-bg',
  demo: 'demo-bg',
};

export const statuses = {
  draft: {
    order: 1,
    name: 'Draft',
    key: 'draft',
    isFilterable: true,
  },
  pending: {
    order: 2,
    name: 'Quoted',
    key: 'pending',
    isFilterable: true,
  },
  paid: {
    order: 3,
    name: 'Paid',
    key: 'paid',
    isFilterable: true,
  },
  inprogress: {
    order: 4,
    name: 'Commenced',
    key: 'inprogress',
    isFilterable: true,
  },
  completed: {
    order: 5,
    name: 'Completed',
    key: 'completed',
    isFilterable: true,
  },
  cancelled: {
    order: 6,
    name: 'Cancelled',
    key: 'cancelled',
    isFilterable: false,
  },
  demo: {
    order: 7,
    name: 'Sample',
    key: 'demo',
    isFilterable: true,
  },
};

export const STATUS = {
  DEMO: 'demo',
  DRAFT: 'draft',
  PENDING: 'pending',
  PAID: 'paid',
  INPROGRESS: 'inprogress',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const ALL_STATUS = [
  STATUS.DEMO,
  STATUS.DRAFT,
  STATUS.PENDING,
  STATUS.INPROGRESS,
  STATUS.COMPLETED,
];

export const NON_EDITABLE_STATUSES = [STATUS.DEMO];

export const MIME_TYPES = {
  '-recap.zip': 'application/zip',
  '.e57': 'binary/octet-stream',
  '.laz': 'binary/octet-stream',
  '.rcs': 'binary/octet-stream',
};

export const UNTITLED = 'untitled';
