import classnames from 'classnames';
import upperCase from 'lodash/upperCase';
import React from 'react';
import { useSelector } from 'react-redux';

import useMembershipButton from '../../hooks/useMembershipButton';
import routes from '../../routes/constants';
import text from '../../text';
import {
  canUserUpgrade,
  isUserGovernment,
  isUserStaff,
  userHasActiveMembership,
} from '../../utilities/user';
import Button from './Button';

const MembershipButton = ({ className, variant = 'primary', isUppercase }) => {
  const { currentUser } = useSelector((reduxState) => ({
    currentUser: reduxState.profileReducer.userProfile,
  }));
  const { handleMembershipClick } = useMembershipButton();

  const label = isUppercase
    ? upperCase(text(canUserUpgrade(currentUser) ? 'upgrade' : 'startTrial'))
    : text(canUserUpgrade(currentUser) ? 'upgrade' : 'startTrial');
  return (
    !isUserStaff(currentUser) &&
    !userHasActiveMembership(currentUser) &&
    !isUserGovernment(currentUser) && (
      <Button
        id='MembershipButton'
        className={classnames(className)}
        variant={variant}
        onClick={() =>
          handleMembershipClick({
            redirectTo: {
              path: `${routes.user.profile}?focusedTab=membership`,
              label: text('navigateToRoute', {
                navigation: 'Back',
                routeName: text('account'),
              }),
            },
          })
        }
      >
        {label}
      </Button>
    )
  );
};

export default MembershipButton;
