import React from 'react';
import T from 'prop-types';
import classnames from 'classnames';

const List = (props) => {
  const { children, isBorderless, isPaddingless, className } = props;
  return (
    <div
      className={classnames('List', {
        'is-borderless': isBorderless,
        'is-paddingless': isPaddingless,
        [className]: className,
      })}
    >
      <table>{children}</table>
    </div>
  );
};

const Item = ({
  description,
  value,
  isHighlighted,
  isSecondaryHighlight,
  isValueSecondaryHighlight,
  isColonAppended,
  className,
}) => {
  return (
    <tr
      className={classnames({
        'is-highlighted': isHighlighted,
        'secondary-highlight': isSecondaryHighlight,
        'secondary-highlight-value': isValueSecondaryHighlight,
        'is-colon-appended': isColonAppended,
        [className]: className,
      })}
    >
      <td className={classnames('description', { [className]: className })}>
        {description}
      </td>
      <td className={classnames('value', { [className]: className })}>
        {value}
      </td>
    </tr>
  );
};

Item.propTypes = {
  description: T.string,
  value: T.any,
  isHighlighted: T.bool,
};

List.Item = Item;

export default List;
