import {
  GET_STARTED_TOURS,
  MODAL_TOUR_NAMES,
  TOURS,
} from '../../pages/dashboard/components/onboarding/tour';
import text from '../../text';
import { tourApi } from '../../utilities/api';
import { tourActionConstants } from '../constants/tour';

export const setTourSelected = (tour) => async (dispatch) =>
  dispatch({ type: tourActionConstants.TOUR_SELECTED, payload: tour });

export const clearTourSelected = () => async (dispatch) =>
  dispatch({ type: tourActionConstants.CLEAR_TOUR_SELECTED, payload: null });

export const getTourStatus =
  (tour, setSteps, setCurrentStep) => async (dispatch) => {
    const tourModal = MODAL_TOUR_NAMES[tour];

    try {
      const {
        data: { status },
      } = await tourApi.getTourStatus(tourModal);

      if (status === 0) {
        dispatch(setTourSelected(TOURS[tour]));
        setSteps(GET_STARTED_TOURS[TOURS[tour]]);
        setCurrentStep(0);
      }
    } catch (error) {
      // TODO: save error log if there is any
      /** or we show alert? 
       * But I don't think we need to show alert to users for this
       * same for --putTourStatus-- */
      console.log(
        error.response?.data?.message
          ? error.response.data.message
          : text('getTourStatusFail')
      );
    }
  };
export const putTourStatus = (tour) => async () => {
  const tourModal = MODAL_TOUR_NAMES[tour];
  try {
    await tourApi.putTourStatus(tourModal);
  } catch (error) {
    // TODO: save error log if there is any
    console.log(
      error.response?.data?.message
        ? error.response.data.message
        : text('putTourStatusFail')
    );
  }
};
