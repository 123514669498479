import '@stripe/stripe-js';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import '../public/scss/main.scss';
import PublicApp from './PublicApp/PublicApp';
import { MapViewProvider } from './components/mapView/mapViewContext';
import { startListener } from './events/serviceWorkerEventListener';
import App from './pages/App';
import history from './routes/history';
import registerServiceWorker from './serviceWorkers/registerServiceWorker';
import { store } from './utilities';
import { setupInterceptors } from './utilities/api';

setupInterceptors(store);

render(
  <Provider store={store}>
    <Router history={history}>
      <MapViewProvider>
        <Switch>
          <Route path='/embed' component={PublicApp} />
          <Route path='/' component={App} />
        </Switch>
      </MapViewProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

if (process.env.ENABLE_SERVICE_WORKER) {
  startListener();
  registerServiceWorker();
}