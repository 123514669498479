import forEach from 'lodash/forEach';
import flowFP from 'lodash/fp/flow';
import groupByFP from 'lodash/fp/groupBy';
import mergeFP from 'lodash/fp/merge';
import orderByFP from 'lodash/fp/orderBy';
import partitionFP from 'lodash/fp/partition';
import map from 'lodash/map';
import prettyBytes from 'pretty-bytes';
import T from 'prop-types';
import React from 'react';
import AssetIcon from '../../components/icon/common/AssetIcon';
import Icon from '../../components/icon/Icon';
import { ASSET_ICON_MAP, PRODUCT_TYPE } from '../../constants/product';
import text from '../../text';
import { client } from '../../utilities/api';
import { isAdministrator } from '../../utilities/user';
import { amplitudeTrack } from '../../utilities';
import { AMPLITUDE_EVENTS } from '../../constants/analytics';
import { getAddOnConfig } from '../../components/mapView/drawingManager/utilities';
import { IconWrapper } from '../../components/RightMenu/Product';

const iconMap = mergeFP(ASSET_ICON_MAP, {});

const FILE_FORMAT = {
  pointCloud3D: ['rcs', 'e57', 'las', 'laz', 'ply', 'rcp', 'pts'],
  drawing2D: ['dwg'],
  bim3D: ['rvt', 'pln', 'skp', 'vwx', '3dm', 'ifc'],
  image: ['jpg', 'png'],
  document: ['pdf'],
  compressed: ['zip'],
};

const SOFTWARE = {
  Revit: ['rcs', 'rvt'],
  ArchiCAD: ['pln'],
  AutoCAD: ['dwg'],
  SketchUp: ['skp'],
  VectorWorks: ['vwx'],
  Rhino: ['3dm', 'ply'],
};

const BIM_FILE_EXT = ['rvt', 'pln', 'ifc'];
const FEATURE_PLAN_EXT = ['pdf', 'dwg'];

const getTitleMessage = (product, fileFormat, software, tripodType = '') => {
  switch (fileFormat) {
    case 'pointCloud3D':
      const extraLabel = [
        PRODUCT_TYPE.INTERIOR,
        PRODUCT_TYPE.EXTERIOR,
      ].includes(product.toLowerCase())
        ? ' Tripod '
        : '';

      if (software) {
        return text('productPointCloud3DTitle', {
          product,
          software,
          extraLabel,
        });
      } else {
        return text('productGenericPointCloud3DTitle', {
          product,
          extraLabel,
        });
      }
    case 'drawing2D':
      return `${text('drawing2DTitle', {
        tripod: tripodType,
        software,
      })}`;
    case 'bim3D':
      return `${text('bim3DTitle', {
        tripod: tripodType,
        software,
      })}`;
    case 'image':
      return text('image');
    case 'document':
      return text('document');
    case 'compressed':
      return text('compressed');
    default:
      return text('other');
  }
};

const getAssetType = (productType) => (productType ? iconMap[productType] : '');

const getProductType = (filename) => {
  const fileExtension = getFileExtension(filename);
  const regexTripod = /[-_.]?(?<product>Exterior|Interior)[-_.]/i;

  const regexBim =
    /[-_.]?(?<product>((Exterior|Interior)[-_.]?BIM|BIM[-_.]?(Exterior|Interior)))[-_.]/i;
  const matchBim = regexBim.exec(filename);
  if (matchBim && BIM_FILE_EXT.includes(fileExtension)) {
    const matchTripod = regexTripod.exec(filename);
    if (!matchTripod) {
      return ['', ''];
    }

    // returns BIM_<Exterior|Interior> at index 0 to set group and tag appropriate icon
    return [`BIM_${matchTripod.groups?.product}`, matchTripod.groups?.product];
  }

  if (
    filename.match(/[-_.]?(Plan)[-_.]?/i) &&
    FEATURE_PLAN_EXT.includes(fileExtension)
  ) {
    const matchTripod = regexTripod.exec(filename);
    if (!matchTripod) {
      return ['', ''];
    }

    // returns Twod_Plan_<Exterior|Interior> at index 0 to set group and tag appropriate icon
    return [
      `Twod_Plan_${matchTripod.groups?.product}`,
      matchTripod.groups?.product,
    ];
  }

  const regexPointClound =
    /[-_.]?(?<product>Drone|Exterior|Interior|Aerial|Streetscape)[-_.]/i;

  const matchToPointCloud = regexPointClound.exec(filename);
  if (matchToPointCloud) {
    return [matchToPointCloud.groups?.product, ''];
  }

  return ['', ''];
};
const getFileExtension = (filename) => filename && filename.split('.').pop();

const getFileGroupFromMap = (extension, groupMap) => {
  let format;
  forEach(groupMap, (exts, key) => {
    if (exts.includes(extension)) {
      format = key;
    }
  });
  return format;
};

const getExtensionFormat = (extension) =>
  getFileGroupFromMap(extension, FILE_FORMAT);
const getExtensionSoftware = (extension) =>
  getFileGroupFromMap(extension, SOFTWARE);

const setFileGrouping = (filename) => [
  getProductType(filename),
  getFileExtension(filename),
];

const downloadFile = async (projectId, assetId) => {
  const { data } = await client.getJobAsset(projectId, assetId);
  const link = document.createElement('a');
  link.download = true;
  link.href = data;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const AssetIconWrapper = (grouRep) => {
  const productType = grouRep?.productType || '';
  const isAddOn = !!getAddOnConfig(productType.toLowerCase());

  if (isAddOn) {
    return <IconWrapper icon={productType.toLowerCase()} />;
  }

  return (
    <AssetIcon
      product={getAssetType(productType)}
      classNames='overwrite-asset-icon-style'
      roundedCorners={false}
    />
  );
};

const ActivityAsset = (props) => {
  const { projectId, assets, onDelete, currentUser } = props;
  const { FILE_MANAGER } = AMPLITUDE_EVENTS;

  const partitionGroups = flowFP([
    (result) =>
      map(result, (item) => {
        const [[product, tripodType], fileExtension] = setFileGrouping(
          item.filename
        );
        const fileFormat = getExtensionFormat(fileExtension);
        return {
          ...item,
          product,
          titleMessage: getTitleMessage(
            product,
            fileFormat,
            getExtensionSoftware(fileExtension),
            tripodType
          ),
        };
      }),
    partitionFP('product'), // Partition Larki assets and other assets, to prioritize larki products
  ])(assets);

  const handleClick = (assetId) => async (event) => {
    event.preventDefault();
    amplitudeTrack(FILE_MANAGER.EVENT, {
      action: FILE_MANAGER.DOWNLOAD_INDIVIDUAL_FILES,
    });
    await downloadFile(projectId, assetId);
  };

  return map(partitionGroups, (group) => {
    const groupedByTitle = flowFP([
      orderByFP('titleMessage', ['asc']),
      groupByFP('titleMessage'),
    ])(group);

    return map(groupedByTitle, (groupedAssets, groupKey) => {
      const orderedGroupedAssets = orderByFP(
        'filename',
        ['asc'],
        groupedAssets
      );

      const groupRep = orderedGroupedAssets[0];

      return (
        <div key={groupKey} className='ActivityAsset'>
          <div key={`${groupKey}-top`} className='Top align-items-center'>
            <div className='IconContainer mr-2'>
              <AssetIconWrapper productType={groupRep?.product} />
            </div>
            <span>
              {text('groupedAssetsHeading', {
                baseMsg: groupRep.titleMessage,
                toDownload: (
                  <strong key={groupKey}>
                    {text('filesToDownload', {
                      filePlural: text('filesPlural', {
                        numberFiles: groupedAssets.length,
                      }),
                    })}
                  </strong>
                ),
              })}
            </span>
          </div>
          <div key={`${groupKey}-bottom`} className='Bottom'>
            {map(orderedGroupedAssets, (item, i) => (
              <div className='Asset' key={i}>
                <a onClick={handleClick(item?.id)} href='#'>
                  {item?.filename}
                </a>
                <span className='FileSize'>
                  {prettyBytes(item?.metadata?.size || 1)}
                </span>
                {typeof item?.metadata.merged !== 'undefined' && (
                  <span className='Merged'>
                    ({item.metadata.merged ? 'Merged' : 'Unmerged'})
                  </span>
                )}
                {isAdministrator(currentUser?.role) && (
                  <Icon onClick={() => onDelete(item?.id)} icon='cross' />
                )}
              </div>
            ))}
          </div>
        </div>
      );
    });
  });
};

ActivityAsset.propTypes = {
  jobId: T.number,
  assets: T.arrayOf(T.shape()),
  type: T.string,
};

export default ActivityAsset;
