import { createSelector } from '@reduxjs/toolkit';
import { first, flatten, uniq } from 'lodash';
import { ENTITLEMENTS } from '../../constants/membership';

const getUserProfile = (state) => state.profileReducer.userProfile;

const getActiveMemberships = (state) =>
  state.profileReducer.userProfile.customer?.memberships.filter((membership) =>
    ['active'].includes(membership.status)
  );

const getActiveMembership = createSelector(
  getActiveMemberships,
  (memberships) => first(memberships)
);

const hasValidMembership = createSelector(
  getActiveMemberships,
  (memberships) => memberships?.length > 0
);

const getCustomer = (state) => state.profileReducer.userProfile.customer;

const getEntitlements = createSelector(getCustomer, (customer) =>
  uniq(
    flatten(customer?.memberships.map((membership) => membership.entitlements))
  )
);

const getHasHighResEntitlement = createSelector(
  getEntitlements,
  (entitlements) => entitlements.includes(ENTITLEMENTS.HIGH_RES_MAP)
);

const getHighResEnabled = createSelector(
  getHasHighResEntitlement,
  getUserProfile,
  (hasHighResEntitlement, userProfile) =>
    hasHighResEntitlement ||
    userProfile.role === 'superadministrator' ||
    userProfile.role === 'administrator' ||
    userProfile.role === 'staff' ||
    userProfile.role === 'government'
);

const getHas3dViewerEntitlement = createSelector(
  getEntitlements,
  (entitlements) => entitlements.includes(ENTITLEMENTS._3D_VIEWER)
);

const get3dViewerEnabled = createSelector(
  getHas3dViewerEntitlement,
  getUserProfile,
  (has3dViewerEntitlement, userProfile) => {
    return (
      has3dViewerEntitlement ||
      userProfile.role === 'superadministrator' ||
      userProfile.role === 'administrator' ||
      userProfile.role === 'staff' ||
      userProfile.role === 'government'
    );
  }
);

export const profileSelectors = {
  getHighResEnabled,
  get3dViewerEnabled,
  hasValidMembership,
  getActiveMembership,
};
