import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Loading from '../../components/loading/Loading';
import Page from '../../components/page/Page';
import { profileSelectors } from '../../redux/selectors/profile';

import routes from '../../routes/constants';
import text from '../../text';

import Invoice from './Invoice/Invoice';
import MembershipInvoice from './Invoice/MembershipInvoice';
import OrderWizard from './OrderWizard/OrderWizard';
import { isStaff } from '../../utilities/user';
import { clearJob, getJob } from '../../redux/actions';

const Payment = () => {
  const { jobId } = useParams();
  const history = useHistory();
  const hasValidMembership = useSelector(profileSelectors.hasValidMembership);
  const dispatch = useDispatch();
  const { userProfileState, currentUser, job, isGettingJob } = useSelector(
    (state) => ({
      userProfileState: state.profileReducer.userProfileState,
      currentUser: state.profileReducer.userProfile,
      job: state.jobsReducer.job,
      isGettingJob: state.jobsReducer.isGettingJob,
    })
  );

  useEffect(() => {
    if (jobId && isEmpty(job) && !isGettingJob) {
      dispatch(getJob(jobId));
    }
  }, [job]);

  useEffect(() => {
    return () => {
      dispatch(clearJob());
    };
  }, []);

  if (userProfileState === 'loading' || isGettingJob) {
    return (
      <Page className='PageCenter'>
        <Loading />
      </Page>
    );
  }
  return (
    <Page className='Payment'>
      <div className='invoice-section'>
        <Link
          to={{
            pathname: routes.order.job(jobId),
            search: history.location.search,
          }}
          href='#'
        >
          <Icon icon='caret-left' />
          {text('backTo2DMap')}
        </Link>
        <p className='invoice-title text-uppercase'>{text('invoicesToPay')}</p>
        {!hasValidMembership && !isStaff(currentUser.role) && (
          <MembershipInvoice jobId={jobId} />
        )}
        <Invoice jobId={jobId} hasValidMembership={hasValidMembership} />
      </div>
      <div className='section'>
        <OrderWizard />
      </div>
    </Page>
  );
};

export default Payment;
