import { forEach, isEmpty } from 'lodash';

/**
 * @returns {Object} contains the compared object fields with value 'true' if the same keys have different values
 */
export const checkFieldsAreDiff = (baseObj, compareObj) => {
  const changedFields = {};
  if (!isEmpty(baseObj) && !isEmpty(compareObj)) {
    forEach(baseObj, (baseValue, baseKey) => {
      if (compareObj[baseKey] !== baseValue) {
        changedFields[baseKey] = true;
      }
    });
    return changedFields;
  }
  return changedFields;
};