/* eslint-disable react/prop-types */
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { components } from 'react-select';

import _ from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn, useMount } from 'react-use';
import { alertError, setCurrentProject } from '../../../redux/actions';
import { client } from '../../../utilities/api';
import { getProjectName } from '../../../utilities/project';
import { isUserAnonymous } from '../../../utilities/user';
import Select from '../Select';

const Label = ({ children, className }) => (
  <h6 className={cn('JobLabel', { [className]: className })}>{children}</h6>
);

const SingleValue = ({ children, ...rest }) => (
  <components.SingleValue className='JobSelectControl' {...rest}>
    <Label>{children}</Label>
  </components.SingleValue>
);

const NavProjectSelect = ({
  projectId,
  admin = false,
  onProjectChange = _.noop,
  filterPredicate = _.stubTrue,
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [loadOptionsState, loadOptions] = useAsyncFn(async () => {
    const { data } = await client.listProjects({
      isAdmin: admin,
      includeDemo: true,
      inviteStatus: ['accepted'],
    });
    setOptions(_.filter(filterPredicate, data.projects));
  });
  const { currentProject } = useSelector((state) => state.project);
  const { userProfile } = useSelector((state) => state.profileReducer);
  useMount(() => {
    loadOptions();
  });
  useEffect(() => {
    if (loadOptionsState.error) {
      console.error(loadOptionsState.error.message);
      dispatch(alertError(loadOptionsState.error.message));
    }
  }, [loadOptionsState.error]);
  useEffect(() => {
    if (
      loadOptionsState.loading ||
      loadOptionsState.error ||
      !options?.length ||
      _.isNil(projectId)
    ) {
      return;
    }
    const project = _.find(['id', projectId], options);
    dispatch(setCurrentProject(project));
  }, [loadOptionsState, options, projectId]);
  return isUserAnonymous(userProfile) ? (
    <Label className='JobSelect'>{getProjectName(currentProject)}</Label>
  ) : (
    <Select
      options={options}
      value={currentProject}
      onChange={(option) => {
        onProjectChange(option, currentProject);
        dispatch(setCurrentProject(option));
      }}
      getOptionLabel={(option) => getProjectName(option)}
      getOptionValue={(option) => option.id}
      formatOptionLabel={(option) => getProjectName(option)}
      className='JobSelect'
      components={{ SingleValue }}
      isDisabled={loadOptionsState.loading}
      isLoading={loadOptionsState.loading}
    />
  );
};

export default NavProjectSelect;
