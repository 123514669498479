import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIVE_STATUS } from '../../constants/job';
import { getAdminJobsList } from '../../redux/actions';
import { ALL_STATUS } from './consts';
import { OrderListing } from './orderListing';

const AdminOrderListing = () => {
  const dispatch = useDispatch();
  const {
    adminJobList,
    adminStatusCounts,
    listJobFilter,
    isAdminJobListLoading,
    currentUser,
  } = useSelector((state) => ({
    adminJobList: state.jobsReducer.adminJobList,
    adminStatusCounts: state.jobsReducer.adminStatusCounts,
    listJobFilter: state.jobsReducer.listJobFilter,
    isAdminJobListLoading: state.jobsReducer.isAdminJobListLoading,
    currentUser: state.profileReducer.userProfile,
  }));

  useEffect(() => {
    const currentStatus =
      listJobFilter.status === ALL_STATUS
        ? ACTIVE_STATUS
        : listJobFilter.status;
    dispatch(getAdminJobsList({ status: currentStatus }));
  }, [listJobFilter.status]);

  return (
    <OrderListing
      currentUser={currentUser}
      isLoading={isAdminJobListLoading}
      jobsList={adminJobList}
      statusCounts={adminStatusCounts}
      status={listJobFilter}
      isAdminOrigin
      isStaff
    />
  );
};

export default AdminOrderListing;
