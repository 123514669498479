import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reach } from 'yup';
import useBreakpoint from '../../hooks/useBreakpoint';
import useMembershipButton from '../../hooks/useMembershipButton';
import {
  copyJob,
  getJobsList,
  hideModal,
  showModal,
  updateProjectName,
} from '../../redux/actions';
import { profileSelectors } from '../../redux/selectors/profile';
import { isFailed, isSuccess } from '../../redux/stateTools';
import routes from '../../routes/constants';
import text from '../../text';
import { formatDate } from '../../utilities/date';
import { isDemoJob, jobHasPointCloud } from '../../utilities/job';
import { isAdministrator } from '../../utilities/user';
import {
  PROJ_NAME_MAX_LENGTH,
  projectValidationSchema,
} from '../../validation';
import LinkButton from '../button/LinkButton';
import MeatballDropdown from '../dropdown/MeatballDropdown';
import Icon from '../icon/Icon';
import { DELETE_PROJECT_MODAL } from '../modal/DeleteProjectModal';
import { MERGE_PROJECT_MODAL } from '../modal/MergeProjectModal';
import { INVITE_PROJECT_USERS_MODAL } from '../modal/inviteProjectUsersModal/InviteProjectUsersModal';
import Cell from '../table/Cell';
import Status from './Status';
import { NON_EDITABLE_STATUSES } from './consts';
import InviteRow from './inviteRow';

export const COLUMN = {
  DATE: 'date-column',
  NAME: 'name-column',
  ADDRESS: 'address-column',
  STATUS: 'status-column',
  FILE_MANAGER: 'file-manager-column',
  VIEW3D: 'view3D-column',
  ACTIONS: 'actions-column',
};

const BadgeCount = ({ count }) => {
  if (count <= 0) {
    return null;
  }

  return <Badge className='badge-greyed'>{`+ ${count}`}</Badge>;
};

BadgeCount.propTypes = {
  count: T.number,
};

function OrderListingItem({ job, currentUser, isAdminOrigin, isStaff }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDeleteEnabled = useMemo(() => {
    return (
      job?.jobs?.every((item) => item.status == 'draft') ||
      (isAdminOrigin && isAdministrator(currentUser.role))
    );
  }, [job, currentUser, isAdminOrigin]);

  const { handleMembershipClick } = useMembershipButton();
  const isMdAndLg = useBreakpoint(['md', 'lg']);
  const is3dViewerAccessibleToUser = useSelector(
    profileSelectors.get3dViewerEnabled
  );
  const is3dViewerLocked =
    jobHasPointCloud(job) && !is3dViewerAccessibleToUser && !isDemoJob(job);

  const isEditingEnabledForBkp = useBreakpoint(['sm', 'md', 'lg', 'xl']);

  const isPending = job.user_project?.invite_status === 'pending';

  const _3dViewerRoute = isAdminOrigin
    ? `${routes.view3D.project(job.projectId)}?admin=true`
    : `${routes.view3D.project(job.projectId)}`;

  const handleDeleteProject = () =>
    dispatch(
      showModal(DELETE_PROJECT_MODAL, {
        projectId: job?.projectId,
        name: job?.project.name,
      })
    );

  const handleAddOrderToProject = () =>
    history.push({
      pathname: routes.order.root,
      search: `?projectId=${job?.project?.id}`,
    });

  const handleMergeProject = () =>
    dispatch(
      showModal(MERGE_PROJECT_MODAL, {
        currentJob: job,
        onSuccess: () => dispatch(getJobsList()),
      })
    );

  const onUpdateProjectName = (projectName) =>
    dispatch(updateProjectName(job.projectId, projectName.trim()));

  const onEdit = () => {
    history.push(`/order/${job.id}`);
  };

  const onCopy = () => {
    dispatch(copyJob(job.id));
  };

  const jobCountExceptCurrent = useMemo(() => {
    if (!job?.jobs) return 0;

    return job.jobs.length - 1;
  }, [job]);

  return (
    !isEmpty(job) && (
      <>
        <tr
          className={classNames({
            'has-pending-invite': job.user_project?.invite_status === 'pending',
            'has-non-staff': job.has_customer && isStaff,
          })}
        >
          <Cell
            className={COLUMN.NAME}
            disabled={
              NON_EDITABLE_STATUSES.includes(job.status) ||
              !isEditingEnabledForBkp
            }
            isFailed={isFailed(job.ui?.updateState)}
            isSuccessful={isSuccess(job.ui?.updateState)}
            id={job.id}
            maxLength={PROJ_NAME_MAX_LENGTH}
            onSubmit={onUpdateProjectName}
            validationSchema={reach(projectValidationSchema, 'project.name')}
            value={
              isNull(job.project?.name) ? text('untitled') : job.project?.name
            }
          >
            <Status className='status-in-name' job={job} />
          </Cell>
          <td className={COLUMN.ADDRESS}>
            <div
              className='d-flex flex-column flex-xl-row align-items-xl-end'
              {...(jobCountExceptCurrent && {
                style: {
                  gap: isMdAndLg ? 8 : 16,
                },
              })}
            >
              <div>{job.full_address}</div>
              <div>
                {jobCountExceptCurrent > 0 && (
                  <BadgeCount count={jobCountExceptCurrent} />
                )}
              </div>
            </div>
          </td>
          <td className={COLUMN.DATE}>{formatDate(job.created_at)}</td>
          <td className={COLUMN.DATE}>{formatDate(job.updated_at)}</td>
          <td className={COLUMN.STATUS}>
            <Status job={job} />
          </td>
          <td className={COLUMN.ACTIONS}>
            <div className='job-actions'>
              <LinkButton
                className={classNames('table-icon-button', {
                  'star-container': is3dViewerLocked,
                })}
                tooltipProps={{
                  text: text(
                    is3dViewerLocked
                      ? 'upgrade'
                      : jobHasPointCloud(job)
                      ? 'viewer3D'
                      : 'viewer3dUnavailable'
                  ),
                }}
                disabled={
                  isPending ||
                  (!is3dViewerLocked &&
                    !isDemoJob(job) &&
                    (!jobHasPointCloud(job) || !is3dViewerAccessibleToUser))
                }
                {...(!isPending
                  ? is3dViewerLocked
                    ? {
                        onClick: (e) => {
                          e.preventDefault();

                          handleMembershipClick({
                            redirectTo: {
                              path: _3dViewerRoute,
                              label: text('navigateToRoute', {
                                navigation: 'Continue',
                                routeName: text('viewer3D'),
                              }),
                            },
                          });
                        },
                      }
                    : jobHasPointCloud(job)
                    ? {
                        to: _3dViewerRoute,
                      }
                    : {}
                  : {})}
              >
                <Icon icon='3D-with-text' />
                {is3dViewerLocked && (
                  <div className='lock-badge-container'>
                    <Icon icon='lock' />
                  </div>
                )}
              </LinkButton>
              <LinkButton
                disabled={isPending}
                tooltipProps={{ text: text('map2D') }}
                to={
                  job.lga
                    ? routes.order.root
                    : isAdminOrigin
                    ? `${routes.order.root}/${job.id}?admin=true`
                    : `${routes.order.root}/${job.id}`
                }
                className='table-icon-button'
              >
                <Icon icon='2D-with-text' />
              </LinkButton>
              <LinkButton
                disabled={isPending}
                tooltipProps={{ text: text('fileManager') }}
                to={
                  isAdminOrigin
                    ? `${routes.fileManager.viewProject(
                        job.project_id
                      )}?admin=true`
                    : `${routes.fileManager.viewProject(job.project_id)}`
                }
                className='table-icon-button'
              >
                <Icon icon='file-with-text' />
              </LinkButton>
              <MeatballDropdown
                btnClassName='table-icon-button'
                disabled={isPending}
              >
                <Dropdown.Item
                  disabled={['demo'].includes(job.status)}
                  onClick={() =>
                    dispatch(
                      showModal(INVITE_PROJECT_USERS_MODAL, {
                        onHide: () =>
                          dispatch(hideModal(INVITE_PROJECT_USERS_MODAL)),
                        project: job?.project,
                        job: job,
                      })
                    )
                  }
                >
                  {text('inviteViewProjectUsers')}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={!['draft'].includes(job.status)}
                  onClick={onEdit}
                >
                  Edit
                </Dropdown.Item>
                {job.status !== 'demo' && (
                  <>
                    <Dropdown.Item onClick={onCopy}>Duplicate</Dropdown.Item>
                    <Dropdown.Item onClick={handleAddOrderToProject}>
                      {text('addNewOrder')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleMergeProject}>
                      {text('mergeProjects')}
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item
                  disabled={!isDeleteEnabled}
                  onClick={handleDeleteProject}
                >
                  {text('deleteProject')}
                </Dropdown.Item>
              </MeatballDropdown>
            </div>
          </td>
        </tr>
        {isPending && <InviteRow job={job} />}
      </>
    )
  );
}

OrderListingItem.propTypes = {
  job: T.shape(),
};

export default OrderListingItem;
