import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getMapQueryData } from '../../redux/actions';
import { convertToGeoJson } from '../../utilities/map';
import Icon from '../icon/Icon';
import { PRODUCT_DATA } from '../mapView/constants';
import { MapViewContext } from '../mapView/mapViewContext';
import ShapeMenu from '../shapeMenu/ShapeMenu';
import { useClickOutside } from '../../hooks/useClickOutside';

export default function ProductShapeMenuModal() {
  const dispatch = useDispatch();
  const { actions, state } = useContext(MapViewContext);
  const { data } = state.productShapeSelected;
  const ref = useRef(null);
  useClickOutside(ref, () => actions.setProductShapeSelected(null), [
    '.click-outside-excluded',
  ]);

  const onProductMenuOpen = useCallback(() => {
    if (state.productShapeSelected) {
      dispatch(
        getMapQueryData({
          region: convertToGeoJson([{ region: data.region }]).features[0]
            .geometry,
          category_names: PRODUCT_DATA.result,
        })
      );
    }
  }, [state.productShapeSelected]);

  useEffect(() => {
    onProductMenuOpen();
  }, [onProductMenuOpen]);

  return (
    <Modal.Dialog id='product-dropdown-menu-container' ref={ref}>
      <Modal.Header className='align-align-items-center'>
        <div className='d-flex flex-column'>
          <div style={{ fontSize: 16, fontWeight: 600 }}>
            {state.productShapeSelected.dataName}
          </div>
        </div>

        <Button
          onClick={() => actions.setProductShapeSelected(null)}
          variant='light'
          className='row-action icon-button'
        >
          <Icon icon='close' />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <ShapeMenu
          data={data}
          DropdownMenuComponent={Dropdown.Menu}
          show
          className='product-dropdown'
        />
      </Modal.Body>
    </Modal.Dialog>
  );
}
