import React from 'react';
import { useHistory } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import Button from '../button/Button';
import BottomContainer from '../containers/BottomContainer';
import routes from '../../routes/constants';
import text from '../../text';

const StepFooter = (props) => {
  const history = useHistory();
  const { previousStep, nextStep, isFirstStep } = useWizard();
  const { previousButtonProps = {}, nextButtonProps = {}, topContent } = props;

  const onCancel = () => {
    history.push(routes.home);
  };

  return (
    <BottomContainer className='StepFooter'>
      <div className='top'>{topContent}</div>
      <div className='bottom'>
        <div className='left'>
          <Button variant='link' onClick={onCancel}>
            {text('cancel')}
          </Button>
        </div>
        <div className='right'>
          {!isFirstStep && (
            <Button
              variant='outline-primary'
              onClick={previousStep}
              {...previousButtonProps}
            >
              {previousButtonProps.label || text('previous')}
            </Button>
          )}
          <Button
            className='ml-3 full-width'
            onClick={
              nextButtonProps?.onClick
                ? () => nextButtonProps.onClick(nextStep)
                : nextStep
            }
            loading={nextButtonProps.loading || false}
            disabled={nextButtonProps.disabled}
          >
            {nextButtonProps.label || text('next')}
          </Button>
        </div>
      </div>
    </BottomContainer>
  );
};

export default StepFooter;
