import cn from 'classnames';
import T from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn, useSearchParam } from 'react-use';

import _ from 'lodash/fp';
import NavProjectSelect from '../../../../../components/select/jobSelect/NavProjectSelect';
import { alertError } from '../../../../../redux/actions';
import routes from '../../../../../routes/constants';
import text from '../../../../../text';
import { client } from '../../../../../utilities/api';
import { getProjectLatestJob } from '../../../../../utilities/project';
import { isUserAnonymous } from '../../../../../utilities/user';

const Map2DNav = (props) => {
  const { className } = props;
  const admin = useSearchParam('admin');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const jobId = location.pathname.split(`${routes.order.root}/`)[1];
  const { userProfile } = useSelector((state) => state.profileReducer);
  const [currentJob, setCurrentJob] = useState(null);
  const canGetJob =
    !_.isNil(jobId) && !_.isEmpty(userProfile) && !isUserAnonymous(userProfile);

  const [getJobState, getJob] = useAsyncFn(async () => {
    const { data } = await client.getJobById(jobId);
    setCurrentJob(data);
  }, [canGetJob]);

  useEffect(() => {
    if (!canGetJob) {
      return;
    }
    getJob();
  }, [canGetJob]);

  useEffect(() => {
    if (getJobState.error) {
      console.error(getJobState.error.message);
      dispatch(alertError(getJobState.error.message));
    }
  }, [getJobState.error]);

  const map2DText = `${text(admin ? 'administrationMap2D' : 'map2D')}${
    jobId ? ':' : ''
  }`;

  return (
    <Nav className={cn('Title', className)}>
      <Navbar>
        <span>{map2DText}</span>
        {canGetJob && (
          <NavProjectSelect
            projectId={currentJob?.project_id}
            admin={admin}
            filterPredicate={(project) => project.jobs?.length > 0}
            onProjectChange={(nextProject, prevProject) => {
              const nextJob = getProjectLatestJob(nextProject);
              if (nextProject.id !== prevProject.id) {
                history.replace({
                  pathname: routes.order.job(nextJob.id),
                  search: history.location.search,
                });
                // TODO: fix this once map state is able to reload on project change
                window.location.reload();
              }
            }}
          />
        )}
      </Navbar>
    </Nav>
  );
};

Map2DNav.propTypes = {
  className: T.string,
};

export default Map2DNav;
