import React, { useEffect, useState } from 'react';
import { Dropdown, Collapse } from 'react-bootstrap';
import classnames from 'classnames';

import Icon from '../icon/Icon';
import DropdownItemLabel from './DropdownItemLabel';

const Submenu = (props) => {
  const { children, menuIcon, label, onOpen } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (!isCollapsed && onOpen) {
      onOpen();
    }
  }, [isCollapsed]);

  return (
    <>
      <Dropdown.Item
        className='SubmenuButton'
        onClick={(event) => {
          setIsCollapsed(!isCollapsed);
          event.preventDefault();
        }}
      >
        <DropdownItemLabel label={label} icon={menuIcon} />
        <Icon icon={isCollapsed ? 'caret-down' : 'caret-up'} />
      </Dropdown.Item>
      <div className={classnames('Submenu', { collapsed: isCollapsed })}>
        <div className='items'>{children}</div>
      </div>
    </>
  );
};

export default Submenu;
