import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import {
  Button as BootstrapButton,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';

import classNames from 'classnames';
import _ from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { clearBoundaries, showModal } from '../../redux/actions';
import text, { formatCurrency } from '../../text';
import Icon from '../icon/Icon';
import { MapViewContext } from '../mapView/mapViewContext';
import { DELETE_SHAPES_MODAL } from '../modal/DeleteShapesModal';
import { isUserUnderTrial, userHasValidMembership } from '../../utilities/user';
import AddOnIcon from '../icon/common/AddOnIcon';
import { getAddOnConfig } from '../mapView/drawingManager/utilities';

export const IconWrapper = (props) => {
  const isAddOn = !!getAddOnConfig(props.icon);
  if (!isAddOn) {
    return <Icon {...props} />;
  }

  let icon = `${props.icon}${props.isActive ? '_white' : ''}`;

  if (props.isDisabled || props.isSecondary) {
    icon = `${props.icon}_disabled`;
  }

  const addOnIconProps = {
    ...addOnIconProps,
    icon,
  };
  return <AddOnIcon {...addOnIconProps} />;
};

const Product = ({ data, product, charges, isReadOnly }) => {
  const { state, actions } = useContext(MapViewContext);
  const dispatch = useDispatch();
  const dataRepresentative = data[1][0] || [];
  const currentUser = useSelector((state) => state.profileReducer.userProfile);

  const isEssentialUser = useMemo(
    () => userHasValidMembership(currentUser) || isUserUnderTrial(currentUser),
    [currentUser]
  );

  const price = useMemo(() => {
    if (!charges || !charges.price) {
      return text('notAvailable');
    }
    if (!charges.price.total) {
      return text('toBeConfirmed');
    }

    const price = isEssentialUser
      ? charges.price.member_total
      : charges.price.total;

    return formatCurrency(
      price,
      charges.currency.name,
      charges.currency.scale_factor,
      {},
      true
    );
  }, [charges.price, charges.currency]);

  const handleDelete = async (event) => {
    const selectionsToDelete = state.selections.filter(
      (selection) => selection.product_id === dataRepresentative.product_id
    );
    const childrenSelections = state.selections.filter((selection) =>
      selectionsToDelete.some(
        (selectionToDelete) =>
          selectionToDelete.id === selection.parent_selection_id
      )
    );

    if (state.selections.length === selectionsToDelete.length) {
      dispatch(clearBoundaries());
    }
    dispatch(
      showModal(DELETE_SHAPES_MODAL, {
        selectionsToDelete: [...selectionsToDelete, ...childrenSelections],
        associatedAddOns: childrenSelections,
      })
    );
    event.stopPropagation();
  };

  const isAllSelectionsVisible = state.selections
    ? _.every(
        ['visible', true],
        _.filter(['category_name', product])(state.selections)
      )
    : false;

  const isAllSelectionsHidden = state.selections
    ? _.every(
        ['visible', false],
        _.filter(['category_name', product])(state.selections)
      )
    : false;

  const isSomeSelectionsHidden = state.selections
    ? _.some(
        ['visible', false],
        _.filter(['category_name', product])(state.selections)
      )
    : false;

  const eyeIcon = useMemo(() => {
    return isAllSelectionsVisible
      ? 'eye'
      : isAllSelectionsHidden
      ? 'eye-slash'
      : 'eye-mixed-state';
  }, [isAllSelectionsVisible, isAllSelectionsHidden]);

  // TODO: move to context
  // this type of functionality should really be abstracted away from components
  // we should have a setVisible method for a selection that automatically handles cases such as when the active selection becomes hidden
  const toggleProductVisibility = () => {
    let didActiveSelectionBecomeHidden = false;
    actions.setSelections(
      state.selections.map((selection) => {
        if (selection.display_name !== dataRepresentative.display_name) {
          return { ...selection };
        }
        const newVisible = !isAllSelectionsVisible ? true : !selection.visible;
        if (
          state.activeSelection &&
          state.activeSelection.id === selection.id
        ) {
          didActiveSelectionBecomeHidden = !newVisible;
        }
        return {
          ...selection,
          visible: newVisible,
          focused: false,
        };
      }) || []
    );
    if (didActiveSelectionBecomeHidden) {
      actions.setActiveSelectionId(null);
      actions.setGeometriesToLoad({});
    }
  };

  const handleSetProduct = () => {
    if (state.productSelected.product !== product) {
      actions.setProductShapeSelected(null);
    }

    actions.setShowProductModal(true);
    actions.setProductSelected((prevState) => ({
      ...prevState,
      data,
      product, // productType
      // charges, not needed since charges is fetched from state.job?.quote?.charges
      isReadOnly,
    }));

    if (
      state.activeSelection &&
      state.activeSelection.category_name !== product
    ) {
      actions.setGeometries({});
      actions.setGeometriesToLoad({});
      actions.makeSelectionActive(null);
    }
  };

  const activeClass = useMemo(
    () =>
      state.productSelected.product === product ? `active-${product}` : ``,
    [state.productSelected]
  );

  return (
    <tr>
      <td className='p-0 pb-2'>
        <Card
          className={classNames({
            [activeClass]: !!activeClass,
          })}
          onClick={handleSetProduct}
        >
          {' '}
          {/** active class will be added here */}
          <Card.Body className='product-row-container'>
            <div className='product-row d-flex flex-row align-items-center flex-fill'>
              <BootstrapButton
                className='row-action icon-button'
                variant='light'
                onClick={(ev) => {
                  toggleProductVisibility();
                  ev.stopPropagation();
                }}
                type='button'
              >
                <Icon
                  className={classNames({
                    original: isSomeSelectionsHidden,
                  })}
                  icon={eyeIcon}
                />
              </BootstrapButton>

              {/* start wrap with tooltip */}
              <div className='w-100 overflow-hidden'>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='top'
                  overlay={
                    <Tooltip
                      id='other-charge-tooltip'
                      className='wider-x-padding'
                    >
                      <p className='mb-0 text-center'>{text(`${product}3D`)}</p>
                      <p className='mb-0 text-center'>
                        {text('clickForMoreInfo')}
                      </p>
                    </Tooltip>
                  }
                >
                  <div className='d-flex flex-row align-items-center'>
                    <div
                      className={classNames(`product-icon-container`, {
                        [activeClass]: !!activeClass,
                      })}
                    >
                      <IconWrapper icon={product} isActive={activeClass} />
                    </div>

                    <div className='product-info text-truncate w-50 ml-1'>
                      {text(`${product}3D`)}
                    </div>
                    <div className='text-right product-info w-50'>{price}</div>
                  </div>
                </OverlayTrigger>
              </div>
              {/* end wrap with tooltip */}

              <div>
                <BootstrapButton
                  className='row-action icon-button'
                  variant='light'
                  onClick={handleDelete}
                >
                  <Icon icon='trash' />
                </BootstrapButton>
              </div>
            </div>
          </Card.Body>
        </Card>
      </td>
    </tr>
  );
};

Product.propTypes = {
  product: PropTypes.string,
  data: PropTypes.array.isRequired,
  charges: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
};

export default Product;
