import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MembershipButton from '../../components/button/MembershipButton';
import OrderListing from '../../components/orderListing/orderListing';
import OrderListingControl from '../../components/orderListing/orderListingControl';
import Page from '../../components/page/Page';
import routes from '../../routes/constants';
import text from '../../text';
import Onboarding from './components/onboarding';

export default function Dashboard() {
  const history = useHistory();
  const user = useSelector((state) => state.profileReducer.userProfile);

  return (
    <Page title={text('dashboard')}>
      <Page.Top>
        <h2>{text('dashboard')}</h2>
        <Page.Top.Control>
          <MembershipButton variant='outline-primary' />
          <Button
            variant='primary'
            onClick={() => history.push(routes.order.root)}
          >
            {text('order3DData')}
          </Button>
        </Page.Top.Control>
      </Page.Top>
      <OrderListingControl />
      <Page.Body>
        <OrderListing />
      </Page.Body>
      <Onboarding />
    </Page>
  );
}
